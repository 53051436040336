import { useState, useEffect, Fragment } from "react";
import { Tooltip } from "@mui/material";
import { FaCopy } from "react-icons/fa";
import { Bars } from "react-loader-spinner";

import "./notifications.scss";
import { getCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";

// convert timestamp to the desired date format e.g. "18-July-2023"
function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = { year: "numeric", month: "long", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
}

// convert data and group by date
function convertDataToColumns(data) {
  const groupedData = {};

  data.forEach((item) => {
    const date = formatDate(item.created_on);

    if (!groupedData[date]) {
      groupedData[date] = [];
    }

    groupedData[date].push(item);
  });

  return groupedData;
}

const headingStyle = {
  fontSize: "12px",
  fontWeight: "bold",
  color: "#697181",
  padding: "10px",
};

const isMobile = window.matchMedia("(max-width: 768px)").matches;
export default function Notifications() {
  const [notifications, setNotifications] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCall({
      url: config.url.NOTIFICATIONS,
      path: `/logs/1/10`,
      onSuccess: (response) => {
        const groupedData = convertDataToColumns(response.data.records);
        setNotifications(groupedData);
        setIsLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "We are unable to fetch notifications. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <div
        className="notifications"
        style={{
          display: "grid",
          placeContent: "center",
          height: "270px",
        }}
      >
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="notifications">
      <div>
        {Object.getOwnPropertyNames(notifications).length > 0 ? (
          <>
            <table style={{ width: "100%" }}>
              {Object.keys(notifications).map((date) => (
                <Fragment key={date}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          fontSize: "12px",
                          fontWeight: "bold",
                          color: "#1a1f36",
                          padding: "10px",
                          textAlign: "left",
                        }}
                      >
                        {date}
                      </th>
                    </tr>
                    <tr
                      style={{
                        borderTop: "1px solid #e5e7eb",
                        borderBottom: "1px solid #e5e7eb",
                      }}
                    >
                      <th style={headingStyle}>EVENT</th>
                      <th
                        style={{
                          ...headingStyle,
                          display: isMobile ? "none" : "table-cell",
                        }}
                      >
                        PAYLOAD
                      </th>
                      <th style={headingStyle}>
                        {isMobile ? "Copy payload" : "."}
                      </th>
                      <th style={headingStyle}>TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notifications[date].map((item) => (
                      <tr
                        key={item.id}
                        style={{
                          borderTop: "1px solid #e5e7eb",
                          borderBottom: "1px solid #e5e7eb",
                          textAlign: "center",
                        }}
                      >
                        <td style={{ padding: "10px" }}>{item.event_type}</td>
                        <td
                          style={{
                            padding: "10px",
                            maxWidth: "250px",
                            display: isMobile ? "none" : "table-cell",
                          }}
                        >
                          <Tooltip
                            title={JSON.stringify(item.payload)}
                            placement="bottom"
                            arrow
                          >
                            <div
                              style={{
                                width: "100%",
                                overflow: "auto",
                                maxWidth: "100%",
                                paddingBottom: "4px",
                              }}
                            >
                              <pre>{JSON.stringify(item.payload)}</pre>
                            </div>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip
                            title="Copy Payload"
                            placement="bottom"
                            arrow
                          >
                            <span>
                              <FaCopy
                                cursor="pointer"
                                color="#959595"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(JSON.stringify(item))
                                    .then(() => {
                                      bottomAlert("Payload copied", "success");
                                    })
                                    .catch((err) => {
                                      bottomAlert(
                                        "Unable to copy payload",
                                        "error"
                                      );
                                    });
                                }}
                              />
                            </span>
                          </Tooltip>
                        </td>
                        <td style={{ padding: "10px" }}>
                          {new Date(item.created_on).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Fragment>
              ))}
            </table>
          </>
        ) : (
          <p
            style={{
              color: "#3684F2",
              textAlign: "center",
              padding: "24px",
              margin: "0",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            No results found
          </p>
        )}
      </div>
    </div>
  );
}
