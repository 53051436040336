import React, { useEffect, useState } from "react";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { IoCall, IoMic, IoVideocam } from "react-icons/io5";
import { config } from "../../config";
import { getCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";
import { CiStreamOn } from "react-icons/ci";
import { BsPersonVideo } from "react-icons/bs";
import { msToDateString, msToMinuteSec, timeAgo } from "../../utils/time";
import "./history-lite.scss";

function HistoryLite() {
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const meetingTypeEl = {
    outbound_call: {
      color: "success",
      icon: <IoCall />,
    },
    live_call: {
      color: "warning",
      icon: <IoVideocam />,
    },
    audio_call: {
      color: "warning",
      icon: <IoMic />,
    },
    live_streaming: {
      color: "error",
      icon: <CiStreamOn style={{ strokeWidth: 2 }} />,
    },
    conference: {
      color: "primary",
      icon: <BsPersonVideo />,
    },
  };

  const fetchHistory = () => {
    getCall({
      url: config.url.MEETINGS,
      path: `/history/all/${page}/10`,
      onSuccess: (response) => {
        setHistory((prevHistory) => [...prevHistory, ...response.data.records]);
        setLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load the history. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchHistory();

    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="saleassist-history-lite">
      {history.length > 0 && (
        <div className="history-timeline">
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.24,
              },
              py: 0,
            }}
          >
            {history.map((meeting, index) => (
              <>
                {history[index - 1]?.date !== meeting.date && (
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{
                        m: "auto 0",
                        p: "6px 10px 6px 0",
                      }}
                    ></TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineConnector
                        sx={{ opacity: index !== 0 ? 1 : 0 }}
                      />
                      <TimelineDot sx={{ position: "relative" }}>
                        <IoCall />
                        <div className="date-divider">
                          <span>{msToDateString(meeting.scheduled_time)}</span>
                        </div>
                      </TimelineDot>

                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ p: "10px 0 10px 10px" }}
                    ></TimelineContent>
                  </TimelineItem>
                )}

                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0", p: "6px 10px 6px 0", fontSize: "12px" }}
                    align="right"
                    variant="subtitle2"
                    color="text.secondary"
                  >
                    <span className="time-info">
                      {meeting.time}
                      <span>{timeAgo(meeting.scheduled_time)}</span>
                    </span>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineConnector />

                    <TimelineDot
                      color={meetingTypeEl[meeting.meeting_type].color}
                    >
                      {meetingTypeEl[meeting.meeting_type].icon}
                    </TimelineDot>

                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent sx={{ p: "10px 0 10px 10px" }}>
                    <div
                      className={`meeting-card ${
                        meetingTypeEl[meeting.meeting_type].color
                      }-color-card`}
                    >
                      <div className="title">{meeting.meeting_name}</div>

                      <div className="description">
                        {/* <p>
                        <strong>Schedule: </strong> {meeting.time}{" "}
                        {meeting.timezone}
                      </p> */}

                        {meeting.description?.length > 0 && (
                          <p>
                            <strong>Description: </strong>
                            {meeting.description}
                          </p>
                        )}

                        {meeting.source_name && (
                          <p>
                            <strong>Widget: </strong> {meeting.source_name}{" "}
                          </p>
                        )}

                        {/* {meeting.source_referrer && (
                        <p>
                          <strong>Source url: </strong>{" "}
                          {meeting.source_referrer}
                        </p>
                      )} */}

                        <p>
                          {meeting.end_time > 0 && (
                            <span>
                              <strong>Duration: </strong>
                              {msToMinuteSec(
                                meeting.end_time - meeting.start_time
                              )}
                            </span>
                          )}
                        </p>

                        <p>
                          {meeting.cumulative_participant_time > 0 && (
                            <span>
                              <strong>Participant time: </strong>
                              {msToMinuteSec(
                                meeting.cumulative_participant_time
                              )}
                            </span>
                          )}
                        </p>

                        <p>
                          {meeting.recordings.length > 0 && (
                            <span className="text-color-danger">Rec.</span>
                          )}
                          {meeting.streamings.length > 0 && (
                            <span className="text-color-primary">
                              Streamed.
                            </span>
                          )}
                          {meeting.participant_count > 0 && (
                            <span className="text-color-primary">
                              {meeting.participant_count} Attended
                            </span>
                          )}
                          {meeting.public_session_count > 0 && (
                            <span className="text-color-primary">
                              {meeting.public_session_count} Public sessions
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </>
            ))}
          </Timeline>

          <div className="load-more-button">
            <LoadingButton
              size="small"
              loading={loading}
              loadingIndicator="Loading…"
              variant="outlined"
              onClick={() => {
                setPage((prevPage) => prevPage + 1);
                setLoading(true);
              }}
            >
              <span>Load more</span>
            </LoadingButton>
          </div>
        </div>
      )}
    </div>
  );
}

export default HistoryLite;
