import { useState, useEffect } from "react";

import { config } from "../../../config";
import { getCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";

export default function Watermark({ activeClientId, watermark, setWatermark }) {
  const [client, setClient] = useState({});

  useEffect(() => {
    getCall({
      url: config.url.CLIENTS,
      path: activeClientId,
      onSuccess: (response) => {
        setClient(response.data);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load active organization. " +
            ((error.response && error.response.data.detail) || error.toString())
        );
      },
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="watermark">
      {client?.logos?.length === 0 && (
        <p>
          * No logos to choose from. Please add logo in your organization
          settings.
        </p>
      )}

      {client?.logos?.length > 0 && (
        <p className="label-text">
          Choose logo {watermark.length > 0 ? "" : "(please select)"}
        </p>
      )}

      {client?.logos?.map((src) => {
        return (
          <div
            key={"logo_" + src}
            className=""
            style={{
              display: "inline-block",
              cursor: "pointer",
              height: "60px",
              width: "fit-content",
              marginRight: "10px",
              position: "relative",
            }}
            onClick={(e) => {
              e.preventDefault();
              if (watermark === src) {
                setWatermark("");
              } else {
                setWatermark(src);
              }
            }}
          >
            {watermark === src ? (
              <span
                style={{
                  position: "absolute",
                  background: "rgba(0,0,0,0.3)",
                  color: "#ffffff",
                  fontSize: "20px",
                  lineHeight: "20px",
                  height: "20px",
                }}
              >
                <strong>&#10003;</strong>
              </span>
            ) : (
              <span
                style={{
                  position: "absolute",
                  background: "rgba(0,0,0,0.3)",
                  color: "#ffffff",
                  fontSize: "26px",
                  lineHeight: "16px",
                  width: "20px",
                  height: "20px",
                  fontWeight: "bold",
                }}
              >
                <strong>&#8857;</strong>
              </span>
            )}
            <img src={src} alt={src} style={{ height: "100%" }} />
          </div>
        );
      })}
    </div>
  );
}
