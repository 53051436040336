import React from "react";
import { BiError } from "react-icons/bi";
import "./login-error.scss";

function LoginError() {
  return (
    <div className="saleassist-login-error">
      <div className="login-error-placeholder">
        <BiError />
        <p>
          User Not Found,
          <span>Need to login!</span>
        </p>
      </div>
    </div>
  );
}

export default LoginError;
