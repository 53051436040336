import React from "react";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { deleteCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";

const DeleteConfirmationMenu = ({ deleteAnchorEl, itemToBeDeleted, closeDeleteConfirmationMenu, refreshTaskList, setRefreshTaskList }) => {
  const open = Boolean(deleteAnchorEl);

    const deleteItem = () =>
      {
        deleteCall({
          url: config.url.TO_DO,
          path: `${itemToBeDeleted}`,

          onSuccess: (response) => {
            setRefreshTaskList(!refreshTaskList)
            bottomAlert("The task has been removed", "success");
          },
          onFailure: (error) => {
            bottomAlert(
              "Failed to delete task", "warning"
            );
          },
        });
        closeDeleteConfirmationMenu();
      }

  return (
    <>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={deleteAnchorEl}
        open={open}
        onClose={closeDeleteConfirmationMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{padding: '8px'}}
      >
        <p style={{fontWeight: '500', paddingLeft: '15px', paddingRight: '15px'}}>Do you want to delete this task?</p>
        <div style={{paddingRight: '15px', marginTop: '20px', display: 'flex', justifyContent: 'end'}}>
            <Button onClick={closeDeleteConfirmationMenu} className="text-danger">Cancel</Button>
            <Button onClick={() => deleteItem()} clasName="text-success">Delete</Button>
        </div>
      </Menu>
    </>
  );
};
export default DeleteConfirmationMenu;
