export function swapScreen(element) {
  const videoCallMainScreenEl = document.getElementById("videoCallMainScreen");
  const videoCallMainScreenChildDivEl = document.querySelector(
    "#videoCallMainScreen div"
  );
  const videoCallVideoTilesChildDivEl = document.querySelectorAll(
    "#videoCallVideoTiles .video-call-wrapper"
  );
  const videoCallVideoTilesEl = document.getElementById("videoCallVideoTiles");

  if (element.id !== videoCallMainScreenChildDivEl.id) {
    videoCallVideoTilesChildDivEl.forEach((el) => {
      if (el.id === element.id) {
        videoCallVideoTilesEl.appendChild(videoCallMainScreenChildDivEl);
        videoCallMainScreenEl.appendChild(el);
        // videoCallVideoTilesEl.removeChild(el);
      }
    });
  }
}
