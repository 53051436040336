import VirtualBackgroundExtension from "agora-extension-virtual-background";
import { loadImage } from "./virtualBackgroundHandler";

export async function enableVirtualBackground(AgoraRTC, el) {
  const virtualBackgorundWindowTabsOverlayEl = document.querySelector(
    ".virtual-backgorund-window-tabs-overlay"
  );
  const virtualBackgroundWindowTabsContEl = document.querySelector(
    ".virtual-background-window-tabs-cont"
  );

  const pipeProcessor = (track, processor) => {
    track.pipe(processor).pipe(track.processorDestination);
  };

  this.denoiser =
    this.denoiser ||
    (() => {
      let denoiser = new VirtualBackgroundExtension();
      AgoraRTC.registerExtensions([denoiser]);
      return denoiser;
    })();

  this.processor =
    this.processor ||
    (await (async () => {
      let processor = this.denoiser.createProcessor();
      processor.eventBus.on("PERFORMANCE_WARNING", () => {
        console.warn("Performance warning!");
      });

      try {
        await processor.init(
          "./node_module/agora-extension-virtual-background/wasms"
        );
      } catch (error) {
        console.error(error);
        processor = null;
      }
      return processor;
    })());

  await pipeProcessor(this.localVideoTrack, this.processor);

  if (el.checked) {
    try {
      if (this.ClientContext.is_visible) {
        this.processor.setOptions({
          type: "img",
          source: await loadImage(this.ClientContext.virtual_bg),
        });
      }
      await this.processor.enable();

      virtualBackgorundWindowTabsOverlayEl.style.display = "none";
      virtualBackgroundWindowTabsContEl.style.opacity = "1";
    } catch (e) {
      console.error("enable VirtualBackground failure", e);
    }
  } else {
    try {
      await this.processor.disable();
      virtualBackgorundWindowTabsOverlayEl.style.display = "block";
      virtualBackgroundWindowTabsContEl.style.opacity = "0.2";
    } catch (e) {
      console.error("disable VirtualBackground failure", e);
    }
  }
}
