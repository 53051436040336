import { useContext, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";

import { UserContext } from "../../../context/UserContext";
import { config } from "../../../config";
import { getCall, postCall, startMeeting } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { validateForm } from "../shared/validateForm";
import MeetingNameAndDescription from "../shared/MeetingNameAndDescription";
import ModeratorsAndParticipants from "../shared/ModeratorsAndParticipants";
import Watermark from "../shared/Watermark";
import "../shared/meeting.scss";

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: #1C2025;
    background: #fff;
    border: 1px solid #DAE2ED;
    box-shadow: 0px 2px 2px #F3F6F9;

    &:hover {
      border-color: #3399FF;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #b6daff;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

export default function LiveStreaming() {
  const { User } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [meetingName, setMeetingName] = useState("Instant Livestreaming");
  const [meetingDescription, setMeetingDescription] = useState(
    "Instant Livestreaming"
  );
  const [moderators, setModerators] = useState([User?.user_name]);
  const [participants, setParticipants] = useState([]);
  const [watermark, setWatermark] = useState("");
  const [isModeratedChat, setIsModeratedChat] = useState(false);
  const [moderationMode, setModerationMode] = useState("auto_censored");
  const [moderatedKeywords, setModeratedKeywords] = useState([
    "badword",
    "anotherbadword",
  ]);
  const [isProductsEnabled, setIsProductsEnabled] = useState(false);
  const [productsListingMode, setProductsListingMode] = useState("buy");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [channels, setChannels] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const navigate = useNavigate();

  const fetchProducts = () => {
    getCall({
      url: config.url.PRODUCTS,
      path: "list",
      onSuccess: (response) => {
        const meeting_products = [];
        response.data.forEach((product) => {
          meeting_products.push({
            id: product.id,
            name: product.product_name,
          });
        });
        setProducts(meeting_products);
      },
      onFailure: (error) => {
        if (!error.response || error.response.status !== 404) {
          bottomAlert(
            "Unable to get products list. " +
              ((error.response && error.response.data.detail) ||
                error.toString())
          );
        }
      },
    });
  };

  const fetchWidgets = () => {
    getCall({
      url: config.url.SOURCES,
      onSuccess: (response) => {
        const widgets = [];
        response.data.forEach((source) => {
          widgets.push({
            id: source.id,
            name: source.name ? source.name : "Undefined Widget",
          });
          setWidgets(widgets);
        });
      },
      onFailure: (error) => {
        if (!error.response || error.response.status !== 404) {
          bottomAlert(
            "Unable to get widgets list. " +
              ((error.response && error.response.data.detail) ||
                error.toString())
          );
        }
      },
    });
  };

  const fetchChannels = () => {
    getCall({
      url: config.url.CHANNELS,
      onSuccess: (response) => {
        const channels = [
          {
            id: "widget",
            name: "widget",
          },
        ];
        response.data.records.forEach((channel) => {
          channels.unshift({
            id: channel.id,
            name: channel.name,
          });
        });
        setChannels(channels);
      },
      onFailure: (error) => {
        if (!error.response || error.response.status !== 404) {
          bottomAlert(
            "Unable to get channels list. " +
              ((error.response && error.response.data.detail) ||
                error.toString())
          );
        } else {
          setChannels([
            {
              id: "widget",
              name: "widget",
            },
          ]);
        }
      },
    });
  };

  useEffect(() => {
    fetchProducts();
    fetchWidgets();
    fetchChannels();
    // eslint-disable-next-line
  }, []);

  const onSubmit = () => {
    if (!validateForm(meetingName, meetingDescription)) {
      return;
    }

    setIsLoading(true);
    postCall({
      url: config.url.MEETINGS,
      data: {
        meeting_type: "live_streaming",
        meeting_name: meetingName,
        meeting_description: meetingDescription,
        moderators: moderators,
        participants: participants,
        watermark: watermark,
        moderation_mode: moderationMode,
        moderated_keywords: moderatedKeywords,
        products_listing_mode: productsListingMode,
        products: selectedProducts.map((product) => product.id),
        widgets: selectedWidgets.map((widget) => widget.id),
        channels: selectedChannels.map((channel) => channel.id),
      },
      onSuccess: (response) => {
        startMeeting({
          meeting: response.data,
          onSuccess: () => {
            setIsLoading(false);
            navigate(`/meetings/live/${response.data.id}`);
          },
          onFailure: () => {
            setIsLoading(false);
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Your meeting could not be created. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
  };

  if (isLoading) {
    return (
      <div
        className="new-meeting-container"
        style={{
          display: "grid",
          placeContent: "center",
          height: "270px",
        }}
      >
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="meeting-container">
      <p className="meeting-title">Tell us about your meeting</p>
      <p
        className="label-text"
        style={{ marginRight: "3px", display: "inline-block" }}
      >
        Meeting type: Livestreaming
      </p>

      <MeetingNameAndDescription
        meetingType="Livestreaming"
        meetingName={meetingName}
        setMeetingName={setMeetingName}
        meetingDescription={meetingDescription}
        setMeetingDescription={setMeetingDescription}
      />

      <ModeratorsAndParticipants
        moderators={moderators}
        setModerators={setModerators}
        participants={participants}
        setParticipants={setParticipants}
      />

      <div className="registration-form-checkbox-wrapper">
        <label htmlFor="registration-form">Enable chat moderation</label>
        <Checkbox
          id="registration-form"
          checked={isModeratedChat}
          onChange={(e) => setIsModeratedChat(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      {isModeratedChat && (
        <FormControl
          sx={{
            marginLeft: "6px",
          }}
        >
          <RadioGroup
            row
            value={moderationMode}
            onChange={(e) => setModerationMode(e.target.value)}
          >
            <FormControlLabel
              value="auto_censored"
              control={<Radio />}
              label="Censor automatically"
            />
            <FormControlLabel
              value="auto_clean_words"
              control={<Radio />}
              label="Censor additional words"
            />
            <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Manually approve"
            />
          </RadioGroup>
        </FormControl>
      )}

      {moderationMode === "auto_clean_words" && (
        <div>
          <p>Bad words separated by space</p>
          <Textarea
            minRows={3}
            placeholder="Words to be censored"
            value={moderatedKeywords.join(" ")}
            onChange={(e) =>
              setModeratedKeywords(
                (e.target.value.split(/[,;\s]+/g) || []).map((word) =>
                  word.trim()
                )
              )
            }
          />
        </div>
      )}

      <Watermark
        activeClientId={User.active_client_id}
        watermark={watermark}
        setWatermark={setWatermark}
      />

      <div className="registration-form-checkbox-wrapper">
        <label htmlFor="products-list">List products</label>
        <Checkbox
          id="products-list"
          checked={isProductsEnabled}
          onChange={(e) => setIsProductsEnabled(e.target.checked)}
        />
      </div>

      {isProductsEnabled && (
        <div>
          <p>Buy mode</p>
          <FormControl
            sx={{
              marginLeft: "6px",
            }}
          >
            <RadioGroup
              row
              value={productsListingMode}
              onChange={(e) => setProductsListingMode(e.target.value)}
            >
              <FormControlLabel value="buy" control={<Radio />} label="Buy" />
              <FormControlLabel value="bid" control={<Radio />} label="Bid" />
              <FormControlLabel value="book" control={<Radio />} label="Book" />
            </RadioGroup>
          </FormControl>
          <Autocomplete
            value={selectedProducts}
            multiple
            onChange={(event, newValue) => {
              setSelectedProducts(newValue);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={products}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            sx={{ width: "100%", marginTop: "20px" }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Select product (s)" />
            )}
          />
        </div>
      )}
      <div>
        <Autocomplete
          value={selectedWidgets}
          multiple
          onChange={(event, newValue) => {
            setSelectedWidgets(newValue);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={widgets}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          sx={{ width: "100%", marginTop: "20px" }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Select Widgets" />
          )}
        />

        <Autocomplete
          value={selectedChannels}
          multiple
          onChange={(event, newValue) => {
            setSelectedChannels(newValue);
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={channels}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          sx={{ width: "100%", marginTop: "20px" }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Select Channels" />
          )}
        />
      </div>

      <Button onClick={onSubmit} variant="contained" sx={{ marginTop: "24px" }}>
        Start LiveStreaming
      </Button>
    </div>
  );
}
