import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function bottomAlert(
  message,
  message_type = "success",
  onClick,
  chime,
  chime_file
) {
  var audio;
  if (chime) {
    if (chime_file) {
      audio = new Audio(chime_file);
      audio.play();
    } else {
      audio = new Audio("/uploads/sounds/Store_Door_Chime.mp3");
      audio.play();
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "bottom-right",
    type: message_type,
    onclick: onClick,
    theme: "colored",
  });
}

export function topAlert(
  message,
  message_type = "success",
  onClick,
  chime,
  chime_file
) {
  var audio;
  if (chime) {
    if (chime_file) {
      audio = new Audio(chime_file);
      audio.play();
    } else {
      audio = new Audio("/uploads/sounds/Store_Door_Chime.mp3");
      audio.play();
    }
  }
  toast(message, {
    transition: Bounce,
    closeButton: true,
    autoClose: 5000,
    position: "top-right",
    type: message_type,
    onclick: onClick,
    theme: "colored",
  });
}

export function soundAlert(sound_file) {
  var audio;
  if (sound_file) {
    audio = new Audio(sound_file);
    audio.play();
  } else {
    audio = new Audio("/uploads/sounds/Store_Door_Chime.mp3");
    audio.play();
  }
}
