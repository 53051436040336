import { useContext, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { useSearchParams } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";
import { config } from "../../../config";
import { postCall, startMeeting } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { validateForm } from "../shared/validateForm";
import MeetingNameAndDescription from "../shared/MeetingNameAndDescription";
import ModeratorsAndParticipants from "../shared/ModeratorsAndParticipants";
import Watermark from "../shared/Watermark";
import "../shared/meeting.scss";

export default function NewMeeting() {
  const { User } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [meetingName, setMeetingName] = useState("Instant Conference");
  const [meetingDescription, setMeetingDescription] =
    useState("Instant Conference");
  const [moderators, setModerators] = useState([User?.user_name]);

  // setting the participant value if page is redirected from conversation panel
  const [participants, setParticipants] = useState(searchParams.get('participant') ? ["+" + searchParams.get('participant').slice(1)] : []);
  const [watermark, setWatermark] = useState("");
  const navigate = useNavigate();

  const onSubmit = () => {
    if (!validateForm(meetingName, meetingDescription)) {
      return;
    }

    setIsLoading(true);
    postCall({
      url: config.url.MEETINGS,
      data: {
        meeting_type: "conference",
        meeting_name: meetingName,
        meeting_description: meetingDescription,
        moderators: moderators,
        participants: participants,
        watermark: watermark,
      },
      onSuccess: (response) => {
        startMeeting({
          meeting: response.data,
          onSuccess: () => {
            setIsLoading(false);
            navigate(`/meetings/live/${response.data.id}`);
          },
          onFailure: () => {
            setIsLoading(false);
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Your meeting could not be created. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
  };

  if (isLoading) {
    return (
      <div
        className="new-meeting-container"
        style={{
          display: "grid",
          placeContent: "center",
          height: "270px",
        }}
      >
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="meeting-container">
      <p className="meeting-title">Tell us about your meeting</p>
      <p
        className="label-text"
        style={{ marginRight: "3px", display: "inline-block" }}
      >
        Meeting type: Conference
      </p>

      <MeetingNameAndDescription
        meetingType="Conference"
        meetingName={meetingName}
        setMeetingName={setMeetingName}
        meetingDescription={meetingDescription}
        setMeetingDescription={setMeetingDescription}
      />

      <ModeratorsAndParticipants
        moderators={moderators}
        setModerators={setModerators}
        participants={participants}
        setParticipants={setParticipants}
      />

      <Watermark
        activeClientId={User.active_client_id}
        watermark={watermark}
        setWatermark={setWatermark}
      />

      <Button onClick={onSubmit} variant="contained" sx={{ marginTop: "24px" }}>
        Start Conference
      </Button>
    </div>
  );
}
