import { config } from "../../../config";
import { postCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";

export function startLivestream(element = null) {
  var LiveTranscoding = {
    width: 1920,
    height: 1080,
    videoBitrate: 4780,
    videoFramerate: 30,
    audioSampleRate: 48000,
    audioBitrate: 48,
    audioChannels: 1,
    videoGop: 30,
    videoCodecProfile: 100,
    userCount: 2,
    backgroundColor: 0x000000,
    transcodingUsers: [
      {
        x: 0,
        y: 0,
        width: 1920,
        height: 1080,
        zOrder: 0,
        alpha: 1.0,
        uid: this.agoraUID,
      },
    ],
  };
  postCall({
    url: config.url.MEETINGS,
    path: `live_stream_start_stop/${this.meeting_data.id}/start`,
    onSuccess: (response) => {
      if (element) {
        element.innerText = "Live";
        element.style.background = "#FF3131";
      }

      postCall({
        url: config.url.ACTIONS,
        path: `/meeting/start_all_streamings/${this.meeting_data.id}`,
        onSuccess: async (response) => {
          console.log(response);
          let channels = response.data;
          channels.map(async (channel) => {
            await this.client.setLiveTranscoding(LiveTranscoding);
            await this.client.startLiveStreaming(channel.stream_url, true);
          });
        },
        onFailure: (error) => {
          bottomAlert(
            "Unable to stream the channels " +
              (error.response ? error.response.data.detail : error.toString()),
            "error"
          );
        },
      });
    },
    onFailure: (error) => {
      bottomAlert(
        "Unable to start live-stream " +
          (error.response ? error.response.data.detail : error.toString()),
        "error"
      );
    },
  });
}

export function stopLivestream(element = null) {
  postCall({
    url: config.url.MEETINGS,
    path: `live_stream_start_stop/${this.meeting_data.id}/stop`,
    onSuccess: async (response) => {
      if (element) {
        element.innerText = "Go Live";
        element.style.background = "#777672";
      }

      postCall({
        url: config.url.ACTIONS,
        path: `/meeting/stop_all_streamings/${this.meeting_data.id}`,
        onSuccess: async (response) => {
          let channels = response.data;
          channels.map(async (channel) => {
            await this.client.stopLiveStreaming(channel.stream_url);
          });
        },
        onFailure: (error) => {
          bottomAlert(
            "Unable to stream the channels " +
              (error.response ? error.response.data.detail : error.toString()),
            "error"
          );
        },
      });
    },
    onFailure: (error) => {
      bottomAlert(
        "Unable to stop live-stream " +
          (error.response ? error.response.data.detail : error.toString()),
        "error"
      );
    },
  });
}
