import { useContext, useEffect, useRef } from "react";
import $ from "jquery";
import { FormContext } from "../../../context/FormContext";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const FormBuilder = ({ formData, id }) => {
  const fbEditorRef = useRef(null);
  const { setFormData } = useContext(FormContext);

  const options = {
    actionButtons: [],
    disabledActionButtons: ["data", "save"],
    controlOrder: [
      "text",
      "number",
      "radio-group",
      "select",
      "checkbox-group",
      "starRating",
      "textarea",
      "autocomplete",
      "button",
      "date",
      "file",
      "header",
      "hidden",
      "paragraph",
    ],
    disableFields: ["button", "date", "file", "header", "autocomplete"],
    defaultFields: formData
      ? formData
      : [
          {
            type: "paragraph",
            subtype: "p",
            label: "Please fill in your details",
            access: false,
          },
          {
            type: "text",
            required: true,
            label: "Name",
            placeholder: "Enter your name",
            className: "form-control",
            name: "name",
            access: false,
            subtype: "text",
          },
          {
            type: "text",
            required: true,
            label: "Email",
            placeholder: "Enter your email",
            className: "form-control",
            name: "email",
            access: false,
            subtype: "text",
          },
          {
            type: "text",
            required: false,
            label: "Mobile number",
            placeholder: "Enter your mobile number",
            className: "form-control",
            name: "mobile_number",
            access: false,
            subtype: "text",
          },
        ],
  };

  const formId = id ? id : "fb-editor";

  useEffect(() => {
    const fbEditor = fbEditorRef.current;
    const form = $(fbEditor).formBuilder(options);
    setFormData(formId, form);
    // eslint-disable-next-line
  }, []);

  return <div id={formId} ref={fbEditorRef} />;
};

export default FormBuilder;
