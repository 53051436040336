export const loadImage = url => {
    return new Promise((resolve, reject) => {
      if (!url) {
        reject(new Error("url is empty"));
      }
      const image = new Image();
      image.src = url;
      image.crossOrigin = "anonymous";
      image.onload = () => {
        resolve(image);
      };
    });
};

export function virtualBackgroundHandler (el) {
    const videoBackgroundBlurContEl = document.querySelector(".video-background-blur-cont");
    const videoBackgroundColorContEl = document.querySelector(".video-background-color-cont");
    const videoBackgroundImageContEl = document.querySelector(".video-background-image-cont");

    const activeTab = document.querySelector(".tab-text-Button.active");
    const type = el.getAttribute('id')

    if(activeTab){
        activeTab.classList.remove("active");
    }

    el.classList.add("active");

    if(type === "blur") {
        const videoBackgroundBlurBtnEl = document.querySelectorAll(".video-background-blur-btn");

        videoBackgroundBlurContEl.style.display = "flex";
        videoBackgroundColorContEl.style.display = "none";
        videoBackgroundImageContEl.style.display = "none";

        videoBackgroundBlurBtnEl.forEach(btn => {
            btn.addEventListener("click", () => {
                const activeButton = document.querySelector(".active-virtual-background");

                if(activeButton){
                    activeButton.classList.remove("active-virtual-background");
                }

                btn.classList.add("active-virtual-background")
                this.processor.setOptions({type: 'blur', blurDegree: Number(btn.getAttribute("blur-value"))});
            });
        });
    }

    if(type === "color") {
        const videoBackgroundColorBtnEl = document.querySelectorAll(".video-background-color-btn");
        const videoBackgroundCustomColorEl = document.querySelector("#video-background-custom-color");
        const videoBackgroundColorCustomBtn = document.querySelector(".video-background-color-custom-btn");

        videoBackgroundBlurContEl.style.display = "none";
        videoBackgroundColorContEl.style.display = "flex";
        videoBackgroundImageContEl.style.display = "none";

        videoBackgroundColorBtnEl.forEach(btn => {
            btn.addEventListener("click", () => {
                const activeButton = document.querySelector(".active-virtual-background");

                if(activeButton){
                    activeButton.classList.remove("active-virtual-background");
                }

                btn.classList.add("active-virtual-background")
                this.processor.setOptions({type: 'color', color: btn.getAttribute("color-value")});
            });
        });

        videoBackgroundCustomColorEl.addEventListener("change", (e) => {
            const activeButton = document.querySelector(".active-virtual-background");
            
            videoBackgroundColorCustomBtn.style.background = e.target.value;

            if(activeButton){
                activeButton.classList.remove("active-virtual-background");
            }

            videoBackgroundColorCustomBtn.classList.add("active-virtual-background")
            this.processor.setOptions({type: 'color', color: e.target.value});
        });
    }

    if(type === "image") {
        const videoBackgroundImageBtnEl = document.querySelectorAll(".video-background-image-btn");
        const videoBackgroundCustomImageEl = document.querySelector("#video-background-custom-image");
        const videoBackgroundImageCustomBtn = document.querySelector(".video-background-image-custom-btn");

        videoBackgroundBlurContEl.style.display = "none";
        videoBackgroundColorContEl.style.display = "none";
        videoBackgroundImageContEl.style.display = "flex";

        videoBackgroundImageBtnEl.forEach(btn => {
            btn.addEventListener("click", async () => {
                const activeButton = document.querySelector(".active-virtual-background");

                if(activeButton){
                    activeButton.classList.remove("active-virtual-background");
                }

                btn.classList.add("active-virtual-background")
                this.processor.setOptions({type: 'img', source: await loadImage(btn.getAttribute("image-value"))});
            });
        });

        videoBackgroundCustomImageEl.addEventListener("change", (e) => {
            const activeButton = document.querySelector(".active-virtual-background");
            const selectedFile = e.target.files[0];

            if (selectedFile) {
                const imageURL = URL.createObjectURL(selectedFile);
                const image = new Image();

                image.onload = () => {
                    videoBackgroundImageCustomBtn.style.background = `url(${imageURL}) center/cover no-repeat`;

                    if(activeButton){
                        activeButton.classList.remove("active-virtual-background");
                    }
        
                    videoBackgroundImageCustomBtn.classList.add("active-virtual-background")
                    this.processor.setOptions({type: 'img', source: image});
                }

                image.src = imageURL;
            }
        });
    }
}