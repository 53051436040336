import React, { useEffect } from "react";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { MdRateReview } from "react-icons/md";
// import { TbBrowserPlus } from "react-icons/tb";
import { BiSolidUserDetail } from "react-icons/bi";

function DraggableSpeedDial({
  callRequest,
  activeRequestHandle,
  renderModalDataHandle,
}) {
  const actions = [
    {
      icon: (
        <MdRateReview
          className="sa-video-call-action-button"
          onClick={() => {
            renderModalDataHandle("agent_feedback");
            activeRequestHandle();
          }}
        />
      ),
      name: "Agent feedback",
    },
    // {
    //   icon: (
    //     <TbBrowserPlus
    //       onClick={() => {
    //         window.open(
    //           `${callRequest.source_referrer}?startCoBrowsing=true&user=${callRequest.user_display_name}&room=${callRequest.meeting_id}`,
    //           "_blank"
    //         );
    //       }}
    //       className="sa-video-call-action-button"
    //     />
    //   ),
    //   name: "Co-browsing",
    // },
    {
      icon: (
        <BiSolidUserDetail
          className="sa-video-call-action-button"
          onClick={() => {
            renderModalDataHandle("active_request");
            activeRequestHandle();
          }}
        />
      ),
      name: "Active Request",
    },
  ];

  function dragElement(elmnt) {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown;

    function dragMouseDown(e) {
      e = e.type === "touchstart" ? e.touches[0] : e;

      pos3 = e.clientX;
      pos4 = e.clientY;

      document.onmouseup = closeDragElement;
      document.ontouchend = closeDragElement;
      document.onmousemove = elementDrag;
      document.ontouchmove = elementDrag;
    }

    function elementDrag(e) {
      e = e.type === "touchmove" ? e.touches[0] : e;

      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      const container = document.querySelector(".saleassist-meeting-lite");
      const containerRect = container.getBoundingClientRect();

      const minX = 0;
      const maxX = containerRect.width - elmnt.offsetWidth;
      const minY = 0;
      const maxY = containerRect.height - elmnt.offsetHeight;

      let top = elmnt.offsetTop - pos2;
      let left = elmnt.offsetLeft - pos1;

      // Check boundaries for X-axis
      if (left <= minX) {
        left = minX;
      } else if (left >= maxX) {
        left = maxX;
      }

      // Check boundaries for Y-axis
      if (top <= minY) {
        top = minY;
      } else if (top >= maxY) {
        top = maxY;
      }

      // Calculate new position
      elmnt.style.top = top + "px";
      elmnt.style.left = left + "px";
    }

    function closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchend = null;
      document.ontouchmove = null;
    }
  }

  useEffect(() => {
    const draggableDiv = document.getElementById("draggable-speed-dial");

    if (draggableDiv) {
      dragElement(draggableDiv);
    }
  }, []);

  return (
    <div
      id="draggable-speed-dial"
      style={{
        position: "absolute",
        top: "calc(100% - 400px)",
        left: "calc(100% - 66px)",
        zIndex: 10,
      }}
    >
      <SpeedDial ariaLabel="SpeedDial" icon={<SpeedDialIcon />} direction="up">
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

export default DraggableSpeedDial;
