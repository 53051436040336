import { bottomAlert } from "../../../utils/toastAlerts";

export const validateForm = (meetingName = "", meetingDescription = "") => {
  if (!meetingName.trim()) {
    bottomAlert("Please provide a meeting name.", "info");
    return false;
  }
  if (!meetingDescription.trim()) {
    bottomAlert("Please provide a description for your meeting.", "info");
    return false;
  }
  return true;
};
