import React, { createContext, useState } from "react";

const PermissionsContext = createContext();

const PermissionsProvider = ({ children }) => {
  const [Permissions, setPermissions] = useState({});

  return (
    <PermissionsContext.Provider value={{ Permissions, setPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export { PermissionsContext, PermissionsProvider };
