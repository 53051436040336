export function volumeIndicator(AgoraRTC) {
  AgoraRTC.setParameter("AUDIO_VOLUME_INDICATION_INTERVAL", 200);
  this.client.enableAudioVolumeIndicator();

  this.client.on("volume-indicator", (volumes) => {
    volumes.forEach((volume) => {
      //   console.log("VOLUME", volume);

      let volIndicatorEl = document.querySelector(
        `.active-speaker-${volume.uid} .volume-indicator`
      );

      let volBorderIndicatorEl = document.querySelector(
        `.active-speaker-${volume.uid}`
      );

      let speakingWaveeffectEl = volBorderIndicatorEl.querySelector(
        ".speaking-wave-effect"
      );

      if (volume.level >= 60) {
        showSpeakingBars(volume.level, volIndicatorEl, true);
        volBorderIndicatorEl.style.borderColor = "#1a73e8";
        speakingWaveeffectEl.style.width = "200px";
        speakingWaveeffectEl.style.height = "200px";
      } else {
        showSpeakingBars(volume.level, volIndicatorEl, false);
        volBorderIndicatorEl.style.borderColor = "#7d7d7d";
        speakingWaveeffectEl.style.width = "0px";
        speakingWaveeffectEl.style.height = "0px";
      }
    });
  });
}

function showSpeakingBars(volumeLevel, element, isSpeaking) {
  const sideBarHeight = isSpeaking ? (volumeLevel / 100) * 40 : 15;
  const middleBarHeight = isSpeaking ? (volumeLevel / 100) * 60 : 15;

  element.querySelector("#bar1").style.height = sideBarHeight + "%";
  element.querySelector("#bar2").style.height = middleBarHeight + "%";
  element.querySelector("#bar3").style.height = sideBarHeight + "%";
}
