import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getCall } from "../../utils/methods";
import { config } from "../../config";
import { HiMiniBellAlert } from "react-icons/hi2";
import { bottomAlert } from "../../utils/toastAlerts";

const PendingTasksReminder = () => {

  const [totalPendingTasks, setTotalPendingTasks] = useState(0);
  const [showStrip, setShowStrip] = useState(true);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const getData = () => {
    getCall({
      url: config.url.TO_DO,
      path: `/eta/list?timezone=${localTimeZone}`,

      onSuccess: (response) => {
        setTotalPendingTasks(response.data.count);
        // bottomAlert("Task table updated", "success");
      },
      onFailure: (error) => {
        bottomAlert("Todo Tasks loading failed", "warning");
      },
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section
        style={{
          display: totalPendingTasks && showStrip ? "flex" : "none",
          padding: "2px",
          backgroundColor: "red",
          color: "white",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <p style={{
          width: "-webkit-fill-available",
          margin: "0px",
          fontSize: "12px",
          display: "flex",
          justifyContent: "center"
        }}>
          <HiMiniBellAlert style={{ fontSize: '16px', marginRight: '5px' }} />
          {`You have ${totalPendingTasks} overdue tasks on priority !`}
        </p>
        <IoMdClose style={{ fontSize: '16px', cursor: 'pointer' }} onClick={(e) => setShowStrip(false)} />
      </section>
    </>
  );
};
export default PendingTasksReminder;
