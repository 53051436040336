import React, { createContext, useState } from "react";

const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const [Socket, setSocket] = useState({});

  return (
    <SocketContext.Provider value={{ Socket, setSocket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
