import React, { useState, useEffect, useRef, useContext } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { BiFilterAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { IconButton, MenuItem, Popover, Badge } from "@mui/material";
import { getCall } from "../../utils/methods";
import { config } from "../../config";
import { debounce } from "../../utils/debounce";
import { bottomAlert } from "../../utils/toastAlerts";
import Leads from "./Leads/Leads";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LinearProgress from "@mui/material/LinearProgress";
import { NotificationContext } from "../../context/NotificationContext";

import "./conversation.scss";

export default function Conversation({ tab }) {
  const { Notification, setNotification } = useContext(NotificationContext);

  const [leads, setLeads] = useState([]);
  const [leadsCount, setLeadsCount] = useState(0);
  const [selectedWidgetId, setSelectedWidgetId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [tabValue, setTabValue] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [widgets, setWidgets] = useState([]);
  const [widgetFilter, setWidgetFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [datafetching, setDataFetching] = useState(false);

  const navigate = useNavigate();
  const prevTabValueRef = useRef();
  const prevPageValueRef = useRef();
  const pageItemsCount = 10;

  const getPath = (noSearchVal = false) => {
    const searchVal = noSearchVal ? "" : searchInput;
    let path;

    if (tab === "1") {
      path = `${
        selectedWidgetId ? "/filter_by_widget/" + selectedWidgetId : "/filter"
      }/${
        searchVal.length > 0 ? searchVal + "/" : ""
      }${pageCount}/${pageItemsCount}`;
    }

    if (tab === "2") {
      // path = `/whatsapp_filter/${pageCount}/${pageItemsCount}`;
      path = `${
        selectedWidgetId
          ? "/whatsapp_filter_by_widget/" + selectedWidgetId
          : "/whatsapp_filter"
      }/${
        searchVal.length > 0 ? searchVal + "/" : ""
      }${pageCount}/${pageItemsCount}`;
    }

    return path;
  };

  const hasUniqueField = (array, value) => {
    for (const obj of array) {
      if (obj.unique_field_value === value) {
        return false;
      }
    }

    return true;
  };

  const updateNotificationCount = (leads) => {
    let unreadChat = leads?.filter((lead) => lead.unread_conversations > 0);

    if (unreadChat.length > 0) {
      unreadChat = unreadChat.filter((lead) =>
        hasUniqueField(
          Notification?.whatsappNotification ?? [],
          lead.unique_field_value
        )
      );

      setNotification((prev) => ({
        ...prev,
        whatsappNotification: [
          ...(prev?.whatsappNotification ?? []),
          ...unreadChat,
        ],
      }));
    }
  };

  const fetchLeads = (noSearchVal = false) => {
    setDataFetching(loading ? false : true);

    getCall({
      url: config.url.PEOPLE,
      path: getPath(noSearchVal),
      onSuccess: (response) => {
        setLeads((leads) => [...leads, ...response.data.records]);
        setLeadsCount(response.data.count);
        setDataFetching(false);
        setLoading(false);

        if (tab === "2") {
          updateNotificationCount(response.data.records);
        }
      },
      onFailure: (error) => {
        setDataFetching(false);
        setLoading(false);

        console.log(error);
      },
    });
  };

  useEffect(() => {
    fetchLeads();
    // eslint-disable-next-line
  }, [selectedWidgetId, pageCount]);

  useEffect(() => {
    setTabValue(tab);

    if (prevTabValueRef.current !== undefined) {
      if (prevTabValueRef.current !== tabValue) {
        if (pageCount !== 1) {
          setLeads([]);
          setPageCount(1);
        } else {
          setLeads([]);
          fetchLeads();
        }
      }
    }

    prevTabValueRef.current = tabValue;
    prevPageValueRef.current = pageCount;

    // eslint-disable-next-line
  }, [tabValue, tab]);

  useEffect(() => {
    getCall({
      url: config.url.SOURCES,
      onSuccess: (response) => {
        const widgets = [];
        response.data.forEach((source) => {
          widgets.push({ id: source.id, name: source.name });
        });
        setWidgets(widgets);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load widget data. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
    // eslint-disable-next-line
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    if (newValue === "1") {
      navigate("/meetings/leads");
    }

    if (newValue === "2") {
      navigate("/meetings/whatsapp");
    }
  };

  const handleFilterOpen = (event) => {
    setWidgetFilter(event.currentTarget);
  };

  const handleFilterClose = () => {
    setWidgetFilter(null);
  };

  const onWidgetChanged = (widgetId) => {
    setLeads([]);
    setPageCount(1);
    setSelectedWidgetId(widgetId || "");
    setWidgetFilter(null);
  };

  return (
    <div className="conversations-container">
      <div className="conversations-container-header">
        {/* Search Element Start */}
        <div className="custom-search">
          <input
            type="text"
            className="search-input"
            placeholder="Search..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                setLeads([]);
                fetchLeads();
              }
            }}
          />
          {searchInput.length > 0 ? (
            <div
              className="search-icon-wrapper"
              onClick={() => {
                setSearchInput("");
                setLeads([]);
                fetchLeads(true);
              }}
            >
              <FaTimes className="cancel-icon" color="#a00" />
            </div>
          ) : (
            <div
              className="search-icon-wrapper"
              onClick={debounce(() => {
                setLeads([]);
                fetchLeads();
              })}
            >
              <FaSearch className="search-icon" color="#999" />
            </div>
          )}
        </div>
        {/* Search Element End */}

        {/* Filter Element Start */}
        <div style={{ position: "relative" }}>
          <IconButton
            aria-describedby={Boolean(
              widgetFilter ? "simple-popover" : undefined
            )}
            size="small"
            onClick={handleFilterOpen}
          >
            <BiFilterAlt size={24} color="#aaa" />
          </IconButton>

          <Popover
            anchorEl={widgetFilter}
            open={Boolean(widgetFilter)}
            onClose={handleFilterClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: "250px",
                },
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              onClick={() => {
                onWidgetChanged();
              }}
              style={{
                fontSize: 12,
                background: selectedWidgetId === "" && "#02307D",
                color: selectedWidgetId === "" && "white",
                fontWeight: selectedWidgetId === "" && "bold",
              }}
            >
              Widgets: All
            </MenuItem>
            {widgets?.map((widget) => (
              <MenuItem
                key={widget.id}
                onClick={() => {
                  onWidgetChanged(widget.id);
                }}
                style={{
                  fontSize: 12,
                  background: selectedWidgetId === widget.id && "#02307D",
                  color: selectedWidgetId === widget.id && "white",
                  fontWeight: selectedWidgetId === widget.id && "bold",
                }}
              >
                {widget.name ? widget.name : "Undefined Widget"}
              </MenuItem>
            ))}
          </Popover>
        </div>
        {/* Filter Element Start */}
      </div>

      {/* Multi-tab Element Start*/}
      {tabValue && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="Leads" value="1" />
              <Tab
                label="WhatsApp"
                value="2"
                className="chat-notification"
                iconPosition="end"
                icon={
                  <Badge
                    color="primary"
                    badgeContent={Notification?.whatsappNotification?.length}
                    max={9}
                  />
                }
              />
            </TabList>
          </Box>

          {datafetching ? (
            <LinearProgress sx={{ m: "-3px" }} />
          ) : leadsCount === 0 ? (
            <span>No leads yet!</span>
          ) : (
            <div className="conversations-container-body">
              <TabPanel value="1">
                <Leads
                  leads={leads}
                  leadsCount={leadsCount}
                  selectedWidgetId={selectedWidgetId}
                  searchInput={searchInput}
                  pageCount={pageCount}
                  pageItemsCount={pageItemsCount}
                  setPageCount={(page) => setPageCount(page)}
                  loading={loading}
                  setLoading={(bool) => setLoading(bool)}
                />
              </TabPanel>

              <TabPanel value="2">
                <Leads
                  whatsapp
                  leads={leads}
                  leadsCount={leadsCount}
                  selectedWidgetId={selectedWidgetId}
                  searchInput={searchInput}
                  pageCount={pageCount}
                  pageItemsCount={pageItemsCount}
                  setPageCount={(page) => setPageCount(page)}
                  loading={loading}
                  setLoading={(bool) => setLoading(bool)}
                />
              </TabPanel>
            </div>
          )}
        </TabContext>
      )}
      {/* Multi-tab Element End*/}
    </div>
  );
}
