import React, { useEffect, useState } from "react";
import { getCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";
import { IoCall } from "react-icons/io5";
import { config } from "../../config";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
  LoadingButton,
} from "@mui/lab";
import { timeAgo } from "../../utils/time";
import "./transfer-logs.scss";
import { Bars } from "react-loader-spinner";
import { FcCallTransfer } from "react-icons/fc";
import { msToDateString } from "../../utils/time";

function CallTransferLogsLite() {
  const [callTransferLogs, setCallTransferLogs] = useState([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [isLogsLoading, setIsLogsLoading] = useState(false);

  const fetchLogs = () => {
    setIsLogsLoading(true);
    getCall({
      url: config.url.MEETINGS,
      path: `/invites/${page}/6`,
      onSuccess: (response) => {
        setCallTransferLogs((prevLogs) => [
          ...prevLogs,
          ...response.data.records,
        ]);
        setIsLogsLoading(false);
        setTotalLogs(response.data.count);
        setInitialRender(false);
        setLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load call transfer logs " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setLoading(false);
        setIsLogsLoading(false);
        setInitialRender(false);
      },
    });
  };
  const meetingStatus = {
    accepted: {
      color: "success",
      icon: <FcCallTransfer className="call-trasnfer-icon" />,
    },
    pending: {
      color: "warning",
      icon: <FcCallTransfer className="call-trasnfer-icon" />,
    },
    rejected: {
      color: "error",
      icon: <FcCallTransfer className="call-trasnfer-icon" />,
    },
  };

  useEffect(() => {
    fetchLogs();
    // eslint-disable-next-line
  }, [page]);

  if (isLogsLoading && initialRender) {
    return (
      <div className="saleassist-meeting-lite-loader-wrapper">
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        Loading...
      </div>
    );
  }

  return (
    <div className="saleassist-callTransfer-lite">
      {callTransferLogs.length > 0 ? (
        <div className="logs-timeline">
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.24,
              },
              py: 0,
            }}
          >
            {callTransferLogs.map((meeting, index) => (
              <>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{
                      m: "auto 0",
                      p: "6px 10px 6px 0",
                    }}
                  ></TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineConnector sx={{ opacity: index !== 0 ? 1 : 0 }} />
                    <TimelineDot sx={{ position: "relative" }}>
                      <IoCall />
                      <div className="date-divider">
                        <span>{msToDateString(meeting.created_on)}</span>
                      </div>
                    </TimelineDot>

                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent
                    sx={{ p: "10px 0 10px 10px" }}
                  ></TimelineContent>
                </TimelineItem>
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0", p: "6px 10px 6px 0", fontSize: "12px" }}
                    align="right"
                    variant="subtitle2"
                    color="text.secondary"
                  >
                    <span className="time-info">
                      <span>{timeAgo(meeting.created_on)}</span>
                    </span>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineConnector />

                    <TimelineDot color={meetingStatus[meeting.status].color}>
                      {meetingStatus[meeting.status].icon}
                    </TimelineDot>

                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent sx={{ p: "10px 0 10px 10px" }}>
                    <div
                      className={`meeting-card ${
                        meetingStatus[meeting.status].color
                      }-color-card`}
                      style={{ overflowY: "auto" }}
                    >
                      <div className="title">{meeting.meeting_name}</div>

                      <div className="description">
                        <p>
                          <strong>Invited Agent: </strong>{" "}
                          {meeting.invited_agent}{" "}
                        </p>

                        <p>
                          <strong>Invited By: </strong>
                          {meeting.invited_by}
                        </p>

                        <p>
                          <strong>Meeting Type: </strong>
                          {meeting.meeting_type}
                        </p>

                        <p>
                          <strong>Meeting Status: </strong>
                          {meeting.status}
                        </p>
                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </>
            ))}
          </Timeline>
          {callTransferLogs.length < totalLogs ? (
            <div className="load-more-button">
              <LoadingButton
                size="small"
                loading={loading}
                loadingIndicator="Loading…"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  setPage((prevPage) => prevPage + 1);
                  setLoading(true);
                }}
              >
                <span>Load more</span>
              </LoadingButton>
            </div>
          ) : (
            <div style={{ marginBottom: "85px" }}></div>
          )}
        </div>
      ) : (
        <>
          <h5 className="text-center" style={{ fontSize: "25px" }}>
            No data available for call transfer logs
          </h5>
        </>
      )}
    </div>
  );
}

export default CallTransferLogsLite;
