import { Link, useNavigate, useLocation } from "react-router-dom";
import { IoArrowBackCircleSharp, IoNotifications } from "react-icons/io5";
import { IoMdRefreshCircle } from "react-icons/io";
import { useContext, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Typography } from "@mui/material";
import { getCall, postCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import { UserContext } from "../../context/UserContext";
import "./header.scss";

const CustomSwitch = styled("div")(({ theme, checked, isBusy }) => ({
  display: "flex",
  alignItems: "center",
  width: 66,
  height: 24,
  borderRadius: 15,
  backgroundColor: isBusy ? "#f00" : checked ? "#2ECA45" : "#ddd",
  padding: 5,
  boxSizing: "border-box",
  position: "relative",
  cursor: isBusy ? "not-allowed" : "pointer",
  "& .label": {
    fontSize: "11px",
    fontWeight: "600",
    display: "flex",
    placeContent: "flex-end",
    width: "100%",
    placeItems: "center",
    lineHeight: "normal",
    paddingRight: "2px",
    "&.active": {
      placeContent: "flex-start",
      paddingLeft: "2px",
    },
  },
  "& .circle": {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: "#fff",
    boxShadow: "0 0 5px rgba(0,0,0,0.3)",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    transition: "transform 0.3s ease",
    "&.active": {
      transform: "translateX(40px) translateY(-50%)",
    },
  },
}));

export default function Header() {
  const { User, setUser } = useContext(UserContext);
  const agentStatus = User.agentStatus;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/utilities") {
      getCall({
        url: config.url.SOURCES,
        path: "/availability/status",
        onSuccess: (response) => {
          setUser((prev) => ({ ...prev, agentStatus: response.data }));
        },
        onFailure: (error) => {},
      });
    }
    // eslint-disable-next-line
  }, []);

  function toggleAgentStatus() {
    if (agentStatus === "busy") {
      return;
    }

    postCall({
      url: config.url.SOURCES,
      path: "/availability/toggle_live",
      onSuccess: (response) => {
        setUser((prev) => ({ ...prev, agentStatus: response.data }));
      },
      onFailure: (error) => {
        bottomAlert(
          error.response ? error.response.data.detail : error.toString(),
          "warning"
        );
      },
    });
  }

  const isOnline = agentStatus === "online" || agentStatus === "busy";

  return (
    <header className="header">
      <nav className="nav">
        <IoArrowBackCircleSharp
          size={26}
          color="#959595"
          style={{ marginRight: "10px" }}
          onClick={() => {
            if (window.history.state && window.history.state.idx > 0) {
              navigate(-1);
            } else {
              navigate("/", { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
            }
          }}
        />
        <Tooltip title="Reload Page" arrow>
          <div
            style={{
              marginRight: "auto",
              cursor: "pointer",
              fontSize: 26,
              display: "flex",
            }}
            onClick={() => window.location.reload()}
          >
            <IoMdRefreshCircle color="#959595" />
          </div>
        </Tooltip>
        {location.pathname === "/utilities" && (
          <div
            className="flex items-center"
            style={{
              gap: 10,
            }}
          >
            <Link
              to="/meetings/notifications"
              style={{ fontSize: 22, display: "flex" }}
            >
              <IoNotifications color="#959595" />
            </Link>
            <Tooltip title="Set availability" arrow>
              <FormControlLabel
                sx={{ m: 0 }}
                labelPlacement="start"
                control={
                  <CustomSwitch
                    checked={isOnline}
                    onClick={toggleAgentStatus}
                    isBusy={agentStatus === "busy"}
                  >
                    <Typography
                      variant="body2"
                      className={`label ${isOnline ? "active" : ""}`}
                      color={isOnline ? "white" : "textSecondary"}
                    >
                      {agentStatus === "busy"
                        ? "In call"
                        : agentStatus === "online"
                        ? "Online"
                        : "Offline"}
                    </Typography>
                    <div className={`circle ${isOnline ? "active" : ""} `} />
                  </CustomSwitch>
                }
              />
            </Tooltip>
          </div>
        )}
      </nav>
    </header>
  );
}
