import React, { useContext } from "react";
import { ClientContext } from "../../../context/ClientContext";

function VideoBackgroundBlur() {
  const { Client } = useContext(ClientContext);

  return (
    <div className="video-background-blur-cont">
      <span
        blur-value="1"
        className={`video-background-blur-btn ${
          Client.is_visible ? "" : "active-virtual-background"
        }`}
      >
        1
      </span>
      <span blur-value="2" className="video-background-blur-btn">
        2
      </span>
      <span blur-value="3" className="video-background-blur-btn">
        3
      </span>
    </div>
  );
}

export default VideoBackgroundBlur;
