import React from "react";

function DeviceAccessError({ error_type }) {
  let default_message = error_type.split("~");
  let headerText = default_message[0] || "";
  let bodyText = default_message[1] || "";

  if (error_type === "NotAllowedError") {
    headerText = "Your camera and microphone are blocked";
    // bodyText = `Meet requires access to your camera and microphone. Click the camera blocked icon <img alt="" aria-hidden="true" style="width:1.5rem;height:1.5rem;vertical-align:middle" src="//www.gstatic.com/meet/ic_blocked_camera_7ca83311f629f64699401950ceaed61e.svg">  in your browser's address bar and then refresh.`;
    bodyText = `Meet requires access to your device's camera and microphone. </br> 
                <ul>
                  <li>
                    <span class="steps">Step 1</span>
                    <span class="item">
                      Click on Site Settings Icon 
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M20 7h-9"></path><path d="M14 17H5"></path><circle cx="17" cy="17" r="3"></circle><circle cx="7" cy="7" r="3"></circle></svg> or 
                      <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><path d="M12 16v-4"></path><path d="M12 8h.01"></path></svg>
                      at the starting of the address bar.
                    </span>
                  </li>
                  
                  <li>
                    <span class="steps">Step 2</span>
                    <span class="item"><span class="mobile-view">Click on Permissions, then </span>Allow Camera and Microphone.</span>
                  </li>

                  <li>
                    <span class="steps">Step 3</span>
                    <span class="item">Reload the Page.</span>
                  </li>
                </ul>`;
  }

  if (error_type === "NotReadableError") {
    headerText = "Something is worng with your camera or microphone";
    bodyText =
      "Meet is unable to access your camera or microphone, but you will be still able to join the call.";
  }

  return (
    <div className="sa-device-access-error-wrapper">
      <div className="sa-device-access-error-cont">
        <div className="sa-device-access-error-header">{headerText}</div>
        <div
          className="sa-device-access-error-body"
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
        <div className="sa-device-access-error-footer">
          <div className="sa-device-access-error-dismiss-btn">Dismiss</div>
        </div>
      </div>
    </div>
  );
}

export default DeviceAccessError;
