import React from "react";
import { UserProvider } from "./UserContext";
import { PermissionsProvider } from "./PermissionsContext";
import { ClientProvider } from "./ClientContext";
import { SocketProvider } from "./SocketContext";
import { FormProvider } from "./FormContext";
import { NotificationProvider } from "./NotificationContext";

function MainContext({ children }) {
  return (
    <UserProvider>
      <PermissionsProvider>
        <ClientProvider>
          <FormProvider>
            <SocketProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </SocketProvider>
          </FormProvider>
        </ClientProvider>
      </PermissionsProvider>
    </UserProvider>
  );
}

export default MainContext;
