import React, { useContext, useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { config } from "../../../../../config";
import { postCall } from "../../../../../utils/methods";
import { bottomAlert } from "../../../../../utils/toastAlerts";
import { ClientContext } from "../../../../../context/ClientContext";
import Switch from "@mui/material/Switch";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

const LeadConfig = ({refreshKey, setRefreshKey, selectedLead }) => {
  const { Client } = useContext(ClientContext);
  const [leadStatusArray, setLeadStatusArray] = useState([]);
  const [leadTypeArray, setLeadTypeArray] = useState([]);
  const [is_people_config, set_is_people_config] = useState(false);
  const [show_lead_transfer_model, set_show_lead_transfer_model] = useState(false);
  const [lead_purchased_comment, set_lead_purchased_comment] = useState("");

  const maxWidth__1200 = window.matchMedia("(max-width: 1200px)").matches;
  const maxWidth__600 = window.matchMedia("(max-width: 600px)").matches;

  useEffect(() => {
    setLeadStatusArray(Client.people_config_status);
    setLeadTypeArray(Client.people_config_type);
    set_is_people_config(Client.is_people_config);
    // eslint-disable-next-line
  }, []);

  const updateLeadStatus = (lead_status) => {
    postCall({
      url: config.url.PEOPLE,
      path: `${selectedLead.id}/status_config`,
      data: {
        lead_status_name: lead_status.name,
        lead_status_color: lead_status.color_code,
      },
      onSuccess: (response) => {
        bottomAlert("Lead status has been updated", "success");
        setRefreshKey(refreshKey+1);
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  const updateLeadType = (lead_type) => {
    postCall({
      url: config.url.PEOPLE,
      path: `${selectedLead.id}/type_config`,
      data: {
        lead_type_name: lead_type.name,
        lead_type_color: lead_type.color_code,
      },
      onSuccess: (response) => {
        bottomAlert("Lead type has been updated", "success");
        setRefreshKey(refreshKey+1);
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  const toggle_lead_transfer_model = () => {
    set_show_lead_transfer_model(!show_lead_transfer_model);
  };

  const updateLeadPurchased = (lead_purchased, purchased_comment = "") => {
    set_lead_purchased_comment("");
    postCall({
      url: config.url.PEOPLE,
      path: `${selectedLead.id}/purchase_config`,
      data: {
        is_lead_purchased: !lead_purchased,
        lead_purchased_comment: purchased_comment,
      },
      onSuccess: (response) => {
        bottomAlert("Purchase status has been updated", "success");
        setRefreshKey(refreshKey+1);
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  const LeadConversionStyling = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

  return (
    <>
      <section style={{ display: "flex", alignItems: "center" }}>
        {/* Lead status dropdown */}
        <div
          className="lead-status-type-dropdown" //styling to make it compatible for small devices in leadDetails.scss
          style={{
           marginLeft: !maxWidth__1200 ? "20px" : "10px",
            position: maxWidth__600 ? "absolute" : "static",
            left: "60px",
            top: "100px",
          }}
        >
          <FormControl sx={{ maxWidth: 120 }}>
            <InputLabel style={{ fontSize: "14px" }} id="lead-status-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              value={{
                name: selectedLead?.lead_status_name,
                color_code: selectedLead?.lead_status_color,
              }}
              onChange={(e) => updateLeadStatus(e.target.value)}
              input={<OutlinedInput label="Lead Status" />}
              renderValue={(selected) => (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={
                      !selected.color_code?.includes("#")
                        ? `bg-${selected.color_code}`
                        : ""
                    }
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: selected.color_code?.includes("#")
                        ? selected.color_code
                        : "",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "6px",
                      display: maxWidth__600 ? "none" : "block",
                    }}
                  >
                    {selected.name}
                  </div>
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    // width: 150,
                    marginLeft: "10px",
                  },
                },
              }}
              style={{
                height: maxWidth__600 ? "auto" : "38px",
              }}
            >
              {leadStatusArray?.map((curItem, index, array) => (
                <MenuItem
                  key={index}
                  value={curItem}
                  style={{ padding: "6px 12px" }}
                >
                  <span
                    className={
                      !curItem.color_code?.includes("#")
                        ? `bg-${curItem.color_code}`
                        : ""
                    }
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "4px",
                      marginLeft: "-5px",
                      marginRight: "6px",
                      backgroundColor: curItem.color_code?.includes("#")
                        ? curItem.color_code
                        : "",
                    }}
                  ></span>
                  <ListItemText primary={curItem.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* Lead Type dropdown */}
        <div
          className="lead-status-type-dropdown"
          style={{
            marginLeft: !maxWidth__1200 ? "20px" : "10px",
            position: maxWidth__600 ? "absolute" : "static",
            left: "100px",
            top: "100px",
          }}
        >
          <FormControl sx={{ maxWidth: 120 }}>
            <InputLabel style={{ fontSize: "14px" }} id="lead-type-label">
              Type
            </InputLabel>
            <Select
              labelId="lead-type-label"
              value={{
                name: selectedLead?.lead_type_name,
                color_code: selectedLead?.lead_type_color,
              }}
              onChange={(e) => updateLeadType(e.target.value)}
              input={<OutlinedInput label="Lead Type" />}
              renderValue={(selected) => (
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={
                      !selected.color_code?.includes("#")
                        ? `bg-${selected.color_code}`
                        : ""
                    }
                    style={{
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: selected.color_code?.includes("#")
                        ? selected.color_code
                        : "",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "6px",
                      display: maxWidth__600 ? "none" : "block",
                    }}
                  >
                    {selected.name}
                  </div>
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    // width: 150,
                    marginLeft: "10px",
                  },
                },
              }}
              style={{
                height: maxWidth__600 ? "auto" : "38px",
              }}
            >
              {leadTypeArray?.map((curItem, index, array) => (
                <MenuItem
                  key={index}
                  value={curItem}
                  style={{ padding: "6px 12px" }}
                >
                  <span
                    className={
                      !curItem.color_code?.includes("#")
                        ? `bg-${curItem.color_code}`
                        : ""
                    }
                    style={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "4px",
                      marginLeft: "-5px",
                      marginRight: "6px",
                      backgroundColor: curItem.color_code?.includes("#")
                        ? curItem.color_code
                        : "",
                    }}
                  ></span>
                  <ListItemText primary={curItem.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* Lead conversion toggle */}
        {is_people_config && (
          <>
            <Modal
              aria-labelledby="lead-conversion-modal-title"
              aria-describedby="lead-conversion-modal-description"
              open={show_lead_transfer_model}
              onClose={toggle_lead_transfer_model}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={show_lead_transfer_model}>
                <Box sx={LeadConversionStyling}>
                  <h6 style={{fontSize: "24px", margin: "0px"}}>
                    Congratulations on your sale conversion !!!
                  </h6>
                  <form action="" style={{marginTop: "20px"}}>
                    <textarea
                      name=""
                      id=""
                      rows="1"
                      placeholder="#order id / #sku / #comment"
                      onChange={(e) =>
                        set_lead_purchased_comment(e.target.value)
                      }
                      style={{ padding: "10px", width: "-webkit-fill-available" }}
                    ></textarea>
                  </form>
                  <div style={{marginTop: '20px', display: 'flex', justifyContent: 'end'}}>
                      <Button onClick={toggle_lead_transfer_model} className="text-danger">Cancel</Button>
                      <Button
                      clasName="text-success"
                      onClick={() => {
                      toggle_lead_transfer_model();
                      updateLeadPurchased(
                        selectedLead?.is_lead_purchased,
                        lead_purchased_comment
                      );
                      }}
                    >Save</Button>
                  </div>
                </Box>
              </Fade>
            </Modal>

            <div
              style={{
                marginLeft: !maxWidth__1200 ? "20px" : "10px",
                position: maxWidth__600 ? "absolute" : "static",
                left: "200px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  margin: "0px",
                }}
              >
                Sale Converted?
              </p>
              <Switch
                checked={selectedLead?.is_lead_purchased ? true : false}
                inputProps={{ "aria-label": "controlled" }}
                onClick={() =>
                  !selectedLead?.is_lead_purchased
                    ? toggle_lead_transfer_model()
                    : updateLeadPurchased(
                        selectedLead?.is_lead_purchased,
                        selectedLead?.lead_purchased_comment
                      )
                }
              />
            </div>
          </>
        )}
      </section>
    </>
  );
};
export default LeadConfig;
