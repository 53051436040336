export function checkAirtelFileTypeAndSize(fileType, fileSize) {
  if (
    (fileType === "image/jpeg" || fileType === "image/png") &&
    fileSize <= 5 * 1024 * 1024
  ) {
    return "IMAGE";
  } else if (
    (fileType === "video/mp4" || fileType === "video/3gpp") &&
    fileSize <= 16 * 1024 * 1024
  ) {
    return "VIDEO";
  } else if (
    (fileType === "audio/aac" ||
      fileType === "audio/mp4" ||
      fileType === "audio/mpeg" ||
      fileType === "audio/amr" ||
      fileType === "audio/ogg") &&
    fileSize <= 16 * 1024 * 1024
  ) {
    return "AUDIO";
  } else if (fileType === "application/pdf" && fileSize <= 100 * 1024 * 1024) {
    return "DOCUMENT";
  } else {
    return "UNKNOWN";
  }
}
