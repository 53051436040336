export const deviceSettings = (type) => {
  const inputDeviceSettingsEl = document.getElementById("inputDeviceSettings");

  if (type === "open") {
    const moreOptionsControlContEl = document.querySelector(
      ".saleassist-video-call-more-options-control"
    );

    inputDeviceSettingsEl.style.display = "flex";
    moreOptionsControlContEl.classList.remove("more-options-control-active");
  }
  if (type === "close") {
    inputDeviceSettingsEl.style.display = "none";
  }
};
