import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import "./emojis-board.scss";

function EmojisBoard({ addEmojiToInput }) {
  const [isEmojis, setIsEmojis] = useState(false);

  const handleClick = (decCode) => {
    const emoji = String.fromCodePoint(decCode);
    addEmojiToInput(emoji);
  };

  return (
    <>
      <div
        className={`dashboard-chat-input-emojis-wrapper ${
          isEmojis ? "opened" : "closed"
        }`}
      >
        <span onClick={() => handleClick(128150)}>&#128150;</span>
        <span onClick={() => handleClick(128077)}>&#128077;</span>
        <span onClick={() => handleClick(127881)}>&#127881;</span>
        <span onClick={() => handleClick(128079)}>&#128079;</span>
        <span onClick={() => handleClick(128514)}>&#128514;</span>
        <span onClick={() => handleClick(128558)}>&#128558;</span>
        <span onClick={() => handleClick(128549)}>&#128549;</span>
        <span onClick={() => handleClick(129300)}>&#129300;</span>
        <span onClick={() => handleClick(128078)}>&#128078;</span>
      </div>
      <Tooltip title="Emojis" arrow>
        <label htmlFor="add-emojis">
          <span className="emoji-icon" onClick={() => setIsEmojis(!isEmojis)}>
            <MdOutlineEmojiEmotions />
          </span>
        </label>
      </Tooltip>
    </>
  );
}

export default EmojisBoard;
