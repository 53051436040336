import React, { useContext } from "react";
import { TbHistoryToggle } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { FcCallTransfer } from "react-icons/fc";
import { UserContext } from "../../context/UserContext";
import {
  MdEdit,
  MdOutlinePhoneInTalk,
  MdOutlinePhoneMissed,
} from "react-icons/md";
import { BsDatabase } from "react-icons/bs";
import PendingTasksReminder from "../ToDoTasks/pendingTasksReminder";
import "./utilities.scss";

function Utilities() {
  const { User } = useContext(UserContext);
  const agentStatus = User.agentStatus;
  const navigate = useNavigate();

  return (
    <div className="saleassist-dashboard-utilities">
      <div className="profile-container">
        <div className="profile-card" onClick={() => navigate("/profile")}>
        <PendingTasksReminder />

          <div className="content-wrapper">
            <span className="content-icon">
              <IoMdPerson />
            </span>
            <div className="content-text">
              <span>{User.display_name || "No name"}</span>
              <span>
                {User.email || User.mobile_number || "No email address"}
              </span>
            </div>
            <span className={`vector-shape ${agentStatus}`}></span>
          </div>
          <span className="edit-icon">
            <MdEdit />
          </span>
        </div>
      </div>

      <div className="utilities-container">
        <NavLink to="/meetings/active-request-queue" end>
          <div className="utility-card active-requests">
            <span className="card-icon ">
              <MdOutlinePhoneInTalk />
            </span>
            <span className="card-text">Active Requests</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/call-transfer-logs" end>
          <div className="utility-card call-transfer">
            <span className="card-icon ">
              <FcCallTransfer className="call-transfer-icon" />
            </span>
            <span className="card-text">Call Transfer Logs</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/missed-call-queue" end>
          <div className="utility-card missed-calls">
            <span className="card-icon ">
              <MdOutlinePhoneMissed className="missed-call-icon" />
            </span>
            <span className="card-text">Missed Calls</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/history" end>
          <div className="utility-card history">
            <span className="card-icon">
              <TbHistoryToggle />
            </span>
            <span className="card-text">History</span>
          </div>
        </NavLink>
        <NavLink to="/to-do-tasks" end>
          <div className="utility-card to-do-tasks">
            <span className="card-icon">
              <BsDatabase />
            </span>
            <span className="card-text">To-Do Tasks</span>
          </div>
        </NavLink>
      </div>
    </div>
  );
}

export default Utilities;
