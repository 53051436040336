import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { config } from "../../config";
import { getCall, postCall } from "../../utils/methods";
import { UserContext } from "../../context/UserContext";
import { bottomAlert } from "../../utils/toastAlerts";
import VideoCallUI from "../../SA-VIdeo-Call/components/Layouts/VideoCallUI";

export default function LiveMeeting({ conference }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { User } = useContext(UserContext);
  const [meetingData, setMeetingData] = useState();
  const [widgetCreds, setWidgetCreds] = useState();
  const [viewerCount, setViewerCount] = useState(0);
  let timer = null;

  const startMeeting = () => {
    getCall({
      url: config.url.MEETINGS,
      path: id,
      onSuccess: (response) => {
        setMeetingData(response.data);

        if (response.data.meeting_type === "live_streaming") {
          timer = setInterval(() => {
            fetchStreamStats();
          }, 2000);
        }

        getCall({
          url: config.url.ACTIONS,
          path: `/get_join_url/${id}/${User?.user_name}`,
          onSuccess: (response) => {
            const room_id = response.data.match(/\/([\w-]+)\?/)[1];
            postCall({
              url: config.url.ACTIONS,
              path: `/meeting/join_channel/${room_id}/${User?.user_name}`,
              onSuccess: (response) => {
                setWidgetCreds({...response.data, room_id: room_id});
              },
            });
          },
          onFailure: (error) => {
            bottomAlert("Unable to fetch join url.", "danger");
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to get meeting details. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        // navigate("/meetings/lite");
      },
    });
  };

  const fetchStreamStats = () => {
    getCall({
      url: config.url.MEETINGS,
      path: `/streaming/stats/${id}`,
      onSuccess: (response) => {
        setViewerCount(response.data.viewers);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch stream stats. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  useEffect(() => {
    startMeeting();

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className="saleassist-meeting-lite">
      {widgetCreds ? (
        <VideoCallUI
          widget_creds={widgetCreds}
          meeting_type="video_call"
          meetingData={meetingData}
          endCallBack={() => navigate("/meetings/lite")}
          conference={conference}
          liveStream={meetingData.meeting_type === "live_streaming"}
          viewerCount={viewerCount}
        />
      ) : null}
    </div>
  );
}
