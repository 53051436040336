import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Input,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from "@mui/material";
import { Bars } from "react-loader-spinner";

import { UserContext } from "../../context/UserContext";
import { deleteCall, getCall, postCall, putCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import "./profile.scss";
import ConfirmationModal from "../Modal/ConfirmationModal";

export default function Profile() {
  const { User, setUser } = useContext(UserContext);

  const [displayName, setDisplayName] = useState(User.display_name);
  const [mobileNumber, setMobileNumber] = useState(User.mobile_number);
  const [email, setEmail] = useState(User.email);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);
    getCall({
      url: config.url.USERS,
      path: "/rooms/check_room_name_availability",
      params: { room_name: User.room_name },
      onSuccess: (response) => {
        if (response.data === true) {
          putCall({
            url: config.url.USERS,
            path: User.id,
            data: {
              ...User,
              display_name: displayName,
              mobile_number: mobileNumber,
              email,
            },
            onSuccess: (response) => {
              bottomAlert("Changes saved.", "success");
              setUser((prev) => ({
                ...prev,
                display_name: displayName,
                mobile_number: mobileNumber,
                email,
              }));
            },
            onFailure: (error) => {
              bottomAlert(
                "Your request could not be processed. " +
                  ((error.response && error.response.data.detail) ||
                    error.toString()),
                "warning"
              );
            },
          });
        } else {
          bottomAlert("Please try entering an available room name.", "warning");
        }
        setIsLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Room name availability could not be checked. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
  };

  const deleteProfile = () => {
    setIsLoading(true);
    deleteCall({
      url: config.url.USERS,
      path: User.id,
      onSuccess: (response) => {
        setIsLoading(false);
        bottomAlert("Profile deleted.", "success");
        // logout();
      },
      onFailure: (error) => {
        setIsLoading(false);
        bottomAlert(
          "Unable to complete your request. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "error"
        );
      },
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getCall({
      url: config.url.CLIENTS,
      path: `/allowed_clients`,
      onSuccess: (response) => {
        setUser((prev) => ({ ...prev, organizations: response.data }));
        setIsLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "We are unable to fetch your organizations. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
      },
    });
    // eslint-disable-next-line
  }, []);

  const handleSwitchOrganization = (organizationId) => {
    setIsLoading(true);
    postCall({
      url: config.url.USERS,
      path: `/switch_client/${organizationId}`,
      onSuccess: (response) => {
        bottomAlert("Let's get you to the selected client.", "success");
        window.location.reload();
      },
      onFailure: (error) => {
        bottomAlert(
          "We are Unable to switch the client. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(true);
      },
    });
  };

  if (isLoading) {
    return (
      <div
        className="profile"
        style={{
          display: "grid",
          placeContent: "center",
          height: "270px",
        }}
      >
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="profile">
        <div>
          <div style={{ marginBottom: "20px" }}>
            <InputLabel htmlFor="display_name">Display Name</InputLabel>
            <Input
              type="text"
              id="display_name"
              placeholder="Your name in meetings"
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
            />
          </div>

          <div style={{ marginBottom: "20px" }}>
            <InputLabel htmlFor="mobile_number">Mobile Number</InputLabel>
            <Input
              type="text"
              id="mobile_number"
              placeholder="Your mobile number for SMS notifications and calls."
              onChange={(e) => setMobileNumber(e.target.value)}
              value={mobileNumber}
              disabled={User.user_name === User.mobile_number}
            />
          </div>

          <div>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input
              type="text"
              id="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled={User.user_name === User.email}
            />
          </div>

          <FormControl
            sx={{ m: 1, minWidth: 120, marginTop: "24px", marginLeft: 0 }}
          >
            <InputLabel id="active-organization-select-label">
              Active organization
            </InputLabel>
            <Select
              labelId="active-organization-select-label"
              value={User.active_client_id}
              label="Active organization"
              onChange={(e) => handleSwitchOrganization(e.target.value)}
            >
              {User.organizations?.length &&
                User.organizations.map((organization) => (
                  <MenuItem value={organization.id}>
                    {organization.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>Switch organization</FormHelperText>
          </FormControl>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "28px",
            }}
          >
            <Button
              variant="contained"
              color="success"
              className="mt-2 mr-2"
              onClick={(e) => {
                if (!(!e.detail || e.detail === 1)) {
                  return;
                }
                handleSubmit();
              }}
            >
              Save changes
            </Button>

            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Delete profile
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationModal
        title="Warning! Are you sure you want to delete this profile?"
        open={isDeleting}
        setOpen={setIsDeleting}
        onAccept={deleteProfile}
      />
    </>
  );
}
