import React, { useState, useEffect, Fragment } from "react";
import dayjs from 'dayjs';
import moment from "moment";
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { IoCall, IoCheckmarkCircle, IoFilter, IoMic, IoVideocam } from "react-icons/io5";
import { config } from "../../config";
import { getCall, patchCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";
import { msToDateString, msToTimeString, timeAgo } from "../../utils/time";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GiCoffeeCup } from "react-icons/gi";
import { Avatar, Button, Chip, CircularProgress, Divider, IconButton, Menu, MenuItem, MenuList, Paper, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { underscoreToSpacedCamel } from "../../utils/text";
import "./calls-queue.scss";

const formatWithMicroseconds = (date) => {
  const formattedDate = moment(new Date(date)).format("YYYY-MM-DD");
  return `${formattedDate} 00:00:00.000000`;   // adding extra zeros for microseconds to convert it into this format "2023-12-01 00:00:00.000000" according to api requirement.
};

function MissedCallsQueue() { 
  const today = dayjs();
  const yesterday = dayjs().subtract(1, 'day');
  const [missedCallsData, setMissedCallsData] = useState([]);
  const [missedCallsCount, setMissedCallsCount] = useState(0);
  const [isModerator, setIsModerator] = useState(true);
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const meetingTypeEl = {
    video_call: {
      color: "warning",
      icon: <IoVideocam />,
    },
    audio_call: {
      color: "warning",
      icon: <IoMic />,
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const markAsFollowed = (conversation_id, unique_field_value) => {
    patchCall({
      url: config.url.CONVERSATIONS,
      path: `/missed-call/follow-up/${conversation_id}`,
      data: {},
      onSuccess: (response) => {
        bottomAlert("Missed call is marked as followed", "success");
        navigate(`/meetings/leads/${conversation_id}?q=${encodeURIComponent(unique_field_value)}`);
      },
      onFailure: (error) => {
        bottomAlert("Unable to follow the missed call. " + (error.response ? error.response.data.detail : error.toString()));
      }
    });
  }

  const fetchMissedCalls = () => {
    getCall({
      url: config.url.MEETINGS,
      path: `/missed?missed_by=${isModerator?"moderators":"participants"}&followed_up=false&source_name=&start_date=${formatWithMicroseconds(startDate)}&end_date=${formatWithMicroseconds(endDate)}&page_number=${page}&items_per_page=10`,
      onSuccess: (response) => {
        setMissedCallsData((prev) => [...prev, ...response.data.records]);
        setMissedCallsCount(response.data.count);
        setIsLoading(false);
        setLoading(false);
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to load missed call. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchMissedCalls();

    // eslint-disable-next-line
  }, [page, isModerator, startDate, endDate]);

  return (
    <div className="saleassist-missedCall-lite">
      <Paper elevation={3} className="missedCall-header">
        <div className="title">
          {isLoading ? (
            <CircularProgress 
              style={{width: "22px", height: "22px"}}
              color="warning" 
            />) : (
            <Avatar 
              sx={{
                bgcolor: "#ed6c02", 
                width: 22, 
                height: 22, 
                fontSize: "14px"
              }}
            >
              {missedCallsCount}
            </Avatar>
          )}
          <p>Missed Calls</p>
        </div>
        <div className="filter">
          <IconButton
            id="filter-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <IoFilter/>
          </IconButton>
          <Menu
            id="basic-menu"
            className="saleassist-missedCall-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuList dense>
              <MenuItem>
                <Chip 
                  color={isModerator ? "info" : "default"} 
                  icon={isModerator ? <IoCheckmarkCircle/> : <></>} 
                  label="Agent" 
                  size="small" 
                  onClick={() => {
                    if(!isModerator) {
                      setIsLoading(true);
                      setIsModerator(true);
                      setMissedCallsData([]);
                      setPage(1);
                    }
                  }} 
                />
                <Chip 
                  color={isModerator ? "default" : "info"} 
                  icon={isModerator ? <></> : <IoCheckmarkCircle/>} 
                  label="Participant" 
                  size="small"
                  onClick={() => {
                    if(isModerator) {
                      setIsLoading(true);
                      setIsModerator(false);
                      setMissedCallsData([]);
                      setPage(1);
                    }
                  }}
                />
              </MenuItem>
              <Divider />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MenuItem className="date-range-item">
                  Select Date Range
                  <div className="date-picker-wrapper">
                    <DatePicker
                      className="date-picker"
                      views={['year', 'month', 'day']}
                      value={startDate}
                      onChange={(newValue) => {
                        setIsLoading(true);
                        setStartDate(newValue);
                        setMissedCallsData([]);
                        setPage(1);
                      }}
                    />
                    {"-"}
                    <DatePicker
                      className="date-picker"
                      views={['year', 'month', 'day']}
                      value={endDate}
                      onChange={(newValue) => {
                        setIsLoading(true);
                        setEndDate(newValue);
                        setMissedCallsData([]);
                        setPage(1);
                      }}
                    />
                  </div>
                </MenuItem>
              </LocalizationProvider>
            </MenuList>
          </Menu>
        </div>
      </Paper>

      {isLoading && (
        <div style={{width: "calc(100% - 48px)"}}>
          <Skeleton variant="rounded" sx={{width: "100%", height: "100px", mt: 2}} />
          <Skeleton variant="rounded" sx={{width: "100%", height: "20px", mt: 2}} />
          <Skeleton variant="rounded" sx={{width: "60%", height: "20px", mt: 2}} />
        </div>
      )}

      {!isLoading && missedCallsData.length === 0 && (
        <div className="saleassist-upcoming-meeting-lite">
          <div className="no-upcoming-meeting-placeholder">
            <GiCoffeeCup />
            <p>
              No missed call found,<span>Grab a coffee!</span>
            </p>
          </div>
        </div>
      )}
      
      {!isLoading && missedCallsData.length > 0 && (
        <div className="missedCall-timeline">
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.24,
              },
              py: 0,
            }}
          >
            {missedCallsData.map((meeting, index) => (
              <Fragment key={`missed-call-${index}`}>
                {msToDateString(missedCallsData[index - 1]?.created_on) !== msToDateString(meeting.created_on) && (
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{
                        m: "auto 0",
                        p: "6px 10px 6px 0",
                      }}
                    ></TimelineOppositeContent>

                    <TimelineSeparator>
                      <TimelineConnector
                        sx={{ opacity: index !== 0 ? 1 : 0 }}
                      />
                      <TimelineDot sx={{ position: "relative" }}>
                        <IoCall />
                        <div className="date-divider">
                          <span>{msToDateString(meeting.created_on)}</span>
                        </div>
                      </TimelineDot>

                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent
                      sx={{ p: "10px 0 10px 10px" }}
                    ></TimelineContent>
                  </TimelineItem>
                )}

                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0", p: "6px 10px 6px 0", fontSize: "12px" }}
                    align="right"
                    variant="subtitle2"
                    color="text.secondary"
                  >
                    <span className="time-info">
                      {msToTimeString(meeting.created_on)}
                      <span>{timeAgo(meeting.created_on)}</span>
                    </span>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineConnector />

                    <TimelineDot
                      color={meetingTypeEl[meeting.message_type].color}
                    >
                      {meetingTypeEl[meeting.message_type].icon}
                    </TimelineDot>

                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent sx={{ p: "10px 0 10px 10px" }}>
                    <div
                      className={`meeting-card ${
                        meetingTypeEl[meeting.message_type].color
                      }-color-card`}
                    >
                      <div className="title">{meeting.unique_field_value}</div>

                      <div className="description">
                        <p>
                          <strong>Type: </strong> {underscoreToSpacedCamel(meeting.message_type)}{" "}
                        </p>

                        {meeting.source_name && (
                          <p>
                            <strong>Widget: </strong> {meeting.source_name}{" "}
                          </p>
                        )}

                        {meeting.conversation_id && (
                          <p>
                            <strong>Conversation Id: </strong> {meeting.conversation_id}{" "}
                          </p>
                        )}

                        <Button
                          color="info"
                          size="small"
                          variant="contained"
                          className="follow-up-btn"
                          onClick={() => markAsFollowed(meeting.conversation_id, meeting.unique_field_value)}
                        >
                          Follow Up
                        </Button>

                      </div>
                    </div>
                  </TimelineContent>
                </TimelineItem>
              </Fragment>
            ))}
          </Timeline>

          <div className="load-more-button">
            {missedCallsData.length < missedCallsCount && (
              <LoadingButton
                size="small"
                loading={loading}
                loadingIndicator="Loading…"
                variant="outlined"
                onClick={() => {
                  setPage((prevPage) => prevPage + 1);
                  setLoading(true);
                }}
              >
                <span>Load more</span>
              </LoadingButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MissedCallsQueue;
