import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Fab } from "@mui/material";
import { Tooltip } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { FaFile, FaNoteSticky, FaPaperclip } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { postCall, uploadFiles } from "../../../utils/methods";
import { config } from "../../../config";
import { UserContext } from "../../../context/UserContext";
import { bottomAlert } from "../../../utils/toastAlerts";
import EmojisBoard from "../EmojisBoard/EmojisBoard";
import LoadingButton from "@mui/lab/LoadingButton";
import { Popover, Typography } from "@mui/material";

import "./send-message.scss";

export default function SendMessage({
  id,
  leadData,
  setLeadData,
  selectedLead,
  handleScrollStable,
}) {
  const [userResponse, setUserResponse] = useState("");
  const [userResponseType, setUserResponseType] = useState("chat_response");
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { User } = useContext(UserContext);
  const [anchor_menu, set_anchor_menu] = useState(null);
  const navigate = useNavigate();

  const sendMessage = (responseType) => {
    if (files.length > 0) {
      setLoading(true);

      uploadFiles({
        url: config.url.MEDIA,
        path: "/people/" + id,
        files: files,
        onSuccess: (response) => {
          let attachments = [];
          files.forEach((file, index) => {
            let attachment = {
              name: file.name,
              attachment_url: response.data[index].url,
            };
            attachments.push(attachment);
          });
          const resType =
            attachments.length > 0 ? "document" : userResponseType;
          addConversation(resType, attachments);
        },
        onFailure: (error) => {
          setLoading(false);
          bottomAlert(
            "Unable to Send the document. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
        },
      });
    } else {
      if (userResponse.trim()) {
        addConversation(responseType);
      }
    }
  };

  const addConversation = (responseType, attachments) => {
    setLoading(true);

    postCall({
      url: config.url.CONVERSATIONS,
      data: {
        message_content: userResponse,
        message_type: responseType || userResponseType,
        message_from: User.id,
        acknowledged: true,
        meta: attachments || [],
        message_to: id,
        meeting_id: "",
        people_id: id,
        unique_field_value: User.user_name,
        session_id: "",
        chat_group_id: "",
      },
      onSuccess: (response) => {
        let conversation_exists = false;
        const updatedConversations = [...leadData];

        leadData.forEach((conversation, index) => {
          if (conversation.id === response.data.id) {
            conversation_exists = true;
            updatedConversations[index] = response.data;
          }
        });

        if (!conversation_exists) {
          updatedConversations.push(response.data);
        }
        setFiles([]);
        setUserResponse("");
        setLeadData(updatedConversations);
        setUserResponseType("chat_response");
        setLoading(false);
      },
      onFailure: (error) => {
        setLoading(false);
        bottomAlert(
          "Unable to post the message. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  };

  const onFileUpload = (event, type) => {
    if (event.target.files && event.target.files.length > 0) {
      setFiles((files) => [...files, ...Array.from(event.target.files)]);
      setUserResponseType(type);
    }
  };

  const removeFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    handleScrollStable("bottom");
  }, [leadData, handleScrollStable]);

  return (
    <div className="send-message-container">
      {userResponseType === "document" &&
        files.map((file, index) => {
          return (
            <div
              className="uploaded-doc-preview"
              key={"agent_document_preview_" + index}
            >
              <FaFile fontSize={18} />
              {file.name}
              <p className="remove-uploaded-doc-btn" onClick={removeFile}>
                <FaTimes />
              </p>
            </div>
          );
        })}
      <div className="send-message-wrapper">
        <div className="action-button">
          <Fab size="small"
            style={{
              boxShadow: "none",
              background: "#02307D",
            }}
            onClick={ (event) => set_anchor_menu(event.currentTarget) }
            >
            <HiPlusSm
              style={{
                color: "#fff",
                transform: anchor_menu && "rotate(135deg)",
                transition: "transform 0.5s",
              }}
            />
          </Fab>

          {/* popover modal */}
          <Popover
                id={
                  Boolean(anchor_menu) ? "simple-popover" : undefined
                }
                open={Boolean(anchor_menu)}
                anchorEl={anchor_menu}
                onClose={() => set_anchor_menu(null)}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Typography
                  style={{
                    margin: "10px 10px 5px 10px",
                    cursor: "pointer",
                    background: "#eee",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  onClick={() => {
                    set_anchor_menu(null);
                   navigate(
                  `/meetings/new/conference?participant=${selectedLead.unique_field_value}`
                  )
                }}
                >
                  New Meeting
                </Typography>
                <Typography
                  style={{
                    margin: "10px 10px 5px 10px",
                    cursor: "pointer",
                    background: "#eee",
                    padding: "5px 10px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                  onClick={() => {set_anchor_menu(null);
                   navigate(
                  `/meetings/new/schedule?participant=${selectedLead.unique_field_value}`
                  )
                }}
                >
                  Schedule Meeting
                </Typography>
              </Popover>
        </div>

        <input
          type="text"
          placeholder="Enter message"
          multiline="false"
          className="send-message-input"
          value={userResponse}
          onChange={(e) => setUserResponse(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
              e.preventDefault();
              sendMessage("chat_response");
            }
          }}
        />

        <div className="send-message-buttons-wrapper">
          <EmojisBoard
            addEmojiToInput={(emoji) => {
              setUserResponse((prevUserResponse) => prevUserResponse + emoji);
            }}
          />

          <Tooltip title="Upload files" arrow>
            <label htmlFor="upload-file">
              <div
                style={{
                  display: "flex",
                  opacity: loading ? "0.4" : "1",
                }}
              >
                <FaPaperclip />
                <input
                  multiple
                  type="file"
                  id="upload-file"
                  style={{ display: "none" }}
                  accept="*"
                  onChange={(e) => onFileUpload(e, "document")}
                  disabled={loading}
                />
              </div>
            </label>
          </Tooltip>

          <Tooltip title="Add notes" placement="top" arrow>
            <label htmlFor="send-button">
              <span
                style={{
                  display: "flex",
                  opacity:
                    userResponse.trim().length === 0 && files.length === 0
                      ? "0.4"
                      : "1",
                }}
              >
                <FaNoteSticky />
                <input
                  id="send-button"
                  style={{ display: "none" }}
                  type="submit"
                  onClick={() => {
                    sendMessage("note");
                  }}
                  disabled={
                    loading || !(userResponse.trim() || files.length > 0)
                  }
                />
              </span>
            </label>
          </Tooltip>

          <LoadingButton
            variant="contained"
            onClick={() => sendMessage("chat_response")}
            size="small"
            loading={loading}
            disabled={!(userResponse.trim() || files.length > 0)}
          >
            <span>Send</span>
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}
