import React from "react";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { putCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";

const MarkAsDoneConfirmationMenu = ({ markedItem, closeMarkAsDoneConfirmationMenu, refreshTaskList, setRefreshTaskList }) => {
  const open = Boolean(markedItem);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const markAsDone = () => {
    putCall({
      url: config.url.TO_DO,
      path: `${markedItem.id}`,
      data: {
        name: markedItem.name,
        notes: markedItem.notes,
        lead: markedItem.lead,
        reminder: markedItem.reminder,
        reminder_time: markedItem.reminder_time,
        status: "COMPLETED",
        priority_level: markedItem.priority_level,
        "timezone": localTimeZone,
      },
      onSuccess: (response) => {
        setRefreshTaskList(!refreshTaskList);
        bottomAlert("Task is marked as done", "success");
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
    closeMarkAsDoneConfirmationMenu();
  };

  return (
    <>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={markedItem}
        open={open}
        onClose={closeMarkAsDoneConfirmationMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{padding: '8px'}}
        className="donPopup"
      >
        <p style={{fontWeight: '500', paddingLeft: '15px', paddingRight: '15px'}}>Do you want to Mark as done this task?</p>
        <div style={{paddingRight: '15px', marginTop: '20px', display: 'flex', justifyContent: 'end'}}>
            <Button onClick={closeMarkAsDoneConfirmationMenu} className="text-danger">Cancel</Button>
            <Button onClick={() => markAsDone()} clasName="text-success">Done</Button>
        </div>
      </Menu>
    </>
  );
};
export default MarkAsDoneConfirmationMenu;
