import React from 'react'

function RecordIcon() {
    return (
        <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 12a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1A5 5 0 1 0 8 3a5 5 0 0 0 0 10z"
            >
            </path>
            <path d="M10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"></path>
        </svg>
    )
}

export default RecordIcon
