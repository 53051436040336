import { config } from "../config";
import axios from "axios";
import { bottomAlert } from "./toastAlerts";
import { getCookie, setCookie } from "./cookie";

export function authorizationHeader() {
  const tokenCookie = getCookie("saleassist_auth_token");
  let token = "";

  if (tokenCookie) {
    let parsedTokenCookie = JSON.parse(tokenCookie);
    token = parsedTokenCookie.token;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    token = urlParams.get('token');

    if(token) {
      setCookie("saleassist_auth_token", JSON.stringify({ token }));
    } else {
      console.error("There is no token value!");
    }
  }

  return {
    Authorization: "Bearer " + token,
    referrer: window.location.href,
  };
}

export function getCall({
  url = config.url.BASE,
  path = "",
  params = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.params = params;
  reqConfig.headers = authorizationHeader();
  return axios
    .get(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function getCallWithParams({
  url = config.url.BASE,
  path = "",
  params = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.params = params;
  reqConfig.headers = authorizationHeader();
  return axios
    .get(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function postCall({
  url = config.url.BASE,
  path = "",
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .post(sanitizeUrl(url + "/" + path), data, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function deleteCall({
  url = config.url.BASE,
  path = "",
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .delete(sanitizeUrl(url + "/" + path), reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function putCall({
  url = config.url.BASE,
  path = "",
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .put(sanitizeUrl(url + "/" + path), data, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function patchCall({
  url = config.url.BASE,
  path = "",
  data = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  return axios
    .patch(sanitizeUrl(url + "/" + path), data, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function uploadFiles({
  url = config.url.BASE,
  path = "",
  files = [],
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  var formData = new FormData();
  files.map((file) => formData.append("files", file));
  return axios
    .post(sanitizeUrl(url + "/" + path), formData, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function uploadWhatsAppFiles({
  url = config.url.BASE,
  path = "",
  files = [],
  form = {},
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  var formData = new FormData();
  files.map((file) => formData.append("files", file));
  for (const key in form) {
    if (form.hasOwnProperty(key)) {
      formData.append(key, form[key]);
    }
  }
  return axios
    .post(sanitizeUrl(url + "/" + path), formData, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function uploadBlob({
  url = config.url.BASE,
  path = "",
  blob = {},
  fileName = "",
  onSuccess = () => {},
  onFailure = () => {},
}) {
  var reqConfig = {};
  reqConfig.headers = authorizationHeader();
  var formData = new FormData();
  formData.append("files", blob, fileName);
  // formData.append('data', blob);
  return axios
    .post(sanitizeUrl(url + "/" + path), formData, reqConfig)
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      handleError(onFailure, error);
    });
}

export function fetchBlob(uri) {
  fetch(uri, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
  })
    .then((r) => {
      r.blob().then(function (myBlob) {
        downloadFile(myBlob, "recording.mp4", "application/zip");
      });
    })
    .catch((r) => {
      console.log("File not available.", r);
    });
}

export function downloadFile(data, fileName, type = "text/plain") {
  // Create an invisible A element
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute("download", fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export function downloadText(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function sanitizeUrl(url) {
  var sanitized_url = url.replace(/([^:]\/)\/+/g, "$1").replace(/\/+$/, "");
  if (sanitized_url.split("/").length <= 4) {
    // fall back for "/" paths on FastAPI backend
    return sanitized_url + "/";
  } else {
    return sanitized_url;
  }
}

export function loadJavascript({ script_url, id, onLoad }) {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = script_url;
    script.id = id;
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      return onLoad && onLoad();
    };
  } else {
    return onLoad && onLoad();
  }
}

function isConnected() {
  return window.navigator.onLine;
}

function handleError(onFailure, error) {
  if (!isConnected()) {
    alert(
      "It seems that you are offline. Kindly refresh this page after a successful network connection.",
      "No Internet!"
    );
  }
  // else if (isSessionExpired()) {
  //   alert(
  //     "It seems that your session expired. Please reload the page.",
  //     "Session Expired",
  //     () => {
  //       showing_popup = false;
  //     }
  //   );
  // }
  else {
    onFailure(error);
  }
}

export function endMeeting({
  meeting = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  if (!meeting) {
    return onSuccess();
  }

  getCall({
    url: config.url.MEETINGS,
    path: "end/" + meeting.id,
    onSuccess: (response) => {
      bottomAlert("Your meeting has been closed.", "success");
      onSuccess(response);
    },
    onFailure: (error) => {
      bottomAlert(
        "Unable to close your meeting. " +
          ((error.response && error.response.data.detail) || error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}

export function startMeeting({
  meeting = null,
  onSuccess = () => {},
  onFailure = () => {},
}) {
  getCall({
    url: config.url.MEETINGS,
    path: "start/" + meeting.id,
    onSuccess: (response) => onSuccess(response),
    onFailure: (error) => {
      bottomAlert(
        "Your meeting could not be started. " +
          (error.response ? error.response.data.detail : error.toString()),
        "warning"
      );
      onFailure(error);
    },
  });
}
