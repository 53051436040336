import { TextField } from "@mui/material";

export default function MeetingNameAndDescription({
  meetingType,
  meetingName,
  setMeetingName,
  meetingDescription,
  setMeetingDescription,
}) {
  return (
    <div className="meeting-name-description-container">
      <div className="meeting-name-wrapper">
        <label
          htmlFor="meeting-name"
          className="label-text"
          style={{ marginBottom: 0 }}
        >
          {meetingType} name <span style={{ color: "#f00" }}>*</span>
        </label>
        <TextField
          variant="outlined"
          id="meeting-name"
          type="text"
          placeholder={meetingType + " name"}
          defaultValue={meetingName}
          onBlur={(e) => setMeetingName(e.target.value)}
        />
      </div>
      <div className="meeting-description-wrapper">
        <label
          htmlFor="meeting-description"
          className="label-text"
          style={{ marginBottom: 0 }}
        >
          Description <span style={{ color: "#f00" }}>*</span>
        </label>
        <TextField
          variant="outlined"
          id="meeting-description"
          type="text"
          placeholder="A short description about your meeting"
          defaultValue={meetingDescription}
          onBlur={(e) => setMeetingDescription(e.target.value)}
        />
      </div>
    </div>
  );
}
