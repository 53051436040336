import React from 'react'

function CloseIconButton() {
    return (
        <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="16"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path
                d="M400 145.49L366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"
            ></path>
        </svg>
    )
}

export default CloseIconButton
