import React, { useState, useEffect, useRef, Fragment } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Divider } from "@mui/material";

import {
  currentTimeInMilliSecs,
  msToDateString,
  msToShortDateString,
  msToTimeString,
} from "../../../../utils/time";
import { getCall, postCall, putCall } from "../../../../utils/methods";
import { config } from "../../../../config";
import MessageBoxWrapper from "../../MessageBox/MessageBoxWrapper";
import SendMessage from "../../SendMessageBox/SendMessage";
import { underscoreToSpacedCamel } from "../../../../utils/text";
import LeadConfig from "./LeadConfig/LeadConfig";

import "./lead-details.scss";

export default function LeadDetails() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [leads, setLeads] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [conversationType] = useState("all");
  const [pageCount, setPageCount] = useState(1);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [clickData, setClickData] = useState();
  const [refreshKey, setRefreshKey] = useState(1);

  const messagesTopRef = useRef();
  const messagesStartRef = useRef();
  const messagesEndRef = useRef();

  const pageItemsCount = 10;

  const handleScrollStable = (direction = "bottom") => {
    const options = {
      behavior: "smooth", // Smooth scrolling
      block: "end", // Scroll to the end of the element
    };

    if (direction === "up") {
      messagesStartRef.current &&
        messagesStartRef.current.scrollIntoView(options);
    } else if (direction === "top") {
      messagesTopRef.current && messagesTopRef.current.scrollIntoView(options);
    } else {
      messagesEndRef.current && messagesEndRef.current.scrollIntoView(options);
    }
  };

  const fetchLeads = () => {
    getCall({
      url: config.url.PEOPLE,
      path: `/filter/${
        searchParams.get("q") ? searchParams.get("q") + "/" : ""
      }1/10`,
      onSuccess: (response) => {
        setLeads(response.data.records);
        getCall({
          url: config.url.TRACKER,
          path: "/cta_by_people_id/" + id,
          onSuccess: (response) => {
            setClickData(response.data);
          },
          onFailure: (error) => {
            console.log(error);
          },
        });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  const fetchLeadData = (count) => {
    postCall({
      url: config.url.CONVERSATIONS,
      path: `/people/${id}/${count || pageCount}/${pageItemsCount}`,
      data: {},
      onSuccess: (response) => {
        setLeadData((leadData) => {
          const conversations = leadData;
          response.data.records.forEach((conversation) => {
            conversations.unshift(conversation);
          });
          return conversations;
        });
        setConversationsCount(response.data.count);
        setPageCount(count || 1);

        putCall({
          url: config.url.PEOPLE,
          path: `set_conversations_read/${id}`,
          onSuccess: (response) => {},
          onFailure: (error) => {
            console.log(error);
          },
        });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    setLeadData([]);    //while rerendering the component then duplicate entries were creaed cuz the previous lead data was not ersaed so I set empty array to avoid duplicate entries.
    fetchLeads();
    fetchLeadData();

    const interval = setInterval(() => {
      if (messagesEndRef) {
        handleScrollStable();
        clearInterval(interval);
      }
    }, 500);

    // eslint-disable-next-line
  }, [refreshKey]);

  const selectedLead = leads?.find((lead) => lead.id === id) || {};


  let lastDate = "";
  let lastTime = "";
  let lastUser = "";
  let lastForm = "";

  return (
    <div className="lead-conversations-container">
      <section className="lead-conversations-container-header">
        <div className="lead-avatar-wrapper">
          <p className="lead-avatar-text">
            {selectedLead?.unique_field_value?.[0]?.toUpperCase()}
          </p>
          <span
            className="lead-avatar-status"
            style={{
              border: `2px solid ${
                selectedLead.is_connected &&
                currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                  ? "#3ac47d"
                  : "#fff"
              }`,
              background:
                selectedLead.is_connected &&
                currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                  ? "#fff"
                  : "#ccc",
            }}
          ></span>
        </div>
        <div>
          <p className="lead-unique-field">{
            selectedLead.unique_field_value?.length > 15 ? selectedLead.unique_field_value?.slice(0, 14) + "..." : selectedLead.unique_field_value
            }</p>
        </div>
        <LeadConfig
        refreshKey={refreshKey}
        setRefreshKey={setRefreshKey}
        selectedLead={selectedLead}
         />
      </section>
      <div className="lead-conversations-container-body">
        <div className="conversations-wrapper">
          <div ref={messagesTopRef} />
          <div ref={messagesStartRef} />
          {leadData.length > 0 && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {pageCount * pageItemsCount < conversationsCount ? (
                <Button
                  color="inherit"
                  variant="outlined"
                  style={{ margin: "8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    fetchLeadData(pageCount + 1);
                    handleScrollStable("top");
                  }}
                >
                  Load more conversations
                </Button>
              ) : (
                <Button variant="contained" disabled style={{ margin: "8px" }}>
                  That's the last one we got.
                </Button>
              )}
            </div>
          )}
          {leadData.length > 0 &&
            leadData
              .filter((conversation) =>
                conversationType === "all"
                  ? true
                  : conversation.message_type === conversationType
              )
              ?.map((item, index) => {
                let isReceiver =  (item.message_type === "lead_purchase_status_change" ||
                  item.message_type === "lead_config_status_change" ||
                  item.message_type === "lead_config_type_change")
                    ? false
                    : item.message_from === selectedLead?.id;
                return (
                  <Fragment key={`item-${index}-${item.created_on}`}>
                    {msToDateString(item.created_on) !== lastDate && (
                      <div className="conversation-created-on">
                        <span className="conversation-created-on-text">
                          {msToDateString(item.created_on)}
                        </span>
                        <Divider className="conversation-created-on-divider" />
                      </div>
                    )}
                    <MessageBoxWrapper
                      item={item}
                      isReceiver={isReceiver}
                      hideUser={
                        item.unique_field_value === lastUser &&
                        msToDateString(item.created_on) === lastDate &&
                        item.form_id === lastForm
                      }
                      hideTime={msToTimeString(item.created_on) === lastTime}
                      isModeratedChat={selectedLead?.is_moderated_chat}
                    />
                    {/* eslint-disable-next-line */}
                    {(lastUser = item.unique_field_value) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastForm = item.form_id) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastDate = msToDateString(item.created_on)) ? "" : ""}
                    {/* eslint-disable-next-line */}
                    {(lastTime = msToTimeString(item.created_on)) ? "" : ""}
                  </Fragment>
                );
              })}
          <div ref={messagesEndRef} className="lead-last-cta-click">
            {clickData &&
              "Last CTA: " +
                underscoreToSpacedCamel(clickData.clicked_on) +
                " on " +
                underscoreToSpacedCamel(clickData.app_panel) +
                " at " +
                msToShortDateString(clickData.created_on)}
          </div>
        </div>
        <SendMessage
          id={id}
          leadData={leadData}
          setLeadData={setLeadData}
          selectedLead={selectedLead}
          handleScrollStable={(dir) => {
            handleScrollStable(dir);
          }}
        />
      </div>
    </div>
  );
}
