import React, { useState, useRef } from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import "./swipe-to-unhold.scss";

const SwipeToUnhold = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [currentX, setCurrentX] = useState(4);
  const startX = useRef(0);
  const maxLeft = 254; // Maximum distance to swipe (container width - handle width)
  const handleRef = useRef(null);

  // Start dragging (for both touch and mouse)
  const startDrag = (e) => {
    setIsDragging(true);
    startX.current = e.type.includes("touch")
      ? e.touches[0].clientX
      : e.clientX;
  };

  // Handle dragging (for both touch and mouse)
  const onDrag = (e) => {
    if (!isDragging) return;
    const clientX = e.type.includes("touch")
      ? e.touches[0].clientX
      : e.clientX;
    const newX = Math.max(0, Math.min(maxLeft, clientX - startX.current));
    setCurrentX(newX);
  };

  // End dragging (for both touch and mouse)
  const endDrag = () => {
    if (!isDragging) return;
    setIsDragging(false);

    if (currentX >= maxLeft * 0.9) {
      // If swiped more than 90% to the right, consider it "unheld"
      const holdCallButtonEl = document.querySelector("#sa-hold-call");
      setCurrentX(maxLeft);
      setTimeout(() => {
        holdCallButtonEl?.click();
        resetHandle();
      }, 500);
    } else {
      // Reset handle if not fully swiped
      resetHandle();
    }
  };

  // Reset the swipe handle to the starting position
  const resetHandle = () => {
    setCurrentX(4);
  };

  return (
    <div className="swipe-container">
      {/* Swipe indicator (back and forth animation) */}
      {!isDragging && <div className="swipe-indicator" />}

      {/* Swipe handle */}
      <div
        className="swipe-handle"
        ref={handleRef}
        style={{ left: `${currentX}px` }}
        onMouseDown={startDrag}
        onTouchStart={startDrag}
      >
        <HiChevronDoubleRight />
      </div>
      Slide to un-hold the call

      {/* Mouse events for desktop */}
      {isDragging && (
        <>
          <div
            className="swipe-overlay"
            onMouseMove={onDrag}
            onMouseUp={endDrag}
            onTouchMove={onDrag}
            onTouchEnd={endDrag}
          />
        </>
      )}
    </div>
  );
};

export default SwipeToUnhold;
