export const msToLongDateString = (ms) => {
  if (ms === -1) {
    return "Never";
  }
  return (
    new Date(parseInt(ms)).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }) +
    " " +
    new Date(parseInt(ms)).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZoneName: "short",
    })
  );
};

export const currentTimeInMilliSecs = () => {
  return new Date().getTime();
}; // UTC

export const timeAgo = (timestamp) => {
  const seconds = Math.floor((Date.now() - timestamp) / 1000);
  const intervals = [
    { interval: 31536000, label: "year" },
    { interval: 2592000, label: "month" },
    { interval: 86400, label: "day" },
    { interval: 3600, label: "hour" },
    { interval: 60, label: "min" },
    { interval: 1, label: "sec" },
  ];

  for (let i = 0; i < intervals.length; i++) {
    const interval = intervals[i];
    const count = Math.floor(seconds / interval.interval);

    if (count >= 1) {
      return count === 1
        ? `${count} ${interval.label} ago`
        : `${count} ${interval.label}s ago`;
    }
  }

  return "Just now";
};

export const msToDateString = (ms) => {
  return new Date(parseInt(ms)).toLocaleString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const msToShortDateString = (ms) => {
  return (
    new Date(parseInt(ms)).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }) +
    " " +
    new Date(parseInt(ms)).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );
};

export var msToMinutes = function (ms) {
  if (ms) {
    return parseInt(ms / 1000 / 60);
  } else {
    return 0;
  }
};

export var msToSeconds = function (ms) {
  if (ms) {
    return parseInt(ms / 1000);
  } else {
    return 0;
  }
};

export const msToTimeString = (ms) => {
  return new Date(parseInt(ms)).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export function msToMinuteSec(ms) {
  const minutes = msToMinutes(ms);
  const overage = ms - minutes * 60 * 1000;
  const seconds = msToSeconds(overage);

  var timestring = "";
  if (minutes > 0) {
    timestring += minutes + (minutes > 1 ? " mins" : " min");
  }
  if (seconds > 0) {
    timestring +=
      (minutes > 0 ? " " : "") + seconds + (seconds > 1 ? " secs" : " sec");
  }
  return timestring;
}

export const roundToNearest15 = (date = new Date()) => {
  const minutes = 15;
  const ms = 1000 * 60 * minutes;

  // 👇️ replace Math.round with Math.ceil to always round UP
  return new Date(Math.ceil(date.getTime() / ms) * ms);
};
