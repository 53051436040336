export function identifyMessageType(url) {
  const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

  if (urlPattern.test(url)) {
    const extension = url.split(".").pop().toLowerCase();

    const imageExtensions = ["jpeg", "jpg", "png", "gif", "webp"];
    const videoExtensions = ["mp4", "avi", "mov", "mkv", "webm"];
    const audioExtensions = ["mp3", "wav", "ogg", "aac", "mpeg", "amr"];

    if (imageExtensions.includes(extension)) {
      return "photo";
    }

    if (videoExtensions.includes(extension)) {
      return "video";
    }

    if (audioExtensions.includes(extension)) {
      return "audio";
    }

    // If not image, video, or audio, consider it as a file
    return "file";
  } else {
    return "text";
  }
}
