import React, { useContext, useEffect, useState } from "react";
import { currentTimeInMilliSecs, timeAgo } from "../../../utils/time";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../../context/SocketContext";

function Leads({
  whatsapp,
  leads,
  leadsCount,
  selectedWidgetId,
  searchInput,
  pageCount,
  pageItemsCount,
  setPageCount,
  loading,
  setLoading,
}) {
  const navigate = useNavigate();
  const [whatsappNotifData, setWhatsappNotifData] = useState({});
  const { Socket, setSocket } = useContext(SocketContext);

  const handleNavigate = (item) => {
    if (whatsapp) {
      setSocket((prev) => ({ ...prev, last_whatsapp_chat_message: null }));
    }

    navigate(
      `/meetings/${whatsapp ? "whatsapp" : "leads"}/${item.id}?${
        selectedWidgetId ? "widget_id=" + selectedWidgetId + "&" : ""
      }${searchInput ? "q=" + encodeURIComponent(searchInput) + "&" : ""}`
    );
  };

  useEffect(() => {
    const data = Socket?.last_whatsapp_chat_message;

    if (data) {
      const id = data.conversation?.people_id
        ? data.conversation.people_id
        : data.conversation.id;

      setWhatsappNotifData((prevData) => ({
        ...prevData,
        [id]: [...(prevData[id] ?? []), data.conversation],
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Socket?.last_whatsapp_chat_message]);

  useEffect(() => {
    return () => {
      setSocket((prev) => ({ ...prev, last_whatsapp_chat_message: null }));
    };
    // eslint-disable-next-line
  }, [whatsappNotifData]);

  return (
    <>
      {leads?.map((item, index) => {
        return (
          <div
            key={item.id}
            className="conversation-wrapper"
            onClick={() => handleNavigate(item)}
          >
            <div className="avatar-container">
              <p className="avatar-text">
                {item?.unique_field_value[0]?.toUpperCase()}
              </p>
              <span
                className="avatar-status"
                style={{
                  border: `2px solid ${
                    item.is_connected &&
                    currentTimeInMilliSecs() - item.last_seen_on < 20000
                      ? "#3ac47d"
                      : "#fff"
                  }`,
                  background:
                    item.is_connected &&
                    currentTimeInMilliSecs() - item.last_seen_on < 20000
                      ? "#fff"
                      : "#ccc",
                }}
              ></span>
            </div>
            <div style={{ flex: "1", position: "relative" }}>
              <div style={{ display: "flex" }}>
                <p className="conversation-unique-field">
                  {item.unique_field_value}
                </p>
                <p className="conversation-last-conversation-time">
                  {timeAgo(item.last_conversation_time)}
                </p>
              </div>

              <div>
                <p className="conversation-status">
                  {(item.last_attended_by
                    ? "Agent: " + item.last_attended_by
                    : "") +
                    " Status: " +
                    item.lead_status}
                </p>
              </div>

              {!whatsapp && !item.is_conversation_read && (
                <span className="unread-conversation">
                  {item.unread_conversations < 10
                    ? item.unread_conversations
                    : "9+"}
                </span>
              )}

              {whatsapp &&
                (whatsappNotifData[item.id]?.length ??
                  0 + item.unread_conversations) > 0 && (
                  <span className="unread-conversation">
                    {(whatsappNotifData[item.id]?.length ?? 0) +
                      item.unread_conversations <
                    10
                      ? (whatsappNotifData[item.id]?.length ?? 0) +
                        item.unread_conversations
                      : "9+"}
                  </span>
                )}
            </div>
          </div>
        );
      })}
      {pageCount * pageItemsCount < leadsCount ? (
        <div className="load-more-conversation-wrapper">
          <LoadingButton
            color="inherit"
            variant="outlined"
            loading={loading}
            loadingIndicator="Loading…"
            onClick={(e) => {
              e.preventDefault();
              setLoading(true);
              setPageCount((pageCount) => pageCount + 1);
            }}
          >
            <span>Load more</span>
          </LoadingButton>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Leads;
