import React, { createContext, useState } from "react";

const ClientContext = createContext();

const ClientProvider = ({ children }) => {
  const [Client, setClient] = useState({});

  return (
    <ClientContext.Provider value={{ Client, setClient }}>
      {children}
    </ClientContext.Provider>
  );
};

export { ClientContext, ClientProvider };
