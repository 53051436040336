import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { config } from "../../../config";
import { postCall } from "../../../utils/methods";

const maxModerators = 4;
const filter = createFilterOptions();

export default function ModeratorsAndParticipants({
  moderators,
  setModerators,
  participants,
  setParticipants,
}) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    postCall({
      url: config.url.CLIENT_USER_MAPPINGS,
      path: `/with_role_and_group_name_v2/1/10/null/null`,
      onSuccess: (response) => {
        setUsers(response.data.records);
      },
      onFailure: (error) => {},
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {maxModerators > 0 && (
          <div>
            {/* <label htmlFor="moderators">Moderators</label> */}
            <Autocomplete
              value={moderators}
              multiple
              onChange={(event, newValue) => {
                setModerators(newValue);
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={moderators}
              getOptionLabel={(option) => {
                return option;
              }}
              renderOption={(props, option) => <li {...props}>{option}</li>}
              sx={{ width: "100%", marginTop: "20px" }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Moderators" />
              )}
            />
          </div>
        )}
      </div>
      <div>
        {/* <label for="moderators">Participants</label> */}
        <Autocomplete
          value={participants}
          multiple
          onChange={(event, newValue) => {
            setParticipants(newValue);
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option);
            if (inputValue !== "" && !isExisting) {
              filtered.push(inputValue);
            }
            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={users?.map((user) => user.user_email)}
          getOptionLabel={(option) => {
            return option;
          }}
          renderOption={(props, option) => <li {...props}>{option}</li>}
          sx={{ width: "100%", marginTop: "20px" }}
          freeSolo
          renderInput={(params) => (
            <TextField {...params} label="Participants" />
          )}
        />
      </div>
    </div>
  );
}
