export function checkUnreadMessages() {
  const chatLog = document.querySelector(".saleassist-video-call-chat-log");
  const messageNotification = document.querySelectorAll(
    ".sa-message-notification"
  );

  const hasUnreadMessages = chatLog.querySelector(".unread") !== null;

  if (hasUnreadMessages) {
    messageNotification.forEach((msg) => (msg.style.display = "flex"));
  } else {
    messageNotification.forEach((msg) => (msg.style.display = "none"));
  }
}
