import React, { useState } from "react";
import { FaDeleteLeft, FaPaste, FaPlus } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { topAlert } from "../../utils/toastAlerts";
import { isPhoneNumber } from "../../utils/text";
import { config } from "../../config";
import { postCall } from "../../utils/methods";
import "./outbound-calls.scss";

function OutboundCalls() {
  const dialer = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleButtonClick = (value) => {
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + value);
  };

  const handleDelete = () => {
    setPhoneNumber((prevPhoneNumber) => prevPhoneNumber.slice(0, -1));
  };

  const handlePaste = async () => {
    try {
      const clipboardContent = await navigator.clipboard.readText();
      setPhoneNumber((prevPhoneNumber) => prevPhoneNumber + clipboardContent);
    } catch (error) {
      console.error("Error reading clipboard:", error);
      topAlert("Error reading clipboard: " + error, "error");
    }
  };

  const makePhoneCall = () => {
    if (isPhoneNumber(phoneNumber)) {
      postCall({
        url: config.url.MEETINGS,
        path: "/outbound_call_manual/" + phoneNumber,
        onSuccess: (response) => {
          topAlert(
            "Call initiated. Please receive a call on your phone.",
            "success"
          );
        },
        onFailure: (error) => {
          topAlert(
            "Unable to place a call. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "error"
          );
        },
      });
    } else {
      topAlert("Unable to place a call. Please check number!", "warning");
    }
  };

  return (
    <div className="saleassist-outbound-calls-container">
      <div className="input-wrapper">
        <div className="input-text-container">
          <input type="text" value={phoneNumber} readOnly />
          {phoneNumber.length > 0 ? (
            <span className="input-clear" onClick={handleDelete}>
              <FaDeleteLeft />
            </span>
          ) : (
            <span className="input-paste" onClick={handlePaste}>
              <FaPaste />
            </span>
          )}
        </div>
      </div>

      <div className="dialer-wrapper">
        {dialer.map((number) => (
          <span
            key={`dialer-${number}`}
            className="dialer-button"
            onClick={() => handleButtonClick(number)}
          >
            <span>{number}</span>
          </span>
        ))}

        <span
          key="dialer-plus"
          className="dialer-button"
          onClick={() => handleButtonClick("+")}
        >
          <span>
            <FaPlus />
          </span>
        </span>

        <span
          key="dialer-zero"
          className="dialer-button"
          onClick={() => handleButtonClick("0")}
        >
          <span>0</span>
        </span>

        <span
          key="dialer-zero"
          className="dialer-button call-button"
          onClick={makePhoneCall}
        >
          <span>
            <IoCall />
          </span>
        </span>
      </div>
    </div>
  );
}

export default OutboundCalls;
