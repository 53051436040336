import React from 'react'
import ColorPaletteButton from './color-palette';

function VideoBackgroundColor() {
    const color_codes = ["#E0E0E0","#B0C4DE","#98FB98","#FFFDD0","#87CEEB","#E6E6FA","#FFD1DC","#AFEEEE","#FFDAB9","#F5F5DC"];
    
    return (
        <div className="video-background-color-cont">
            {color_codes.map(color => 
                <div key={color} color-value={color} style={{background:color}} className="video-background-color-btn"></div>
            )}

            <input type="color" id="video-background-custom-color" style={{visibility: "hidden",opacity: "0",position: "absolute",bottom: "3px",}} />
            
            <div
              className="video-background-color-custom-btn"
              onClick={() => {document.getElementById("video-background-custom-color").click();}}
            >
              <span className="video-background-color-icon"><ColorPaletteButton /></span>
            </div>
        </div>
    )
}

export default VideoBackgroundColor
