export async function changeDeviceInput(type, selectedValue) {
  if (type === "video") {
    let currentCamera = this.videoInputDevices.find(
      (cam) => cam.label === selectedValue
    );

    await this.localVideoTrack.setDevice(currentCamera.deviceId);

    // Handle the mirrored effect for the local user's back/rear camera
    if (
      currentCamera.label.toLowerCase().includes("back") ||
      currentCamera.label.toLowerCase().includes("rear")
    ) {
      const videoElement = document.querySelector("#localVideoContainer video");
      videoElement.style.transform = "rotateY(0deg)";
    }
  }
  if (type === "audio") {
    let currentMic = this.audioInputDevices.find(
      (mic) => mic.label === selectedValue
    );

    await this.localAudioTrack.setDevice(currentMic.deviceId);
  }
}
