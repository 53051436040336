import { config } from "../../config";
import { postCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";

export const handleAgentFeedbackSubmit = (
  e,
  agent_feedback_form,
  setFormButton,
  setFormErrorText,
  setActiveRequestOpen,
  callRequest,
  User
) => {
  e.preventDefault();
  setFormButton(true);
  const formData = {};

  const leadFormFields = agent_feedback_form;
  const requiredFields = leadFormFields.filter((field) => field.required);

  const leadCaptureFormFieldsEl = document.querySelectorAll(
    ".sa-live-call-widget-lead-capture-form-field"
  );

  const leadCaptureFormRadioFieldsEl = document.querySelectorAll(
    ".sa-live-call-widget-lead-capture-form-field-radio"
  );
  const leadCaptureFormCheckboxFieldsEl = document.querySelectorAll(
    ".sa-live-call-widget-lead-capture-form-field-checkbox"
  );

  // get form data for each input
  leadCaptureFormFieldsEl.forEach((fieldEl) => {
    if (fieldEl.tagName.toLocaleLowerCase() === "select") {
      const selectedOptions = Array.from(
        fieldEl.querySelectorAll("option:checked"),
        (e) => e.value
      );
      formData[fieldEl.name] = selectedOptions;
    } else {
      formData[fieldEl.name] = fieldEl.value;
    }
  });

  // get form data for each radio & checkbox input
  const getRadioAndCheckboxValue = (elements) => {
    const selectedOptions = [];
    elements.forEach((element) => {
      if (element.checked) {
        selectedOptions.push(element.value);
      }
    });
    formData[elements[0].getAttribute("name")] = selectedOptions;
  };

  if (leadCaptureFormRadioFieldsEl.length) {
    getRadioAndCheckboxValue(leadCaptureFormRadioFieldsEl);
  }

  if (leadCaptureFormCheckboxFieldsEl.length) {
    getRadioAndCheckboxValue(leadCaptureFormCheckboxFieldsEl);
  }

  let hasFalseValue = false;

  for (const obj of requiredFields) {
    const key = obj.name;
    if (
      key in formData &&
      (formData[key] === false ||
        formData[key] === null ||
        formData[key] === undefined ||
        (typeof formData[key] === "string" && formData[key].trim() === ""))
    ) {
      hasFalseValue = true;
      break;
    }
  }

  const mobileNumberEnteredValue = formData.mobile_number;
  const { maxlength: mobileNumberMaxLength } =
    leadFormFields.find((field) => field.name === "mobile_number") || {};

  const showError = (errorMessage = "") => {
    setFormErrorText(errorMessage);
    setFormButton(false);
    return;
  };

  if (
    mobileNumberEnteredValue &&
    mobileNumberEnteredValue.length < mobileNumberMaxLength
  ) {
    showError(
      `Please Enter ${mobileNumberMaxLength} digit Mobile Number including +`
    );
  } else if (hasFalseValue) {
    showError("Required fields can't be empty.");
  } else {
    postCall({
      url: config.url.FORMS,
      data: {
        people_id: callRequest.people_id,
        data: formData,
        relates_to_collection: "sources",
        collection_id: callRequest.source_id,
      },
      onSuccess: (response) => {
        postCall({
          url: config.url.CONVERSATIONS,
          data: {
            message_content: response.data.id,
            meta: [response.data.data],
            message_type: "agent_feedback_form",
            message_from: User.id,
            message_to: callRequest.people_id,
            people_id: callRequest.people_id,
            unique_field_value: User.user_name,
            form_id: response.data.id,
            source_id: callRequest.source_id,
            source_type: callRequest.source_type,
            source_name: callRequest.source_name,
            source_referrer: window.location.href.includes(
              "&saleassist_referrer="
            )
              ? window.location.href.split("&saleassist_referrer=")[1]
              : window.location.href,
          },
          onSuccess: (response) => {
            bottomAlert("Your form submitted.");
            showError();
            setActiveRequestOpen(false);
          },
          onFailure: (error) => {
            bottomAlert(
              "Form submission failed. " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "error"
            );
            showError("Form submission failed.", error);
          },
        });
      },
      onFailure: (error) => {
        bottomAlert(
          "Form submission failed. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "error"
        );
        showError("Form submission failed.", error);
      },
    });
  }
};
