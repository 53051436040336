import { bottomAlert } from "../../../utils/toastAlerts";

export const pipModeHandler = (event, el) => {
  event.stopPropagation();

  if (document.pictureInPictureElement) {
    document.exitPictureInPicture();
  } else if (document.pictureInPictureEnabled) {
    if (el) {
      el.requestPictureInPicture().catch((error) => {
        console.log("Error entering Picture-in-Picture mode:", error);
        bottomAlert("Error entering Picture-in-Picture mode:" + error, "error");
      });
    } else {
      bottomAlert("Video not found for Picture-in-Picture mode", "error");
    }
  }
};