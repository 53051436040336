import React, { useState } from "react";
import { postCall, putCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import { Button, } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, } from "@mui/x-date-pickers/DatePicker";
import { TextField } from '@mui/material';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./toDoTable.scss";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from '@mui/material/Tooltip';

const AddTaskSection = ({
  setShowAddTaskSection,
  handleClose,
  refreshTaskList,
  setRefreshTaskList,
  setItemToBeUpdated,
  itemToBeUpdated,
}) => {
  console.log(itemToBeUpdated, 'itemToBeUpdated')
  const [taskToAdd, setTaskToAdd] = useState({
    id: itemToBeUpdated?.lead || "",
    task_id: itemToBeUpdated?.id || "",
    name: itemToBeUpdated?.name || "",
    note: itemToBeUpdated?.notes || "",
    priority: itemToBeUpdated?.priority_level || "LOW",
    reminder: itemToBeUpdated?.reminder || false,
    reminder_time: itemToBeUpdated?.reminder_time || null,
  });
  // added this line to ignore the eslint warning regarding the setDateTimeValue which is not being used but defined, i'll use it and remove this line in future
  // eslint-disable-next-line
  const [dateTimeValue, setDateTimeValue] = useState(taskToAdd.reminder_time);
  const [isReminderChecked, setIsReminderChecked] = useState(taskToAdd.reminder);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const maxWidth__576 = window.matchMedia("(max-width: 576px)").matches;
  const maxWidth__768 = window.matchMedia("(max-width: 768px)").matches;

  const handleChange = (e) => {
    e.persist();
    setTaskToAdd((previous) => ({
      ...previous,
      [e.target?.name]: e.target?.value,
    }));
  };

  const postData = () => {
    postCall({
      url: config.url.TO_DO,
      data: {
        name: taskToAdd.name,
        notes: taskToAdd.note,
        reminder: isReminderChecked,
        reminder_time: (date && time) ? date.format('YYYY-MM-DD') + "T" + time.format('HH:mm') : "",
        status: "SCHEDULED",
        priority_level: taskToAdd.priority,
        "timezone": localTimeZone,
      },
      onSuccess: (response) => {
        setRefreshTaskList(!refreshTaskList);
        bottomAlert("Task has been added to To-Do list", "success");
        handleClose()
        setDate(null)
        setTime(null)
        setTaskToAdd({
          id:"",
          task_id:"",
          name:"",
          note:"",
          priority:"",
          reminder:"",
          reminder_time:""
        })
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  const updateData = () => {
    // setShowAddTaskSection(false);

    putCall({
      url: config.url.TO_DO,
      path: `${taskToAdd.task_id}`,
      data: {
        name: taskToAdd.name,
        notes: taskToAdd.note,
        lead: taskToAdd.id,
        reminder: isReminderChecked,
        reminder_time: (date && time) ? date.format('YYYY-MM-DD') + "T" + time.format('HH:mm') : taskToAdd.reminder_time || "",
        status: "SCHEDULED",
        priority_level: taskToAdd.priority,
        "timezone": localTimeZone
      },
      onSuccess: (response) => {
        setRefreshTaskList(!refreshTaskList);
        bottomAlert("Task has been updated", "success");
        handleClose()
        setDate(null)
        setTime(null)
        setTaskToAdd({
          id:"",
          task_id:"",
          name:"",
          note:"",
          priority:"",
          reminder:"",
          reminder_time:""
        })
      },
      onFailure: (error) => {
        bottomAlert("Unable to complete your request.", "warning");
      },
    });
  };

  return (
    <>
      <section
        style={{
          display: "flex",
          flexWrap: "wrap",
          columnGap: "10px",
          rowGap: "10px",
          alignItems: 'center',
        }}
      >
        {taskToAdd.id &&  <input
          type="text"
          value={taskToAdd.id}
          name="id"
          placeholder="Task Id"
          style={{
            width: maxWidth__576 ? "100%" : maxWidth__768 ? "45%" : "30%",
            padding: "0px 12px",
            height: "38px",
            border: "1px solid #ced4da",
            color: "#495057",
            borderRadius: "4px",
            background:"#eee"
          }}
          readOnly={true}
          onChange={(e) => handleChange(e)}
        disabled/> }
       
        <input
          type="text"
          value={taskToAdd.name}
          name="name"
          placeholder="Task Name"
          style={{
            width: maxWidth__576 ? "100%" : maxWidth__768 ? "45%" : "30%",
            padding: "0px 12px",
            height: "38px",
            border: "1px solid #ced4da",
            color: "#495057",
            borderRadius: "4px"
          }}
          onChange={(e) => handleChange(e)}
        />
        <input
          type="text"
          value={taskToAdd.note}
          name="note"
          placeholder="Task Note"
          style={{
            width: maxWidth__576 ? "100%" : maxWidth__768 ? "45%" : "30%",
            padding: "0px 12px",
            height: "38px",
            border: "1px solid #ced4da",
            color: "#495057",
            borderRadius: "4px"
          }}
          onChange={(e) => handleChange(e)}
        />
        <div className="form_fieldsRow">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              // label="Date"
              variant="outlined"
              value={date}
              onChange={(newValue) => setDate(newValue)}
              className="toDoDateTimePicker"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select a date"
                  variant="outlined"
                  // label="Date"
                />
              )}
            />
            <TimePicker
              // label="Time"
              minutesStep={1}
              value={time}
              className="toDoDateTimePicker"
              onChange={(newValue) => { setTime(newValue) }}
            />
          </LocalizationProvider>
        </div>
        <div className="form_fieldsRow">
          <div className="remndr">
            <Tooltip title="Mark as Reminder">
              <span>
                <Checkbox
                  style={{ padding: 0 }}
                  inputProps={{ "aria-label": "Mark as Reminder Checkbox" }}
                  checked={isReminderChecked}
                  onChange={() => setIsReminderChecked(!isReminderChecked)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 39 } }}
                />
                <label style={{ marginLeft: '3px' }}>Reminder</label>
              </span>
            </Tooltip>
          </div>

          <select
            size="sm"
            type="select"
            style={{
              width: "50%",
              cursor: "pointer",
              fontSize: "14px",
              border: "1px solid #ced4da",
              borderRadius: "0.25rem",
              color: "#495057",
              backgroundColor: "#fff",
              fontWeight: 400,
              height: "35px",
              padding: "5px",
              outline: "none"
            }}
            value={taskToAdd?.priority}
            name="priority"
            onChange={(e) => handleChange(e)}
          >
            <option value="LOW">Low</option>
            <option value="MEDIUM">Medium</option>
            <option value="HIGH">High</option>
          </select>
        </div>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            handleClose();
            setItemToBeUpdated(null);
          }}
          style={{ height: "38px" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            taskToAdd.name &&
              taskToAdd.note
              ? itemToBeUpdated
                ? updateData()
                : postData()
              : null
          }
          style={{
            height: "38px",
            opacity:
              taskToAdd.name &&
                taskToAdd.note
                ? "1"
                : "0.5",
            cursor:
              taskToAdd.name &&
                taskToAdd.note
                ? "pointer"
                : "not-allowed",
          }}
        >
          {itemToBeUpdated ? "Update" : "Save"}
        </Button>
      </section>
    </>
  );
};
export default AddTaskSection;
