import React from "react";
import ReactDOM from "react-dom";
import ScreenShareOffButton from "../../components/Buttons/screen-share-off";
import ScreenShareOnButton from "../../components/Buttons/screen-share-on";
import { config } from "../../../config";
import { bottomAlert } from "../../../utils/toastAlerts";
import { http } from "./others";
import { postCall } from "../../../utils/methods";

export async function screenShare(AgoraRTC, e) {
  let screenVideoTrack = null;
  let screenAudioTrack = null;

  try {
    if (this.isSharingEnabled === false) {

      let user_name = `screen-share-${this.user_display_name}`;

      const response = await http.get(config.url.ACTIONS,`/meeting/${this.meeting_data.id}`);

      for (let key in this.remoteUsers) {
        if (key in response.uid_map) {
          if(response.uid_map[key].user_display_name.includes("screen-share")) {
            return bottomAlert("Screen sharing is limited to 1 user at a time.", "info");
          }
        } 
      }

      postCall({
        url: config.url.ACTIONS,
        path: `/meeting/join_channel/${this.room_id}/${user_name}`,
        onSuccess: async (response) => {
          this.screen_creds = response.data;
          let app_id = response.data.widget_creds.app_id;
          let channel_name = response.data.widget_creds.channel_name;
          let token = response.data.widget_creds.token;
          let uid = response.data.widget_creds.uid;

          await this.screen_client.join(app_id, channel_name, token, uid);

          // Create a screen sharing track
          try {
            this.localScreenTrack = await AgoraRTC.createScreenVideoTrack({}, "auto");

            if (this.localScreenTrack instanceof Array) {
              screenVideoTrack = this.localScreenTrack[0];
              screenAudioTrack = this.localScreenTrack[1];
            } else {
              screenVideoTrack = this.localScreenTrack;
            }

            // Publish the screen sharing track to the channel
            screenVideoTrack.play(`remoteVideo_${uid}`);

            // to track screen-share ended or not
            screenVideoTrack.on("track-ended", async () => {
              // Unpublish the screen sharing track
              if (screenAudioTrack == null) {
                await this.screen_client.unpublish(screenVideoTrack);
                screenVideoTrack.close();
              } else {
                await this.screen_client.unpublish([screenVideoTrack, screenAudioTrack]);
                screenVideoTrack.close();
                screenAudioTrack.close();
              }

              // leave the channel
              await this.screen_client.leave()

              // e.innerHTML = screenShareOnButton();
              ReactDOM.render(<ScreenShareOnButton />, e);
              this.isSharingEnabled = false;
            });

            if (screenAudioTrack === null) {
              if(screenVideoTrack) {
                await this.screen_client.publish(screenVideoTrack);
              }
            } else {
              if(screenVideoTrack && screenAudioTrack) {
                await this.screen_client.publish([screenVideoTrack, screenAudioTrack]);
              }
            }
      
            // e.innerHTML = <ScreenShareOffButton/>;
            ReactDOM.render(<ScreenShareOffButton />, e);
            this.isSharingEnabled = true;

          } 
          catch (err) {
            console.error(err);
            await this.screen_client.leave();
          }
        },
        onFailuure: (error) => {
          bottomAlert("Unable to join channel for screen share.", "danger");
        },
      });
    } else {
      if (this.localScreenTrack instanceof Array) {
        screenVideoTrack = this.localScreenTrack[0];
        screenAudioTrack = this.localScreenTrack[1];
      } else {
        screenVideoTrack = this.localScreenTrack;
      }

      // Unpublish the screen sharing track
      if (screenAudioTrack == null) {
        await this.screen_client.unpublish(screenVideoTrack);
        screenVideoTrack.close();
      } else {
        await this.screen_client.unpublish([screenVideoTrack, screenAudioTrack]);
        screenVideoTrack.close();
        screenAudioTrack.close();
      }

      // leave the channel
      await this.screen_client.leave();

      // e.innerHTML = <ScreenShareOnButton/>;
      ReactDOM.render(<ScreenShareOnButton />, e);
      this.isSharingEnabled = false;
    }
  } catch (error) {
    console.log("Screen sharing failed:", error);
  }
}
