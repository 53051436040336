import React, { useEffect, useState } from "react";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#000",
  },
}));

function CircularProgressWithLabel(props) {
  const chatExpired =
    props.hrsLeft === 0 && props.minLeft === 0 && props.value === 100;

  let hourFormat = chatExpired
    ? 0
    : props.hrsLeft === 0
    ? ""
    : props.hrsLeft === 1
    ? `${props.hrsLeft}hr`
    : `${props.hrsLeft}hrs`;

  let minuteFormat = chatExpired
    ? 0
    : props.minLeft === 0
    ? ""
    : props.minLeft === 1
    ? `${props.minLeft}min`
    : `${props.minLeft}mins`;

  const tooltipText = chatExpired
    ? "Chat expired! WhatsApp will allow you to send only template messages."
    : `Chat expires in ${hourFormat} ${minuteFormat}. Once expired, WhatsApp will allow only template messages to be sent.`;

  const [lightProgressColor, darkProgressColor] = chatExpired
    ? ["#bdbdbd26", "#bdbdbd"]
    : props.hrsLeft > 11
    ? ["#469a1026", "#469a10"]
    : props.hrsLeft >= 1
    ? ["#ffd60026", "#ffd600"]
    : ["#d5000026", "#d50000"];

  return (
    <CustomTooltip title={tooltipText} placement="bottom-end" arrow>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={100}
          sx={{
            color: lightProgressColor,
          }}
          size={70}
          thickness={2}
        />
        <CircularProgress
          variant="determinate"
          value={props.value}
          sx={{
            color: darkProgressColor,
            position: "absolute",
            left: "0",
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={70}
          thickness={2}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{
              display: "flex",
              placeContent: "center",
              placeItems: "center",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              border: `1px solid ${darkProgressColor}`,
              background: `${darkProgressColor}0d`,
              color: darkProgressColor,
              fontSize: "14px",
              lineHeight: "12px",
              fontWeight: 600,
            }}
          >
            {`${props.hrsLeft > 9 ? "" : "0"}${props.hrsLeft}:${
              props.minLeft > 9 ? "" : "0"
            }${props.minLeft}`}
          </Typography>
        </Box>
      </Box>
    </CustomTooltip>
  );
}

export default function WhatsAppTimer({ startTime }) {
  const [progress, setProgress] = useState(0);
  const [hoursLeft, setHoursLeft] = useState("");
  const [minutesLeft, setMinutesLeft] = useState("");
  const secondsInDay = 24 * 60 * 60;

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const elapsedTimeInSeconds = (currentTime - startTime) / 1000;
      const remainingTimeInSeconds = secondsInDay - elapsedTimeInSeconds;

      if (isNaN(remainingTimeInSeconds) || remainingTimeInSeconds <= 0) {
        clearInterval(timer);
        setProgress(100);
        setHoursLeft(0);
        setMinutesLeft(0);
      } else {
        const remainingHours = Math.floor(remainingTimeInSeconds / 3600);
        const remainingMinutes = Math.floor(
          (remainingTimeInSeconds % 3600) / 60
        );

        setHoursLeft(remainingHours);
        setMinutesLeft(remainingMinutes);
        setProgress(
          ((secondsInDay - remainingTimeInSeconds) / secondsInDay) * 100
        );
      }
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [startTime]);

  return (
    <CircularProgressWithLabel
      value={progress}
      hrsLeft={hoursLeft}
      minLeft={minutesLeft}
    />
  );
}
