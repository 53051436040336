import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { postCall, getCall, putCall } from "../../../../utils/methods";
import {
  currentTimeInMilliSecs,
  msToDateString,
  msToTimeString,
} from "../../../../utils/time";
import { Button, Divider } from "@mui/material";
import { config } from "../../../../config";
import MessageBoxWrapper from "../../MessageBox/MessageBoxWrapper";
import SendWhatsAppMessage from "../../SendMessageBox/SendWhatsAppMessage";
import WhatsAppTimer from "../../WhatsappTimer/WhatsappTimer";
import { ClientContext } from "../../../../context/ClientContext";

import "./lead-details.scss";

function WhatsAppLeadDetails() {
  const { id } = useParams();
  const { Client } = useContext(ClientContext);
  const [whatsAppLeads, setWhatsAppLeads] = useState([]);
  const [whatsAppLeadsData, setWhatsAppLeadsData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [conversationsCount, setConversationsCount] = useState(0);
  const [conversationID, setConversationID] = useState("");
  const [sessionID, setSessionID] = useState("");
  const [checkHandoverStatus, setCheckHandoverStatus] = useState("");

  const messagesTopRef = useRef();
  const messagesStartRef = useRef();
  const messagesEndRef = useRef();

  const pageItemsCount = 10;

  const handleScrollStable = (direction = "bottom") => {
    const options = {
      behavior: "smooth", // Smooth scrolling
      block: "end", // Scroll to the end of the element
    };

    if (direction === "up") {
      messagesStartRef.current &&
        messagesStartRef.current.scrollIntoView(options);
    } else if (direction === "top") {
      messagesTopRef.current && messagesTopRef.current.scrollIntoView(options);
    } else {
      messagesEndRef.current && messagesEndRef.current.scrollIntoView(options);
    }
  };

  const fetchWhatsAppLeads = () => {
    getCall({
      url: config.url.PEOPLE,
      path: `/whatsapp_filter/1/10`,
      onSuccess: (response) => {
        setWhatsAppLeads(response.data.records);
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  const fetchWhatsAppLeadsData = (count) => {
    postCall({
      url: config.url.CONVERSATIONS,
      path: `/people/${id}/${count || pageCount}/${pageItemsCount}`,
      data: {
        message_type: "whatsapp_message",
      },
      onSuccess: (response) => {
        setWhatsAppLeadsData((leadData) => {
          const conversations = leadData;
          response.data.records.forEach((conversation) => {
            conversations.unshift(conversation);
          });
          return conversations;
        });
        setConversationsCount(response.data.count);
        setPageCount(count || 1);

        let receiverWaMsgs = [...whatsAppLeadsData]?.filter(
          (message) => message.session_id !== "" && message.message_to === ""
        );

        receiverWaMsgs = receiverWaMsgs[receiverWaMsgs.length - 1] || "";

        setConversationID(receiverWaMsgs?.id);
        setSessionID(receiverWaMsgs?.session_id);

        if (
          Client.whatsapp_ai_auto_response ||
          Client.whatsapp_auto_default_msg
        ) {
          postCall({
            url: config.url.BASE,
            path: `whatsapp/check_handover_status/${receiverWaMsgs?.id}/${receiverWaMsgs?.session_id}`,
            onSuccess: (response) => {
              setCheckHandoverStatus(response.data);
            },
            onFailure: (error) => {
              console.log(
                "Unable to fetch the handover status. " +
                  ((error.response && error.response.data.detail) ||
                    error.toString())
              );
            },
          });
        }

        putCall({
          url: config.url.PEOPLE,
          path: `set_conversations_read/${id}`,
          onSuccess: (response) => {},
          onFailure: (error) => {
            console.log(error);
          },
        });
      },
      onFailure: (error) => {
        console.log(error);
      },
    });
  };

  const handoverWhatsApp = (status) => {
    postCall({
      url: config.url.BASE,
      path: `whatsapp/change_handover_status/${conversationID}/${sessionID}`,
      data: {
        is_bot_handover: status,
      },
      onSuccess: (response) => {
        setCheckHandoverStatus(status);
      },
      onFailure: (error) => {
        console.log(
          "Unable to change the handover status. " +
            ((error.response && error.response.data.detail) || error.toString())
        );
      },
    });
  };

  useEffect(() => {
    fetchWhatsAppLeads();
    fetchWhatsAppLeadsData();

    const interval = setInterval(() => {
      if (messagesEndRef) {
        handleScrollStable();
        clearInterval(interval);
      }
    }, 500);

    // eslint-disable-next-line
  }, []);

  const selectedLead = whatsAppLeads?.find((lead) => lead.id === id) || {};

  let lastDate = "";
  let lastTime = "";
  let lastUser = "";
  let lastForm = "";

  return (
    <div className="lead-conversations-container">
      <div className="lead-conversations-container-header whatsapp-lead-detail">
        <div className="lead-avatar-wrapper">
          <p className="lead-avatar-text">
            {selectedLead?.unique_field_value?.[0]?.toUpperCase()}
          </p>
          <span
            className="lead-avatar-status"
            style={{
              border: `2px solid ${
                selectedLead.is_connected &&
                currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                  ? "#3ac47d"
                  : "#fff"
              }`,
              background:
                selectedLead.is_connected &&
                currentTimeInMilliSecs() - selectedLead.last_seen_on < 20000
                  ? "#fff"
                  : "#ccc",
            }}
          ></span>
        </div>
        <div className="lead-user-number">
          <p className="lead-unique-field">
            {selectedLead.unique_field_value}
            <span>{selectedLead.lead_owned_by}</span>
          </p>
        </div>
        <WhatsAppTimer startTime={new Date(selectedLead.session_time_left)} />
      </div>
      <div className="lead-conversations-container-body">
        <div className="conversations-wrapper">
          <div ref={messagesTopRef}></div>
          <div ref={messagesStartRef}></div>
          {whatsAppLeadsData.length > 0 && (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {pageCount * pageItemsCount < conversationsCount ? (
                <Button
                  color="inherit"
                  variant="outlined"
                  style={{ margin: "8px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    fetchWhatsAppLeadsData(pageCount + 1);
                    handleScrollStable("top");
                  }}
                >
                  Load more conversations
                </Button>
              ) : (
                <Button variant="contained" disabled style={{ margin: "8px" }}>
                  That's the last one we got.
                </Button>
              )}
            </div>
          )}
          {whatsAppLeadsData.length > 0 &&
            whatsAppLeadsData?.map((item, index) => {
              let isReceiver = item.message_from === selectedLead?.id;
              return (
                <Fragment key={`${item.id}-${index}`}>
                  {msToDateString(item.created_on) !== lastDate && (
                    <div className="conversation-created-on">
                      <span className="conversation-created-on-text">
                        {msToDateString(item.created_on)}
                      </span>
                      <Divider className="conversation-created-on-divider" />
                    </div>
                  )}
                  <MessageBoxWrapper
                    isWhatsapp
                    item={item}
                    isReceiver={isReceiver}
                    hideUser={
                      item.unique_field_value === lastUser &&
                      msToDateString(item.created_on) === lastDate &&
                      item.form_id === lastForm
                    }
                    hideTime={msToTimeString(item.created_on) === lastTime}
                    isModeratedChat={selectedLead?.is_moderated_chat}
                    sessionTimeLeft={selectedLead?.session_time_left}
                  />
                  {/* eslint-disable-next-line */}
                  {(lastUser = item.unique_field_value) ? "" : ""}
                  {/* eslint-disable-next-line */}
                  {(lastForm = item.form_id) ? "" : ""}
                  {/* eslint-disable-next-line */}
                  {(lastDate = msToDateString(item.created_on)) ? "" : ""}
                  {/* eslint-disable-next-line */}
                  {(lastTime = msToTimeString(item.created_on)) ? "" : ""}
                </Fragment>
              );
            })}
          <div ref={messagesEndRef} className="lead-last-cta-click">
            {/* {clickData &&
              "Last CTA: " +
                underscoreToSpacedCamel(clickData.clicked_on) +
                " on " +
                underscoreToSpacedCamel(clickData.app_panel) +
                " at " +
                msToShortDateString(clickData.created_on)} */}
          </div>
        </div>
        <SendWhatsAppMessage
          id={id}
          leadData={whatsAppLeadsData}
          setLeadData={setWhatsAppLeadsData}
          selectedLead={selectedLead}
          userNumber={
            selectedLead?.unique_field_value ||
            selectedLead?.mobile_number ||
            ""
          }
          sessionTimeLeft={selectedLead?.session_time_left}
          checkHandoverStatus={checkHandoverStatus}
          handoverWhatsApp={(status) => handoverWhatsApp(status)}
          handleScrollStable={(dir) => {
            handleScrollStable(dir);
          }}
        />
        .
      </div>
    </div>
  );
}

export default WhatsAppLeadDetails;
