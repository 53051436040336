export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const underscoreToSpacedCamel = (text) => {
  if (!text) {
    return text;
  }
  return capitalize(text.replace(/_/g, " "));
};

export function underscoreToCamel(text) {
  if (!text) {
    return text;
  }
  return capitalize(text.replace(/_/g, ""));
}

export function isPhoneNumber(text) {
  if (!text) {
    return text;
  }
  text = text.replace(/[.\s-]/g, "");

  // eslint-disable-next-line
  return RegExp(
    // eslint-disable-next-line
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,16}$/i
  ).test(text);
}
