// const BASE_URL = "https://platform.saleassist.ai";
// const BASE_URL = "https://platform.saleassist.ai";
const BASE_URL = "https://platform.saleassist.ai";

// const SOCKET_URL = "https://sockets.saleassist.ai";
const SOCKET_URL = "https://sockets.saleassist.ai";

const JUMP_URL = "https://jump.saleassist.ai";

const url = {
  BASE: BASE_URL,
  SOCKET_URL: SOCKET_URL,
  SOURCE: BASE_URL + "/source",
  SOURCES: BASE_URL + "/sources",
  ACTIONS: BASE_URL + "/actions",
  USERS: BASE_URL + "/users",
  CLIENTS: BASE_URL + "/clients",
  MEDIA: BASE_URL + "/media",
  MEETINGS: BASE_URL + "/meetings",
  FORMS: BASE_URL + "/forms",
  CONVERSATIONS: BASE_URL + "/conversations",
  JUMP_SOURCE: JUMP_URL + "/source",
  JUMP_SOURCE_LANDING: JUMP_URL + "/jump",
  PEOPLE: BASE_URL + "/people",
  NOTIFICATIONS: BASE_URL + "/notifications",
  CLIENT_USER_MAPPINGS: BASE_URL + "/client_user_mappings",
  TRACKER: BASE_URL + "/tracker",
  PRODUCTS: BASE_URL + "/products",
  CHANNELS: BASE_URL + "/channels",
  TO_DO: BASE_URL + "/to-do"
};

const dev = {
  url: url,
  landing_page: "/",
  default_meeting_domain: "localhost:3001",
  razorpay_key_id: "rzp_test_6qMDYgeHkcDSNX",
};

const prod = {
  url: url,
  landing_page: "/",
  default_meeting_domain: "meetings.saleassist.ai",
  razorpay_key_id: "rzp_live_8JXSK0ZDSlsNqI",
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
