import React, { useState, useEffect } from "react";

function countdown(targetTimeString) {
  const currentTime = new Date();

  // Parse the target time string
  const [hours, minutes, period] = targetTimeString.split(/[:\s]+/);
  let targetHours = parseInt(hours, 10);

  if (period.toUpperCase() === "PM" && targetHours < 12) {
    targetHours += 12;
  } else if (period.toUpperCase() === "AM" && targetHours === 12) {
    targetHours = 0;
  }

  const targetTime = new Date();
  targetTime.setHours(targetHours, parseInt(minutes, 10), 0, 0);

  // Calculate the time difference
  let timeDifference = targetTime - currentTime;

  if (timeDifference <= 0) {
    // If the time has passed, calculate how much time has passed
    timeDifference = Math.abs(timeDifference);
    const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesPassed = Math.ceil((timeDifference % (1000 * 60 * 60)) / (1000 * 60)) % 60;

    return {
      expired: true,
      hours: hoursPassed,
      minutes: minutesPassed,
    };
  }

  const hoursRemaining = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutesRemaining = Math.ceil((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  return {
    expired: false,
    hours: hoursRemaining,
    minutes: minutesRemaining,
  };
}

export default function CountdownTimer({ targetTime, customText }) {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const remaining = countdown(targetTime);

      setTimeRemaining(remaining);
      if (remaining.expired && remaining.hours === 0 && remaining.minutes === 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [targetTime]);

  return (
    <>
      {timeRemaining !== null ? (
        <span>
          {timeRemaining.expired ? (
            <>
              {customText ? customText.expired : "This event occurred: "}
              {timeRemaining.hours > 0 && (
                <>
                  {timeRemaining.hours} {timeRemaining.hours === 1 ? "hr" : "hrs"}{" "}
                  {timeRemaining.minutes > 0 && "and "}
                </>
              )}
              {timeRemaining.minutes > 0 && (
                <>
                  {timeRemaining.minutes} {timeRemaining.minutes === 1 ? "min" : "mins"}{" "}
                </>
              )}
              {" ago"}
            </>
          ) : (
            <>
              {customText ? customText.ongoing : "Time Left: "}
              {timeRemaining.hours > 0 && (
                <>
                  {timeRemaining.hours} {timeRemaining.hours === 1 ? "hr" : "hrs"}{" "}
                  {timeRemaining.minutes > 0 && "and "}
                </>
              )}
              {timeRemaining.minutes > 0 && (
                <>
                  {timeRemaining.minutes} {timeRemaining.minutes === 1 ? "min" : "mins"}
                </>
              )}
            </>
          )}
        </span>
      ) : (
        <span>Calculating countdown...</span>
      )}
    </>
  );
}
