import React, { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { FaWhatsapp } from "react-icons/fa6";
import { postCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { UserContext } from "../../../context/UserContext";
import { ClientContext } from "../../../context/ClientContext";

import "./whatsapp-template.scss";

function WhatsappTemplate({ id, userNumber }) {
  const [isOpen, SetIsOpen] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  // eslint-disable-next-line
  const [selectedTemplateData, setSelectedTemplateData] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [isMedia, setIsMedia] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const [uploadImageError, setUploadImageError] = useState("");
  const [uploadVideoError, setUploadVideoError] = useState("");
  const [templateMediaId, setTemplateMediaId] = useState("");
  const [variableInput, setVariableInput] = useState("");

  const { User } = useContext(UserContext);
  const { Client } = useContext(ClientContext);

  const getWhatsAppTemplate = () => {
    postCall({
      path: `whatsapp/sync_airtel_templates`,
      data: {},
      onSuccess: (response) => {
        setTemplateData(response.data.record);
      },
      onFailure: () => {
        bottomAlert("Unable to load whatsapp template");
      },
    });
  };

  const extractTemplateData = (templateName) => {
    return templateData.find(
      (template) => template.templateName === templateName
    );
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file size is not more than 5MB
      if (file.size <= 5 * 1024 * 1024) {
        setUploadImageError("");

        const formData = new FormData();
        formData.append("files", file);
        formData.append("sender_number", Client.whatsapp_number);
        formData.append("media_type", "IMAGE");
        formData.append("message_type", "TEMPLATE_MESSAGE");

        postCall({
          path: `whatsapp/add_media`,
          data: formData,
          onSuccess: (response) => {
            setTemplateMediaId(response.data.id);
            bottomAlert("Media attached successfully", "success");
          },
          onFailure: (error) => {
            bottomAlert(
              "Unable to attach media " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          },
        });
      } else {
        setUploadImageError("File size should be less than or equal to 5MB.");
      }
    } else {
      // No file selected
      setUploadImageError("");
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (file) {
      // Check if the file size is not more than 15MB
      if (file.size <= 15 * 1024 * 1024) {
        setUploadVideoError("");

        const formData = new FormData();
        formData.append("files", file);
        formData.append("sender_number", Client.whatsapp_number);
        formData.append("media_type", "VIDEO");
        formData.append("message_type", "TEMPLATE_MESSAGE");

        postCall({
          path: `whatsapp/add_media`,
          data: formData,
          onSuccess: (response) => {
            setTemplateMediaId(response.data.id);
            bottomAlert("Media attached successfully", "success");
          },
          onFailure: (error) => {
            bottomAlert(
              "Unable to attach media" +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          },
        });
      } else {
        setUploadVideoError("File size should be less than or equal to 15MB.");
      }
    } else {
      // No file selected
      setUploadVideoError("");
    }
  };

  const countTemplateVariables = (templateBody) => {
    const matches = templateBody.match(/{{\s*\d+\s*}}/g);
    return matches ? matches.length : 0;
  };

  const handleWhatsApp = (e) => {
    const templateBodyVariablesCount = countTemplateVariables(templateBody);
    let enteredVariables = [];
    if (templateBodyVariablesCount === 0) {
      enteredVariables = variableInput
        .split(",")
        .filter((variable) => variable.trim() !== "");
    } else {
      enteredVariables = variableInput.split(",");
    }
    if (enteredVariables.length === templateBodyVariablesCount) {
      if (mediaType === "VIDEO" || mediaType === "IMAGE") {
        postCall({
          path: `whatsapp/send_outbound_message`,
          data: {
            templateId: templateId,
            user_number: userNumber,
            message: {
              variables: enteredVariables,
            },
            body: templateBody,
            meeting_id: "",
            agent_id: User.id,
            mediaAttachment: {
              type: mediaType,
              id: templateMediaId,
            },
            sender_number: Client.whatsapp_number,
            people_id: id,
            conversation_id: "",
          },
          onSuccess: (response) => {
            bottomAlert("Sent Successfully", "success");
          },
          onFailure: (error) => {
            bottomAlert(
              "Unable to sent " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          },
        });
      } else {
        postCall({
          path: `whatsapp/send_outbound_message`,
          data: {
            templateId: templateId,
            user_number: userNumber,
            message: {
              variables: enteredVariables,
            },
            body: templateBody,
            meeting_id: "",
            sender_number: Client.whatsapp_number,
            people_id: id,
            conversation_id: "",
          },
          onSuccess: (response) => {
            bottomAlert("Sent Successfully", "success");
          },
          onFailure: (error) => {
            bottomAlert(
              "Unable to sent " +
                ((error.response && error.response.data.detail) ||
                  error.toString()),
              "warning"
            );
          },
        });
      }
    } else {
      bottomAlert(
        "Invalid number of variables. Please enter the correct number of variables.",
        "warning"
      );
    }
  };

  return (
    <>
      <Tooltip title="Template" arrow>
        <label htmlFor="whats-app-template">
          <span
            style={{ display: "flex" }}
            onClick={() => {
              SetIsOpen(true);
              getWhatsAppTemplate();
            }}
          >
            <FaWhatsapp size={20} />
          </span>
        </label>
      </Tooltip>

      <Dialog
        className="wa-template-dialog-container"
        open={isOpen}
        fullWidth
        maxWidth="lg"
        onClose={() => SetIsOpen(false)}
      >
        <div className="wa-template-header-container">
          <DialogTitle>
            <FaWhatsapp size={20} /> Template
          </DialogTitle>
          <span>
            <Button
              variant="contained"
              onClick={() => handleWhatsApp()}
              color="success"
              size="small"
              disabled={
                uploadVideoError.length > 0 || uploadImageError.length > 0
              }
            >
              Send
            </Button>
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => SetIsOpen(false)}
            >
              Close
            </Button>
          </span>
        </div>
        <div className="wa-template-content-container">
          <div className="edit-option-container">
            <DialogContent>
              <FormGroup>
                <label>Template</label>
                <Select
                  size="small"
                  value={selectedTemplate}
                  onChange={(event) => {
                    const selectedTemplateData = extractTemplateData(
                      event.target.value
                    );

                    if (selectedTemplateData) {
                      setSelectedTemplate(event.target.value);
                      setTemplateId(selectedTemplateData.templateId);
                      setSelectedTemplateData(selectedTemplateData);
                      setTemplateBody(selectedTemplateData.body);

                      if (selectedTemplateData.type.includes("MEDIA")) {
                        setIsMedia(true);
                        setMediaType(selectedTemplateData.mediaType);
                      } else {
                        setIsMedia(false);
                        setMediaType("");
                      }
                    }
                  }}
                >
                  {templateData.map((template) => (
                    <MenuItem value={template.templateName}>
                      {template.templateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormGroup>
              <FormGroup>
                <label>Body</label>
                <textarea
                  type="textarea"
                  id="msg"
                  value={templateBody}
                  style={{
                    height: "150px",
                    background: "#e9ecef",
                    padding: "10px 20px",
                  }}
                  disabled
                  readOnly={true}
                ></textarea>
                {isMedia && (
                  <>
                    {mediaType === "IMAGE" && (
                      <div>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                        {uploadImageError && (
                          <p style={{ color: "red" }}>{uploadImageError}</p>
                        )}
                      </div>
                    )}
                    {mediaType === "VIDEO" && (
                      <>
                        <div>
                          <input
                            type="file"
                            accept="video/mp4"
                            onChange={handleVideoUpload}
                          />
                          {uploadVideoError && (
                            <p style={{ color: "red" }}>{uploadVideoError}</p>
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              </FormGroup>
              <FormGroup>
                <label>
                  Variables(
                  <span>
                    Add {","} to add a variable.For example:
                    {"test1,test2"}
                  </span>
                  )
                </label>
                <input
                  type="text"
                  value={variableInput}
                  onChange={(e) => setVariableInput(e.target.value)}
                  placeholder="Enter comma-separated variables"
                />
              </FormGroup>
            </DialogContent>
          </div>
          <div className="preview-container">
            <div className="wa-template-smartphone">
              <div className="content">
                {templateBody === "" ? (
                  <></>
                ) : (
                  <pre className="whatsapp-message-style">{templateBody}</pre>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default WhatsappTemplate;
