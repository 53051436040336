import React from 'react'

function SendIconButton() {
    return (
        <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 512 512"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            >
            <path d="M16 464l480-208L16 48v160l320 48-320 48z"></path>
        </svg>
    )
}

export default SendIconButton
