import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { FiEdit } from "react-icons/fi";
import { config } from "../../config";
import { getCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";
import DeleteConfirmationMenu from "./deleteConfirmationMenu";
import AddTaskSection from "./AddTaskSection";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import MarkAsDoneConfirmationMenu from "./markAsDoneConfirmationMenu";
import { BsDatabase } from "react-icons/bs";
import { Button } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { BsFillTrash3Fill } from "react-icons/bs"
import { NavLink } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { MdUnarchive } from "react-icons/md";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));



const ToDoTable = () => {
  const [tasksArray, setTasksArray] = useState([]);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
  const [refreshTaskList, setRefreshTaskList] = useState(false);
  const [showAddTaskSection, setShowAddTaskSection] = useState(true);
  const [itemToBeUpdated, setItemToBeUpdated] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [markedItem, setMarkedItem] = useState(null);
  const [priority_filter, set_priority_filter] = useState('all');
  const [overDueTask, setOverDueTask] = useState('all');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setShowAddTaskSection(true);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setItemToBeUpdated(null)
  };

  const itemsPerPage = 10;

  const maxWidth__576 = window.matchMedia("(max-width: 576px)").matches;

  const getData = () => {

    getCall({
      url: config.url.TO_DO,
      path: `/${priority_filter}/${currentPage}/${itemsPerPage}?status=SCHEDULED`,

      onSuccess: (response) => {
        setTasksArray(response.data.records);
        setTotalItems(response.data.count);
        // bottomAlert("Task table updated", "success");
      },
      onFailure: (error) => {
        bottomAlert("Todo Tasks loading failed", "warning");
      },
    });
  };

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [refreshTaskList]);

  const handleTaskDelete = (event, taskId) => {
    setItemToBeDeleted(taskId);
    setDeleteAnchorEl(event.currentTarget);
    setItemToBeUpdated(null);
    setShowAddTaskSection(false);
  };

  const handleTaskUpdate = (item) => {
    setOpen(true);
    setItemToBeUpdated(item);
    setShowAddTaskSection(true);
  };

  const handleLoadMore = (action) => {
    action === "inc"
      ? setCurrentPage(currentPage + 1)
      : setCurrentPage(currentPage - 1);
    setRefreshTaskList(!refreshTaskList);
  };

  const curDateColor = (dateString) => {
    if (!dateString) return false;
    const dueDate = new Date(dateString);
    const currentDate = new Date();
    return dueDate <= currentDate;
  };

  const handleMarkAsDone = (item) => {
    console.log(item, 'item')
    setMarkedItem(item);
    setItemToBeUpdated(null);
    setShowAddTaskSection(false);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const options = {
      day: '2-digit',
      month: 'short',
      year: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    return date.toLocaleString('en-GB', options);
  }

  const handleOverDueTask = (e) => {
    const dueVal = e.target.value;
    setOverDueTask(dueVal);
  
    if(dueVal==='all'){
      getData()
    }else{
      const today = new Date();
      today.setHours(0, 0, 0, 0); 
  
      const allDueDateData = tasksArray.filter((item) => {
        if (item.reminder_time) {
          const taskDate = new Date(item.reminder_time);
          taskDate.setHours(0, 0, 0, 0); 
          return taskDate < today; 
        }
        return false;
      });
  
      console.log(allDueDateData, 'Overdue tasks');
      setTasksArray(allDueDateData);
    }
    };

  return (
    <>

      <React.Fragment>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" style={{ padding: "10px" }}>
            Add To-Do
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <IoClose />
          </IconButton>
          <DialogContent dividers style={{ padding: "10px" }}>
            {Boolean(showAddTaskSection) && (
              <AddTaskSection
                setShowAddTaskSection={setShowAddTaskSection}
                handleClose={handleClose}
                refreshTaskList={refreshTaskList}
                setRefreshTaskList={setRefreshTaskList}
                setItemToBeUpdated={setItemToBeUpdated}
                itemToBeUpdated={itemToBeUpdated}
              />
            )}
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>

      {Boolean(deleteAnchorEl) && (
        <DeleteConfirmationMenu
          deleteAnchorEl={deleteAnchorEl}
          itemToBeDeleted={itemToBeDeleted}
          closeDeleteConfirmationMenu={() => {
            setItemToBeDeleted(null);
            setDeleteAnchorEl(null);
          }}
          refreshTaskList={refreshTaskList}
          setRefreshTaskList={setRefreshTaskList}
        />
      )}
      {Boolean(markedItem) && (
        <MarkAsDoneConfirmationMenu
          markedItem={markedItem}
          closeMarkAsDoneConfirmationMenu={() => {
            setMarkedItem(null);
          }}
          refreshTaskList={refreshTaskList}
          setRefreshTaskList={setRefreshTaskList}
        />
      )}
      <section className="toDoMainCard" style={{ padding: "0px 5px", }}>
        <section className="lg-mb-0 todoMainSection" style={{ padding: maxWidth__576 ? "0px 5px" : "0px 20px" }}>
          <div className="todoMainHeading">
            <h6 style={{ fontSize: "20px", fontWeight: "600", display: 'flex', alignItems: 'center', margin: "0px" }}>
              To-Do List
            </h6>
          </div>
          <div
            className="todoMainHeading" style={{ borderBottom: " 1px solid #ddd", paddingBottom: " 15px" }}>
            {/* <h6 style={{ fontSize: "18px", fontWeight: "500", display: 'flex', alignItems: 'center', margin: "0px" }}>
              <BsDatabase style={{ marginRight: "5px", color: "#808080" }} />
              All Task
            </h6> */}
            <div style={{display:"flex", alignItems: "center"}}>
            <BsDatabase style={{ marginRight: "5px", color: "#808080", width: "25px", height:" 25px" }} />
            <select
              size="sm"
              type="select"
              value={overDueTask}
              name="overDueTask_dropdown"
              onChange={(e) =>handleOverDueTask(e)}
            >
              <option value="all">All Task</option>
              <option value="overdue" style={{ fontWeight: "600"}}>Overdue </option>
            </select>
              </div>
          
           

            <select
              size="sm"
              type="select"
              value={priority_filter}
              name="priority_dropdown"
              onChange={(e) => {
                set_priority_filter(e.target.value);
                setCurrentPage(1);
                setRefreshTaskList(!refreshTaskList)
              }}
            >
              <option value="all">Priority</option>
              <option value="low" style={{ fontWeight: "600", color: "#63c05b" }}>Low </option>
              <option value="medium" style={{ fontWeight: "600", color: "#18b0ff" }}>Medium </option>
              <option value="high" style={{ fontWeight: "600", color: "#ff2473" }}>High </option>
            </select>

          </div>

          {tasksArray.length === 0 && <p style={{ color: "rgba(153, 136, 111, 0.85)", fontWeight: '700px', textAlign: 'center' }}>No tasks found, <br />Plz add tasks to show here!</p>}

          <div className="todoMainList">
            <ul>
              {tasksArray.length !== 0 &&
                tasksArray?.map((curItem, index) => {
                  let priorityStyle;
                  switch (curItem.priority_level) {
                    case "LOW":
                      priorityStyle = { borderColor: "#63c05b", backgroundColor: "#63c05b35" };
                      break;
                    case "MEDIUM":
                      priorityStyle = { borderColor: "#18b0ff", backgroundColor: "#18b0ff35" };
                      break;
                    case "HIGH":
                      priorityStyle = { borderColor: "#ff2473", backgroundColor: "#ff247335" };
                      break;
                    default:
                      priorityStyle = {};
                  }

                  return (
                    <li>
                      <div className="listChckBox">
                        <span style={priorityStyle}></span>
                      </div>
                      <div className="listItems">
                        <h5>{curItem.name}
                          {curItem.lead && 
                            <NavLink to={`/meetings/leads/${curItem.lead}`} end>

                            {/* {curItem.lead.length > 10
                              ? curItem.lead.slice(0, 9) + "..."
                              : curItem.lead} */}
                          View Leads <GrView />
                          
                          </NavLink> } </h5>
                        <p>{curItem.notes}</p>
                        <div className="actionBox">
                          <span style={{ color: curDateColor(curItem.reminder_time) ? "#de4a3c" : "#999",
                          }}
                          >{curItem.reminder_time ? formatDate(curItem.reminder_time) : 'N/A'}</span>
                          <div className="actionIcon">
                            <FaCheck style={{ fill: "#1976d2" }} onClick={() => handleMarkAsDone(curItem)} />
                            <FiEdit style={{ color: "#13a3bc" }} onClick={() => handleTaskUpdate(curItem)} />
                            <BsFillTrash3Fill style={{ fill: "#de4a3c" }}
                              onClick={(event) => handleTaskDelete(event, curItem.id)} />
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                }
                )}
            </ul>
          </div>

          <div
            style={{
              display: "flex",
              columnGap: "10px",
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingBottom: "10px",
              justifyContent: 'end',
              marginTop: "10px"
            }}
          >
            {currentPage > 1 && (
              <Button
                variant="outlined"
                color="primary"
                outline
                onClick={() => handleLoadMore("dec")}
              >
                <IoIosArrowBack />
              </Button>
            )}
            {currentPage <= totalItems / itemsPerPage && (
              <Button
                variant="outlined"
                color="primary"
                outline
                onClick={() => handleLoadMore("inc")}
              >
                <IoIosArrowForward />
              </Button>
            )}
          </div>
          <Box className="addBtn"
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: '50%',
                width: "30px !important",
                height: "30px !important",
                fontSize: '20px',
                minWidth: "unset",
                backgroundColor: "#000"
              }}
              onClick={handleClickOpen}
            >
              +
            </Button>

            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: '50%',
                width: "30px !important",
                height: "30px !important",
                fontSize: '20px',
                minWidth: "unset",
                marginLeft:"10px",
                padding:"7px"
                // backgroundColor: "#000"
              }}
              // onClick={handleClickOpen}
            >
              <MdUnarchive />
            </Button>
          </Box> 
        </section>
      </section>
    </>
  );
};
export default ToDoTable;
