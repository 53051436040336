import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../config";
import { postCall } from "../../utils/methods";
import { bottomAlert } from "../../utils/toastAlerts";
import { Bars } from "react-loader-spinner";
import "./meeting-invite.scss";

function MeetingInvite() {
  let { id } = useParams();
  const navigate = useNavigate();

  const acceptIncomingInvite = () => {
    postCall({
      url: config.url.BASE,
      path: `/meetings/${id}/accept_invite`,
      data: {},
      onSuccess: (response) => {
        if (response.data.people_id) {
          navigate(`/meetings/people/${response.data.meeting_id}`);
        } else {
          navigate(`/meetings/live/${response.data.meeting_id}`);
        }
      },
      onFailure: (error) => {
        bottomAlert(
          "Unable to fetch the data " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "error"
        );
      },
    });
  };

  useEffect(() => {
    acceptIncomingInvite();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <div className="saleassist-meeting-lite-loader-wrapper">
      <Bars
        height="60"
        width="60"
        color="#959595"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      Fetching meeting invite
    </div>
  );
}

export default MeetingInvite;
