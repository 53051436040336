import React from 'react';
import ReactDOM from "react-dom";
import DeviceAccessError from "../../components/Layouts/DeviceAccessError";

export const alertMessage = (errorType) => {
    const videoCallContainerEl = document.getElementById("videoCallContainer");

    // alert message for deivce access error
    const deviceAccessErrorLayout = document.createElement("div");
    ReactDOM.render(<DeviceAccessError error_type={errorType} />, deviceAccessErrorLayout);
    videoCallContainerEl.appendChild(deviceAccessErrorLayout);

    const closeButton = deviceAccessErrorLayout.querySelector(".sa-device-access-error-dismiss-btn");
    closeButton.addEventListener("click", () => {
        videoCallContainerEl.removeChild(deviceAccessErrorLayout);
    });
}