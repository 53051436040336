export async function flipCamera(el) {
  if (this.videoInputDevices.length > 1) {
    let currentCamera;

    //   Flip the camera source
    if (this.videoInputDevices[0].label === this.localVideoTrack._deviceName) {
      // eslint-disable-next-line
      this.videoInputDevices.map((device, index) => {
        if (
          device.label.toLowerCase().includes("back") ||
          device.label.toLowerCase().includes("rear")
        ) {
          currentCamera = this.videoInputDevices[index];
          el.selectedIndex = index;
        }
      });
    } else {
      currentCamera = this.videoInputDevices[0];
      el.selectedIndex = 0;
    }

    await this.localVideoTrack.setDevice(currentCamera.deviceId);

    // to remove the mirrored effect for the local user when switched to back/rear camera
    if (
      currentCamera.label.toLowerCase().includes("back") ||
      currentCamera.label.toLowerCase().includes("rear")
    ) {
      const videoElement = document.querySelector("#localVideoContainer video");
      videoElement.style.transform = "rotateY(0deg)";
    }
  }
}
