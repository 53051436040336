import { createContext, useState } from "react";

// Note: This context API should only be used with FormBuilder and not with custom forms.

export const FormContext = createContext({
  setFormData: (id, form) => {},
  getFormData: (id) => {},
});

export const FormProvider = ({ children }) => {
  const [form, setForm] = useState({});

  const setFormData = (id, form) => {
    setForm((prev) => {
      return {
        ...prev,
        [id]: form,
      };
    });
  };

  const getFormData = (id) => {
    try {
      return form[id]?.formData;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <FormContext.Provider value={{ setFormData, getFormData }}>
      {children}
    </FormContext.Provider>
  );
};
