import React from "react";
import ReactDOM from "react-dom";
import CameraOffButton from "../../components/Buttons/camera-off";
import CameraOnButton from "../../components/Buttons/camera-on";
import { alertMessage } from "./alertMessage";

export async function toggleCamera(e, AgoraRTC) {
  if (this.localVideoTrack) {
    const localUserPlaceholderEl = document.getElementById(
      "localUserPlaceholder"
    );

    if (!this.localVideoTrackMuted) {
      await this.localVideoTrack.setMuted(true);
      this.localVideoTrackMuted = true;
      e.style.background = "#eb271e"; //red
      ReactDOM.render(<CameraOffButton />, e);
      localUserPlaceholderEl.style.display = "flex"; // local user's placeholder is visible when camera is off
    } else {
      await this.localVideoTrack.setMuted(false);
      this.localVideoTrackMuted = false;
      e.style.background = "#3f3f41";
      ReactDOM.render(<CameraOnButton />, e);
      localUserPlaceholderEl.style.display = "none"; // local user's placeholder is invisible when camera is on
    }

    // local user's placeholder set to none if screen share enabled
    if (this.isSharingEnabled) {
      localUserPlaceholderEl.style.display = "none";
    }
  } else {
    try {
      this.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
      this.localVideoTrack.play("localVideoContainer"); // Play the local video track
      await this.client.publish([this.localAudioTrack, this.localVideoTrack]); // Publish local tracks to the channel

      this.localVideoTrackMuted = false;
      e.style.background = "#3f3f41";
      ReactDOM.render(<CameraOnButton />, e);
    } catch (error) {
      if (error.message.includes("NotReadableError")) {
        alertMessage("NotReadableError");
      } else if (error.message.includes("NotAllowedError")) {
        alertMessage("NotAllowedError");
      } else {
        alertMessage("Camera Error~" + error.message);
        console.error("Camera Error:", error.message);
      }
    }
  }
}
