import React from "react";
import { BsPersonVideo } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { MdDialpad } from "react-icons/md";
import { LuCalendarPlus } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import "./quick-action-view.scss";

function QuickActionView({ handleAction }) {
  const navigate = useNavigate();

  const handleQuickAction = (path) => {
    handleAction();
    navigate(path);
  };

  return (
    <div className="saleassist-action-overlay-container">
      <div className="quick-action-button-layout">
        <div
          className="quick-action-button-wrapper pos1"
          onClick={() => handleQuickAction("/meetings/new/conference")}
        >
          <span className="quick-action-button">
            <span>
              <BsPersonVideo />
            </span>
          </span>
          <span className="quick-action-button-text">New Meeting</span>
        </div>

        <div
          className="quick-action-button-wrapper pos2"
          onClick={() => handleQuickAction("/meetings/new/live-streaming")}
        >
          <span className="quick-action-button">
            <span>
              <CiStreamOn style={{ strokeWidth: 1 }} />
            </span>
          </span>
          <span className="quick-action-button-text">Live Stream</span>
        </div>

        <div
          className="quick-action-button-wrapper pos3"
          onClick={() => handleQuickAction("/outbounds")}
        >
          <span className="quick-action-button">
            <span>
              <MdDialpad />
            </span>
          </span>
          <span className="quick-action-button-text">Phone Call</span>
        </div>

        <div
          className="quick-action-button-wrapper pos4"
          onClick={() => handleQuickAction("/meetings/new/schedule")}
        >
          <span className="quick-action-button">
            <span>
              <LuCalendarPlus />
            </span>
          </span>
          <span className="quick-action-button-text">Schedule Call</span>
        </div>
      </div>
    </div>
  );
}

export default QuickActionView;
