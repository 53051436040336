import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { config } from "../../../config";
import { uploadFiles } from "../../../utils/methods";
import CloseIconButton from "../../components/Buttons/close-icon";
import DownloadIconButton from "../../components/Buttons/download-icon";
import FileIconButton from "../../components/Buttons/file-icon";
import ScreenshotIcon from "../../components/Buttons/screenshot-icon";
import html2canvas from "html2canvas";

function getCurrentTime() {
  const now = new Date();
  const hours = now.getHours() > 12 ? now.getHours() - 12 : now.getHours();
  const ampm = now.getHours() >= 12 ? "PM" : "AM";
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes} ${ampm}`;
}

export function chatBox(action) {
  const chatContainerEl = document.querySelector(
    ".saleassist-video-call-chat-container"
  );

  const unreadMessageEl = document.querySelectorAll(
    ".saleassist-video-call-chat-log .unread"
  );

  const messageNotification = document.querySelectorAll(
    ".sa-message-notification"
  );

  if (action === "open") {
    const moreOptionsControlContEl = document.querySelector(
      ".saleassist-video-call-more-options-control"
    );

    chatContainerEl.classList.remove("sa-chat-hidden");
    chatContainerEl.classList.add("sa-chat-active");

    if (unreadMessageEl) {
      unreadMessageEl.forEach((msg) => msg.classList.remove("unread"));
    }

    if (messageNotification) {
      messageNotification.forEach((msg) => {
        msg.style.display = "none";
        msg.innerText = "";
      });
    }
    moreOptionsControlContEl.classList.remove("more-options-control-active");
  }
  if (action === "close") {
    chatContainerEl.classList.remove("sa-chat-active");
    chatContainerEl.classList.add("sa-chat-hidden");
  }
}

export function sendMessage() {
  const ChatMessageInputEl = document.getElementById(
    "saleassistVideoChatMessageInput"
  );
  const documentPreviewContainerEl = document.querySelector(
    ".saleassist-video-call-document-preview-container"
  );

  let message = ChatMessageInputEl.value;

  if (this.files[0]) {
    const overlayEl = document.querySelector(
      ".saleassist-video-call-chat-input-box .chat-input-overlay"
    );

    overlayEl.style.display = "flex";

    uploadFiles({
      url: config.url.MEDIA,
      files: this.files,
      onSuccess: (response) => {
        this.files.forEach((file, index) => {
          const downloadIconContainer = document.createElement("span");
          ReactDOM.render(<DownloadIconButton />, downloadIconContainer);

          message += `
            <a class="sa-chat-document-preview-sent" href="${
              response.data[index].url
            }" target=”_blank”>
              <span>${ReactDOMServer.renderToString(
                <DownloadIconButton />
              )}</span>
              <span>${file.name}</span>
            </a>`;
        });

        if (this.meeting_data.people_id) {
          this.socket.emit("chat_message", {
            text: message,
            people_id: this.meeting_data.people_id,
            userName: this.user_display_name,
          });
        }

        if (this.meeting_data.id) {
          this.socket.emit("chat_message", {
            text: message,
            meeting_id: this.meeting_data.id,
            userName: this.user_display_name,
          });
        }

        appendMessage("You", message, true);
        this.files = [];
        ChatMessageInputEl.value = "";
        documentPreviewContainerEl.innerHTML = "";
        overlayEl.style.display = "none";
      },
      onFailure: (error) => {
        this.files = [];
        overlayEl.style.display = "none";
        console.warn(
          "Unable to Send the document. " +
            ((error.response && error.response.data.detail) || error.toString())
        );
      },
    });
  }

  if (message.trim() !== "" && !this.files[0]) {
    // console.log("userName", this.user_display_name);

    if (this.meeting_data.people_id) {
      this.socket.emit("chat_message", {
        text: message,
        people_id: this.meeting_data.people_id,
        userName: this.user_display_name,
      });
    } 
    
    if (this.meeting_data.id) {
      this.socket.emit("chat_message", {
        text: message,
        meeting_id: this.meeting_data.id,
        userName: this.user_display_name,
      });
    }

    appendMessage("You", message, true);
    ChatMessageInputEl.value = "";
  }
}

export function appendMessage(sender, message, isSent) {
  const chatLog = document.getElementById("saleassistVideoChatLog");
  const messageContainerEl = document.createElement("div");
  const messageElement = document.createElement("div");
  const infoElement = document.createElement("div");
  let count =
    document.querySelector(".sa-message-notification")?.innerText || 0;

  if (isSent) {
    messageContainerEl.classList.add("sent");
  } else {
    messageContainerEl.classList.add("received");
    if (document.querySelector(".sa-chat-hidden")) {
      messageContainerEl.classList.add("unread");
      count = +count + 1;
      document
        .querySelectorAll(".sa-message-notification")
        .forEach((el) => (el.innerText = count));
    }
  }

  messageElement.classList.add("message");
  infoElement.classList.add("message-meta-info");

  messageElement.innerHTML = message;

  let infoData = `${!isSent ? `${sender} | ` : ""}${getCurrentTime()}`;

  if (chatLog?.lastChild) {
    if (
      (isSent &&
        chatLog.lastChild.className === "sent" &&
        chatLog.lastChild.outerText.includes(infoData)) ||
      (!isSent &&
        chatLog.lastChild.className === "received" &&
        chatLog.lastChild.outerText.includes(infoData))
    ) {
      chatLog.lastChild.lastChild.innerHTML = "";
    }
  }

  infoElement.innerHTML = infoData;

  messageContainerEl.appendChild(messageElement);
  messageContainerEl.appendChild(infoElement);
  chatLog.appendChild(messageContainerEl);
  chatLog.scrollTop = chatLog.scrollHeight;
}

export function receiveMessage(sender, message) {
  appendMessage(sender, message, false);
}

function removeDocument(file_name) {
  const documentEl = document.getElementById(`${file_name}`);

  const fileIndex = this.files.findIndex((file) => file.name === file_name);
  if (fileIndex !== -1) {
    this.files.splice(fileIndex, 1);
  }

  documentEl.remove();
}

export function onContentUpload(event, type = "file") {
  const documentPreviewContainerEl = document.querySelector(
    ".saleassist-video-call-document-preview-container"
  );

  if (type === "file" && event.target.files && event.target.files.length > 0) {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);

      const previewDiv = document.createElement("div");
      previewDiv.className = "sa-chat-document-preview";
      previewDiv.id = `${files[i].name}`;

      const container = document.createElement("div");

      ReactDOM.render(
        <React.Fragment>
          <span className="sa-chat-document-file-name">
            <FileIconButton /> {files[i].name}
          </span>
          <span className="sa-chat-remove-document-button">
            <CloseIconButton />
          </span>
        </React.Fragment>,
        container
      );

      while (container.firstChild) {
        previewDiv.appendChild(container.firstChild);
      }

      documentPreviewContainerEl.appendChild(previewDiv);

      const removeButton = previewDiv.querySelector(
        ".sa-chat-remove-document-button"
      );
      removeButton.addEventListener("click", () => {
        removeDocument.call(this, previewDiv.id);
      });
    }
  }

  if (type === "screenshot") {
    const file = event;
    this.files.push(file);

    const previewDiv = document.createElement("div");
    previewDiv.className = "sa-chat-document-preview";
    previewDiv.id = `${file.name}`;

    const container = document.createElement("div");

    ReactDOM.render(
      <React.Fragment>
        <span className="sa-chat-document-file-name">
          <ScreenshotIcon /> {file.name}
        </span>
        <span className="sa-chat-remove-document-button">
          <CloseIconButton />
        </span>
      </React.Fragment>,
      container
    );

    while (container.firstChild) {
      previewDiv.appendChild(container.firstChild);
    }

    documentPreviewContainerEl.appendChild(previewDiv);

    const removeButton = previewDiv.querySelector(
      ".sa-chat-remove-document-button"
    );
    removeButton.addEventListener("click", () => {
      removeDocument.call(this, previewDiv.id);
    });
  }
}

export function onScreenShotUpload() {
  const self = this;
  html2canvas(document.querySelector("#videoCallMainScreen")).then((canvas) => {
    const now = new Date();

    const formattedDate = formatDate(now);
    const formattedTime = formatTime(now);

    canvas.toBlob(function (blob) {
      let fileObj = new File(
        [blob],
        `Screenshot_${formattedDate}_${formattedTime}.png`,
        { type: "image/png" }
      );
      onContentUpload.call(self, fileObj, "screenshot");
    }, "image/png");
  });
}

function formatDate(date) {
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
}

function formatTime(date) {
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${hours}-${minutes}-${seconds}`;
}
