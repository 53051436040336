import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { HiHome } from "react-icons/hi2";
import { IoMdCalendar } from "react-icons/io";
import { MdGroupAdd, MdDashboard } from "react-icons/md";
import { SocketContext } from "../../context/SocketContext";
import { NotificationContext } from "../../context/NotificationContext";
import QuickActionView from "../QuickActionView/QuickActionView";

import "./footer-navigation.scss";

function FooterNavigation() {
  const location = useLocation();
  const { Socket, setSocket } = useContext(SocketContext);
  const { Notification, setNotification } = useContext(NotificationContext);
  const [isActionButtonOpen, setIsActionButtonOpen] = useState(false);

  const sound = new Audio(
    "https://static.saleassist.ai/sounds/wachat-ding.mp3"
  );

  const handleAction = () => {
    setIsActionButtonOpen(!isActionButtonOpen);
  };

  useEffect(() => {
    Socket?.socket?.on("whatsapp_chat_message", async (data) => {
      // console.log("whatsapp_chat_message", data);
      setSocket((prev) => ({ ...prev, last_whatsapp_chat_message: data }));
    });

    return () => {
      Socket?.socket?.removeAllListeners("whatsapp_chat_message");
    };

    // eslint-disable-next-line
  }, [Socket?.socket]);

  useEffect(() => {
    const data = Socket?.last_whatsapp_chat_message;

    const hasUniqueField = (array, value) => {
      if (array) {
        for (const obj of array) {
          if (obj.unique_field_value === value) {
            return false;
          }
        }
      }

      return true;
    };

    if (data && data.conversation.message_to === "") {
      const waNotificationList = Notification
        ? Notification.whatsappNotification
        : [];

      if (
        hasUniqueField(waNotificationList, data.conversation.unique_field_value)
      ) {
        try {
          sound.play();
        } catch (error) {
          console.error("Failed to play audio:", error);
        }

        // adding indication for data recived on socket for whatsapp notification
        data.conversation["notification_type"] = data.message_type;

        setNotification((prev) => ({
          ...prev,
          whatsappNotification: [
            ...(prev?.whatsappNotification ?? []),
            data.conversation,
          ],
        }));
      }
    }

    return () => {};

    // eslint-disable-next-line
  }, [Socket?.last_whatsapp_chat_message]);

  return (
    <>
      {isActionButtonOpen && <QuickActionView handleAction={handleAction} />}

      <footer className="saleassist-footer-nav-lite">
        <span
          className={`action-button ${isActionButtonOpen ? "rotate" : ""}`}
          onClick={handleAction}
        >
          <FaPlus />
        </span>

        <span className="nav-items">
          <NavLink to="/meetings/lite" end>
            <HiHome />
          </NavLink>
          <NavLink to="/meetings/schedule" end>
            <IoMdCalendar />
          </NavLink>
        </span>

        <span className="nav-items">
          <NavLink
            to="/meetings/leads"
            className={`
              ${location.pathname === "/meetings/whatsapp" ? "active" : ""} 
              ${
                Notification?.whatsappNotification?.length > 0
                  ? "notification"
                  : ""
              }
            `}
            end
          >
            <MdGroupAdd />
          </NavLink>
          <NavLink to="/utilities" end>
            <MdDashboard />
          </NavLink>
        </span>
      </footer>
    </>
  );
}

export default FooterNavigation;
