import React from "react";
import ReactDOM from "react-dom";
import { config } from "../../../config";
import { postCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { ColorRing } from "react-loader-spinner";

export function recordCall(el) {
  var temp = el.innerHTML;
  if (this.isRecording === false) {
    el.disabled = true;
    ReactDOM.render(
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
      />,
      el
    );
    postCall({
      url: config.url.MEETINGS,
      path: `/start_recording/${this.meeting_data.id}`,
      onSuccess: (response) => {
        el.style.color = "#ff0000";
        el.innerHTML = temp;
        bottomAlert("Recording has been initiated!", "success");
        this.isRecording = true;
        el.disabled = false;
      },
      onFailure: (error) => {
        el.disabled = false;
        console.log("error---", error.response);
        bottomAlert(
          "Unable to start the record. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  } else {
    el.disabled = true;
    ReactDOM.render(
      <ColorRing
        visible={true}
        height="80"
        width="80"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
      />,
      el
    );
    postCall({
      url: config.url.MEETINGS,
      path: `/stop_recording/${this.meeting_data.id}`,
      onSuccess: (response) => {
        el.style.color = "#fff";
        el.innerHTML = temp;
        bottomAlert("Recording has been stopped!", "success");
        this.isRecording = false;
        el.disabled = false;
      },
      onFailure: (error) => {
        el.disabled = false;
        bottomAlert(
          "Unable to stop the record. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
      },
    });
  }
}
