export const renderForm = (formData) => {
  let fields = "";
  // let countryList = "";

  // countries.forEach((country) => {
  //   countryList += `<li
  //     data-country-code=${country.code}
  //     data-country-dial-code=${country.dial_code}
  //     class="sa-live-call-widget-country"
  //   >
  //     ${country.name} ${country.dial_code}
  //   </li>`;
  // });

  const getCustomInput = (item) => {
    // if (item.name === "mobile_number") {
    //   const input = `<div
    //       class="sa-live-call-widget-mobile-number-input-wrapper"
    //     >
    //       <div class="sa-live-call-widget-selected-country-wrapper">
    //         <p class="sa-live-call-widget-selected-country">+91</p>
    //         <div class="sa-live-call-widget-select-country-arrow"></div>
    //       </div>
    //       <input
    //         type="number"
    //         id="${item.name}"
    //         name="${item.name}"
    //         value="${item.value || ""}"
    //         placeholder="${item.placeholder ? item.placeholder : ""}"
    //         ${item.required ? " required " : " "}
    //         class="sa-live-call-widget-lead-capture-form-field sa-live-call-widget-mobile-number-input"
    //       />
    //     </div>
    //     <ul
    //       class="sa-live-call-widget-country-list sa-live-call-widget-d-hidden"
    //     >
    //       ${countryList}
    //     </ul> `;
    //   return input;
    // } else {
    const input = `<input
        type="${item.subtype ? item.subtype : item.type}"
        id="${item.name}"
        name="${item.name}"
        value="${item.value || ""}"
        maxlength="${item.maxlength || ""}"
        placeholder="${item.placeholder ? item.placeholder : ""}"
        ${item.required ? " required " : " "}
        class="sa-live-call-widget-lead-capture-form-field"

        ${
          item.name === "mobile_number"
            ? `oninput="this.value = this.value.replace(/[^+0-9]/g, '')"`
            : ""
        }
        }
      />`;
    return input;
    // }
  };

  formData.forEach((item) => {
    if (item.name) {
      if (item.type === "text") {
        fields += `<div
          class="sa-live-call-widget-lead-capture-form-group"
          id=${item.name === "mobile_number" ? "input-field-number" : ""}
        >
          <label
            for="${item.name}"
            class="sa-live-call-widget-lead-capture-form-field-label"
          >
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </label>
          ${getCustomInput(item)}
        </div>`;
      }
      if (item.type === "number") {
        fields += `<div
          class="sa-live-call-widget-lead-capture-form-group"
        >
          <label
            for="${item.name}"
            class="sa-live-call-widget-lead-capture-form-field-label"
          >
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </label>
          <input
            type="${item.type}"
            id="${item.name}"
            name="${item.name}"
            value="${item.value}"
            min="${item.min || ""}"
            max="${item.max || ""}"
            step="${item.step || ""}"
            placeholder="${item.placeholder ? item.placeholder : ""}"
            ${item.required ? " required " : " "}
            class="sa-live-call-widget-lead-capture-form-field"
          />
        </div>`;
      }
      if (item.type === "textarea") {
        fields += `<div class="sa-live-call-widget-lead-capture-form-group">
          <label
            for="${item.name}"
            class="sa-live-call-widget-lead-capture-form-field-label"
          >
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </label>
          <textarea
            id="${item.name}"
            name="${item.name}"
            placeholder="${item.placeholder ? item.placeholder : ""}"
            rows="${item.rows || ""}"
            maxlength="${item.maxlength || ""}"
            ${item.required ? " required " : " "}
            class="sa-live-call-widget-lead-capture-form-field"
          >
${item.value || ""}</textarea
          >
        </div>`;
      }
      if (item.type === "select") {
        let options = "";
        // eslint-disable-next-line
        item.values?.map((option) => {
          options += `<option value="${option.value}">
            ${option.label}
          </option>`;
        });
        fields += `<div class="sa-live-call-widget-lead-capture-form-group">
          <label
            for="${item.name}"
            class="sa-live-call-widget-lead-capture-form-field-label"
          >
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </label>
          <select
            name="${item.name}"
            id="${item.name}"
            ${item.required ? " required " : " "}
            ${item.multiple ? " multiple " : " "}
            class="sa-live-call-widget-lead-capture-form-field"
          >
            ${options}
          </select>
        </div>`;
      }
      if (item.type === "radio-group") {
        let options = "";
        // eslint-disable-next-line
        item.values?.map((option) => {
          options += `<div>
            <input
              type="radio"
              id="${option.value}"
              name="${item.name}"
              value="${option.value}"
              ${option.selected ? "checked" : ""}
              class="sa-live-call-widget-lead-capture-form-field-radio"
            />
            <label for="${option.value}">${option.label}</label>
          </div>`;
        });
        fields += `<div class="sa-live-call-widget-lead-capture-form-group">
          <p class="sa-live-call-widget-lead-capture-form-field-label">
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </p>
          <div
            class="sa-live-call-widget-lead-capture-form-radio-fields-wrapper"
          >
            ${options}
          </div>
        </div>`;
      }
      if (item.type === "checkbox-group") {
        let options = "";
        // eslint-disable-next-line
        item.values?.map((option) => {
          options += `<div
            class="sa-live-call-widget-lead-capture-form-checkbox-wrapper"
          >
            <input
              type="checkbox"
              id="checkbox-${option.value}"
              name="${item.name}"
              value="${option.value}"
              ${option.selected ? "checked" : ""}
              class="sa-live-call-widget-lead-capture-form-field-checkbox"
            />
            <label
              class="sa-live-call-widget-lead-capture-form-checkbox-field-label"
              for="checkbox-${option.value}"
              >${option.label}</label
            >
          </div>`;
        });
        fields += `<div class="sa-live-call-widget-lead-capture-form-group">
          <p
            class="sa-live-call-widget-lead-capture-form-field-label"
          >
            ${item.label}<span class="sa-form-input-required"
              >${item.required ? " *" : ""}</span
            >
          </p>
         ${options}
        </div>`;
      }
    }
    if (item.type === "hidden") {
      fields += `<div
        class="sa-live-call-widget-lead-capture-form-group"
        style="display: none"
      >
        <input
          type="${item.type}"
          id="${item.name}"
          name="${item.name}"
          value="${item.value || ""}"
          class="sa-live-call-widget-lead-capture-form-field"
        />
      </div>`;
    }
    if (item.type === "paragraph") {
      fields += `<div class="sa-live-call-widget-lead-capture-form-group">
        <p class="sa-live-call-widget-lead-capture-form-field-label">
          ${item.label}
        </p>
      </div>`;
    }
  });

  return fields;
};
