export const getEmailUsernameOrNothing = (inputString) => {
  // Regular expression to check for an email address
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // Regular expression to check for an international mobile number
  const mobileRegex = /^\+?\d{1,3}[-.\s]?\d{1,14}$/;

  if (emailRegex.test(inputString)) {
    // If it's an email address, split and return the username
    // eslint-disable-next-line
    const [username, domain] = inputString.split("@");
    return username;
  } else if (mobileRegex.test(inputString)) {
    // If it's a mobile number, replace the last four digits with ***
    const digits = inputString.match(/\d/g);
    const lastFourDigits = digits.slice(-4).join("");
    const obscuredNumber = inputString.replace(lastFourDigits, "***");
    return obscuredNumber;
  } else {
    // If it's neither an email nor a mobile number, return the input string as it is
    return inputString;
  }
};

export const http = {
  get,
  post,
};

async function get(url, path) {
  return new Promise((resolve, reject) => {
    const requestURL = url + path;
    const requestOptions = {
      method: "GET",
      headers: {
        referrer: window.location.href,
      },
    };
    fetch(requestURL, requestOptions)
      .then(handleResponse)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

function post(url, path, body) {
  return new Promise((resolve, reject) => {
    const requestURL = url + path;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        referrer: window.location.href,
      },
      body: JSON.stringify(body),
    };
    fetch(requestURL, requestOptions)
      .then(handleResponse)
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
