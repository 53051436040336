export function networkQuality() {
  this.client.on("network-quality", (quality) => {
    const networkQualityEl = document.querySelector(
      "#localVideoContainer .network-quality-info"
    );

    let networkQuality =
      (quality.uplinkNetworkQuality + quality.downlinkNetworkQuality) / 2;

    let color = "#999";

    if (networkQuality < 1) {
      networkQualityEl.style.color = "#999";
    } else if (networkQuality < 2) {
      networkQualityEl.style.color = "#0CDD08";
      color = "#0CDD08";
    } else if (networkQuality < 3) {
      networkQualityEl.style.color = "yellow";
      color = "yellow";
    } else if (networkQuality < 4) {
      networkQualityEl.style.color = "orange";
      color = "orange";
    } else {
      networkQualityEl.style.color = "#eb271e";
      color = "#eb271e";
    }

    if (this.meeting_data.people_id) {
      this.socket.emit("chat_message", {
        networkQuality: networkQuality,
        people_id: this.meeting_data.people_id,
        uid: this.agoraUID,
        color: color,
      });
    }
    
    if (this.meeting_data.id) {
      this.socket.emit("chat_message", {
        networkQuality: networkQuality,
        meeting_id: this.meeting_data.id,
        uid: this.agoraUID,
        color: color,
      });
    }

    // console.log("quality", networkQuality);
  });
}
